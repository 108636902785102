
// SoundCard.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_SOUND_IMAGE } from '../api/APIConfig';
import { acceptLicense, reportSound } from '../api/APIManager';
import Spinner from './Spinner';
import { isJUCE } from '../context/JUCE';
import { AuthContext } from '../context/AuthContext';
import {
  PlayIcon,
  PauseIcon,
  LikeIcon,
  MoreIcon,
  DownloadIcon,
  DragIcon,
  CheckIcon,
  TokenBadge
} from '../assets/Icons';
import {
  CustomPopover,
  CustomDialog
} from '../components/CustomComponents';

export const SoundCard = ({ 
  sound: initialSound, 
  onLike, 
  onUnlike, 
  onPurchase, 
  onPlay, 
  onDownload, 
  currentPlayingSound,
  isPlaying,
  isPurchasing,
  onDragStart,
  onPurchaseSuccess
}) => {
  const navigate = useNavigate();
  const [sound, setSound] = useState(initialSound);
  const [isDragging, setIsDragging] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isAcceptingLicense, setIsAcceptingLicense] = useState(false);
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [selectedReportReason, setSelectedReportReason] = useState('');
  const [isSubmittingReport, setIsSubmittingReport] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
  const [canAcceptLicense, setCanAcceptLicense] = useState(false);
  const [showPurchasePrompt, setShowPurchasePrompt] = useState(false);
  const [acceptTimer, setAcceptTimer] = useState(null);

  const cardRef = useRef(null);
  const licenseContentRef = useRef(null);
  const { user } = useContext(AuthContext);
  const imageUrl = sound.image || sound.image2 || DEFAULT_SOUND_IMAGE;
  const hasAudioPreview = !!sound.audio_preview;
  const isCurrentSound = currentPlayingSound && currentPlayingSound.id === sound.id;
  const isOwner = user && (user.id === sound.owner_id || user.soundware_id === sound.owner_id);
  const buttonClass = "h-8 flex flex-col items-center justify-center text-white rounded transition-all duration-200";

  useEffect(() => {
    setSound(initialSound);
  }, [initialSound]);

// Clean up timer when modal closes
useEffect(() => {
  if (!isLicenseModalOpen && acceptTimer) {
    clearTimeout(acceptTimer);
    setAcceptTimer(null);
  }
}, [isLicenseModalOpen]);

  const handleDragStart = (e) => {
    if (isJUCE() && sound.is_purchased && sound.has_accepted_required_license) {
      e.preventDefault();
      const dragImage = new Image();
      dragImage.src = imageUrl;
      e.dataTransfer.setDragImage(dragImage, 0, 0);
      e.dataTransfer.setData('text/plain', JSON.stringify(sound));
      setIsDragging(true);
      onDragStart(e, sound);
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleClick = (e) => {
    if (e.target.closest('button') || e.target.closest('.drag-icon')) return;
    
    if (!isDragging) {
      onPlay(sound);
    }
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleLikeToggle = (e) => {
    e.stopPropagation();
    const newLikedState = !sound.is_liked;
    
    setSound(prevSound => ({
      ...prevSound,
      is_liked: newLikedState
    }));

    if (newLikedState) {
      onLike(sound.id).catch(() => {
        setSound(prevSound => ({
          ...prevSound,
          is_liked: !newLikedState
        }));
      });
    } else {
      onUnlike(sound.id).catch(() => {
        setSound(prevSound => ({
          ...prevSound,
          is_liked: !newLikedState
        }));
      });
    }
  };

  const handlePurchase = (e) => {
    e.stopPropagation();
    if (!sound.has_accepted_required_license && sound.license) {
      setShowPurchasePrompt(true);
      return;
    }
    
    processPurchase();
  };

  const processPurchase = () => {
    onPurchase(sound.id).then(() => {
      setSound(prevSound => ({
        ...prevSound,
        is_purchased: true
      }));
      if (onPurchaseSuccess) {
        onPurchaseSuccess(sound.id);
      }
    }).catch((error) => {
      console.error('Purchase failed:', error);
    });
  };

  const handleDownload = (e) => {
    e.stopPropagation();
    setIsDownloading(true);
    onDownload(sound.id)
      .then(() => {
        setIsDownloading(false);
      })
      .catch((error) => {
        setIsDownloading(false);
        console.error('Download failed:', error);
      });
  };

  const handleArtistClick = (e) => {
    e.stopPropagation();
    navigate(`/profile/${sound.owner.id}`);
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    navigate(`/sound/${sound.id}`);
  };

  const handleReport = async () => {
    if (!selectedReportReason) return;
    
    setIsSubmittingReport(true);
    try {
      const success = await reportSound(sound.id, selectedReportReason);
      if (success) {
        setIsReportDialogOpen(false);
      }
    } catch (error) {
      console.error('Error reporting sound:', error);
    } finally {
      setIsSubmittingReport(false);
      setSelectedReportReason('');
    }
  };

// Update the modal open handler
const handleLicenseClick = (e) => {
  e.stopPropagation();
  setIsLicenseModalOpen(true);
  setCanAcceptLicense(false);
  
  // Clear any existing timer
  if (acceptTimer) {
    clearTimeout(acceptTimer);
  }
  
  // Set new timer for 3 seconds
  const timer = setTimeout(() => {
    setCanAcceptLicense(true);
  }, 3000);
  
  setAcceptTimer(timer);
};


  const handleAcceptLicense = async () => {
    if (!sound.license || !sound.license.id) return;

    setIsAcceptingLicense(true);
    try {
      await acceptLicense(sound.license.id);
      setSound(prevSound => ({
        ...prevSound,
        has_accepted_required_license: true
      }));
      setIsLicenseModalOpen(false);
      if (showPurchasePrompt) {
        setShowPurchasePrompt(false);
        processPurchase();
      }
    } catch (error) {
      console.error('Error accepting license:', error);
      alert('Failed to accept license. Please try again.');
    } finally {
      setIsAcceptingLicense(false);
    }
  };

  const renderLicenseSection = () => {
    return (
      <div 
        className="flex flex-col items-center justify-center w-24 md:w-24 space-y-1 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          handleLicenseClick(e);
        }}
      >
        {sound.license ? (
          <>
            <span
              className="text-xs text-text-secondary hover:text-text-primary transition-colors duration-200 text-center"
              title={sound.license.name}
            >
              {sound.license.name}
            </span>
            <div className="h-4 flex items-center justify-center">
              {sound.has_accepted_required_license && (
                <CheckIcon className="text-green-500" />
              )}
            </div>
          </>
        ) : (
          <>
            <div className="h-4 flex items-center justify-center">
              <CheckIcon className="text-green-500" />
            </div>
          </>
        )}
      </div>
    );
  };
  
  // 

  const renderActionButton = () => {
    if (isPurchasing) {
      return (
        <div className="ml-4 md:ml-0 w-20 flex items-center justify-center">
          <Spinner />
        </div>
      );
    }

    if (sound.is_purchased) {
      if (isJUCE()) {
        return (
          <div className="ml-4 md:ml-0 w-20 h-9 drag-icon">
            <DragIcon />
          </div>
        );
      } else {
        const isDisabled = !sound.has_accepted_required_license;
        return (
          <button 
            onClick={handleDownload}
            className={`${buttonClass} ml-4 md:ml-0 w-20 md:w-24 ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isDisabled || isDownloading}
          >
            {isDownloading ? (
              <Spinner />
            ) : (
              <>
                <DownloadIcon />
                <span className="hidden sm:inline text-xs mt-1 text-text-primary">Download</span>
              </>
            )}
          </button>
        );
      }
    } else {
      return (
        <button 
          onClick={handlePurchase}
          className={`${buttonClass} ml-4 md:ml-0 w-20 bg-bg-primary hover:bg-accent-start hover:text-white`}
        >
          {sound.cost_in_credits ? (
            <span className="text-xs text-text-primary">{sound.cost_in_credits} <TokenBadge/></span>
          ) : (
            'Get'
          )}
        </button>
      );
    }
  };

  return (
    <>
      <div 
        ref={cardRef}
        className={`
          relative bg-transparent hover:bg-bg-secondary/30 p-2 flex items-center h-16 
          transition-all duration-200 ease-in-out group
          ${isCurrentSound ? 'bg-bg-secondary/30' : ''}
          border-b border-white/5
        `}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div 
          className="relative w-12 h-12 flex-shrink-0 mr-3 rounded-sm overflow-hidden cursor-pointer"
          onClick={handleClick}
        >
          <img src={imageUrl} alt={`${sound.name} artwork`} className="w-full h-full object-cover" draggable="false" />
          {hasAudioPreview && (
            <div className={`
              absolute inset-0 flex justify-center items-center bg-black/40
              ${isHovered || isCurrentSound ? 'opacity-100' : 'opacity-0'}
              transition-opacity duration-200
            `}>
              {isCurrentSound && isPlaying ? <PauseIcon className="w-5 h-5" /> : <PlayIcon className="w-5 h-5" />}
            </div>
          )}
        </div>
        
        <div className="flex-grow grid grid-cols-4 md:grid-cols-6 gap-2 overflow-hidden cursor-pointer items-center" onClick={handleClick}>
          <div className="col-span-4 md:col-span-2">
            <div className="font-medium text-sm text-text-primary truncate select-none">
              {sound.name}
            </div>
            <div 
              className="text-text-secondary text-xs truncate select-none cursor-pointer hover:underline"
              onClick={handleArtistClick}
            >
              {sound.owner.username}
            </div>
          </div>
          
          <div className="hidden md:flex items-center">
            {sound.instruments && sound.instruments.length > 0 ? (
              <span className="text-text-secondary text-xs truncate">
                {sound.instruments.slice(0, 2).map(i => i.name).join(', ')}
              </span>
            ) : (
              <span className="text-text-secondary text-xs">-</span>
            )}
          </div>
          
          <div className="hidden md:block text-text-secondary text-xs truncate">
            {sound.type ? sound.type.name : '-'}
          </div>
          
          <div className="hidden md:block text-text-secondary text-xs truncate">
            {sound.key || '-'}
          </div>
          
          <div className="hidden md:block text-text-secondary text-xs truncate">
            {typeof sound.bpm === 'number' && sound.bpm > 0 ? `${sound.bpm}` : '-'}
          </div>
        </div>

 {/* Main controls container */}
{/* Update just the controls container section in the main JSX */}



<div className="flex items-center space-x-2 md:space-x-3">
<button 
  onClick={handleLikeToggle}
  className={`
    p-1 rounded-full hover:bg-gray-700/50 transition-all duration-200
    ${sound.is_liked ? 'opacity-100' : 'md:opacity-0 md:group-hover:opacity-100'}
  `}
>
  <LikeIcon className="w-4 h-4" filled={sound.is_liked} />
</button>
  
  <div className="w-18 flex justify-center md:opacity-0 md:group-hover:opacity-100 transition-all duration-200">
    {renderLicenseSection()}
  </div>
  
  <div className="w-16 flex justify-center">
    {renderActionButton()}
  </div>

  <div className="md:opacity-0 md:group-hover:opacity-100 transition-all duration-200">
  <CustomPopover
    isOpen={isMenuOpen}
    setIsOpen={setIsMenuOpen}
    trigger={
      <button 
        className="p-1.5 hover:bg-gray-700/50 rounded-full"
        onClick={(e) => {
          e.stopPropagation();  // Add this to prevent event bubbling
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        <MoreIcon className="w-4 h-4" />
      </button>
    }
    content={
      <div 
        className="w-40 shadow-lg border border-white/10 rounded-lg overflow-hidden"
        onClick={(e) => e.stopPropagation()} // Add this to prevent closing when clicking inside
      >
        {isOwner && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(e);
              setIsMenuOpen(false);
            }}
            className="w-full px-3 py-1.5 text-sm text-left hover:bg-gray-700/50"
          >
            Edit
          </button>
        )}
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsReportDialogOpen(true);
            setIsMenuOpen(false);
          }}
          className="w-full px-3 py-1.5 text-sm text-left hover:bg-gray-700/50"
        >
          Report
        </button>
      </div>
    }
  />
  </div>
</div>





<CustomDialog 
  isOpen={isLicenseModalOpen} 
  onClose={(e) => {
    e?.stopPropagation();
    setIsLicenseModalOpen(false);
  }}
  title={`${sound.license?.name || 'License'} Agreement`}
>
  <div className="space-y-4" onClick={(e) => e.stopPropagation()}>
    <div className="h-[calc(100vh-250px)] md:h-[600px] mb-4 bg-bg-primary rounded overflow-hidden">
      <iframe
        src={sound.license?.url}
        className="w-full h-full border-0"
        title="License Agreement"
      />
    </div>
    <div className="flex justify-end space-x-2">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsLicenseModalOpen(false);
        }}
        className="px-4 py-2 rounded hover:bg-gray-700 transition-colors duration-200 text-text-primary"
      >
        Cancel
      </button>
      {!sound.has_accepted_required_license && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleAcceptLicense();
          }}
          disabled={!canAcceptLicense || isAcceptingLicense}
          className={`
            px-4 py-2 rounded bg-accent-start text-text-primary
            ${(!canAcceptLicense || isAcceptingLicense) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-accent-end'}
            transition-colors duration-200
          `}
        >
          {isAcceptingLicense ? (
            <Spinner />
          ) : !canAcceptLicense ? (
            'Please wait...'
          ) : (
            'Accept'
          )}
        </button>
      )}
    </div>
  </div>
</CustomDialog>

{/* Report Dialog */}
<CustomDialog 
  isOpen={isReportDialogOpen} 
  onClose={() => setIsReportDialogOpen(false)}
  title="Report Sound"
>
  <div className="space-y-4">
    <p className="text-text-secondary">Please select a reason for reporting this sound</p>
    {['hate', 'copyright infringement', 'other'].map((reason) => (
      <button
        key={reason}
        onClick={() => setSelectedReportReason(reason)}
        className={`
          w-full px-4 py-2 text-left rounded
          ${selectedReportReason === reason ? 'bg-accent-start text-white' : 'hover:bg-gray-700'}
          transition-colors duration-200
        `}
      >
        {reason.charAt(0).toUpperCase() + reason.slice(1)}
      </button>
    ))}
    <div className="flex justify-end space-x-2 pt-4">
      <button
        onClick={() => setIsReportDialogOpen(false)}
        className="px-4 py-2 rounded hover:bg-gray-700 transition-colors duration-200"
      >
        Cancel
      </button>
      <button
        onClick={handleReport}
        disabled={!selectedReportReason || isSubmittingReport}
        className={`
          px-4 py-2 rounded bg-accent-start text-white
          ${(!selectedReportReason || isSubmittingReport) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-accent-end'}
          transition-colors duration-200
        `}
      >
        {isSubmittingReport ? <Spinner /> : 'Submit'}
      </button>
    </div>
  </div>
</CustomDialog>

        {isJUCE() && sound.is_purchased && sound.has_accepted_required_license && (
          <div
            className="absolute inset-0 cursor-grab active:cursor-grabbing"
            draggable="true"
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        )}
      </div>
    </>
  );
};

export default SoundCard;