// utils/uploadUtils.js

export const VALID_STATUS = [
    { value: "active", label: "Public" },
    { value: "inactive", label: "Private" }
];

export const VALID_KEYS = [
    "C Major", "Db Major", "D Major", "Eb Major", "E Major", "F Major",
    "Gb Major", "G Major", "Ab Major", "A Major", "Bb Major", "B Major",
    "C Minor", "Db Minor", "D Minor", "Eb Minor", "E Minor", "F Minor",
    "Gb Minor", "G Minor", "Ab Minor", "A Minor", "Bb Minor", "B Minor"
];

export const STEPS = {
    SOUND_FILE: 0,
    IMAGE: 1,
    VISIBILITY: 2,
    METADATA: 3,
    CLASSIFICATIONS: 4,
    REVIEW: 5
};

export const pageVariants = {
    initial: {
        opacity: 0,
        x: 20,
        scale: 0.95,
    },
    animate: {
        opacity: 1,
        x: 0,
        scale: 1,
        transition: {
            duration: 0.4,
            ease: "easeOut",
        },
    },
    exit: {
        opacity: 0,
        x: -20,
        scale: 0.95,
        transition: {
            duration: 0.3,
            ease: "easeIn",
        },
    },
};

export const convertSharpToFlat = (key) => {
    const sharpToFlatMap = {
        'C#': 'Db', 'D#': 'Eb', 'F#': 'Gb', 'G#': 'Ab', 'A#': 'Bb'
    };
    const [note, ...modeParts] = key.split(' ');
    const mode = modeParts.join(' ');
    const upperNote = note.toUpperCase();
    return sharpToFlatMap[upperNote] ? `${sharpToFlatMap[upperNote]} ${mode}` : key;
};