import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createSound, fetchSoundTypes, fetchTags, fetchGenres, fetchInstruments, analyzeAudio, createTag, createGenre, createInstrument, fetchLicenses } from '../api/APIManager';
import { useToast } from '../context/ToastContext';
import SpinnerFull from '../components/SpinnerFull';
import BatchUploadTable from '../components/BatchUploadTable';
import { Upload } from 'lucide-react';

const convertSharpToFlat = (key) => {
    const sharpToFlatMap = {
        'C#': 'Db', 'D#': 'Eb', 'F#': 'Gb', 'G#': 'Ab', 'A#': 'Bb'
    };
    const [note, ...modeParts] = key.split(' ');
    const mode = modeParts.join(' ');
    const upperNote = note.toUpperCase();
    for (const [sharp, flat] of Object.entries(sharpToFlatMap)) {
        if (upperNote === sharp) {
            return mode ? `${flat} ${mode}` : flat;
        }
    }
    return key;
};

const BatchUpload = () => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const [files, setFiles] = useState([]);
    const [soundTypes, setSoundTypes] = useState([]);
    const [availableTags, setAvailableTags] = useState([]);
    const [availableGenres, setAvailableGenres] = useState([]);
    const [availableInstruments, setAvailableInstruments] = useState([]);
    const [licenses, setLicenses] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);

    useEffect(() => {
        const loadData = async () => {
            try {
                const types = await fetchSoundTypes();
                setSoundTypes(types);
                const fetchedTags = await fetchTags();
                setAvailableTags(fetchedTags);
                const fetchedGenres = await fetchGenres();
                setAvailableGenres(fetchedGenres);
                const fetchedInstruments = await fetchInstruments();
                setAvailableInstruments(fetchedInstruments);
                const fetchedLicenses = await fetchLicenses();
                setLicenses(fetchedLicenses);
            } catch (error) {
                console.error("Error loading initial data:", error);
                showToast("Failed to load initial data. Please try again.", "error");
            } finally {
                setIsLoading(false);
            }
        };
        loadData();
    }, [showToast]);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.currentTarget.contains(e.relatedTarget)) return;
        setIsDragging(false);
    };

    const handleFileDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        const droppedFiles = Array.from(e.dataTransfer.files).filter(file => file.type.startsWith('audio/wav'));
        if (droppedFiles.length === 0) {
            showToast("Please drop WAV audio files only.", "error");
            return;
        }
        await addFiles(droppedFiles);
    };

    const handleFileInput = async (e) => {
        const selectedFiles = Array.from(e.target.files).filter(file => file.type.startsWith('audio/wav'));
        if (selectedFiles.length === 0) {
            showToast("Please select WAV audio files only.", "error");
            return;
        }
        await addFiles(selectedFiles);
    };

    const addFiles = async (newFiles) => {
        const filesWithPreviews = newFiles.map(file => ({
            file,
            name: file.name.split('.').slice(0, -1).join('.'),
            preview: URL.createObjectURL(file),
            description: '',
            tags: [],
            genres: [],
            instruments: [],
            typeId: '',
            bpm: '',
            key: '',
            status: 'active',
            costInCredits: '1',
            license: '',
            isAnalyzed: false,
            isAnalyzing: true,
            uploadStatus: 'pending',
            image: null,
            imagePreview: null
        }));

        setFiles(prevFiles => [...prevFiles, ...filesWithPreviews]);

        for (let i = 0; i < filesWithPreviews.length; i++) {
            try {
                const analysis = await analyzeAudio(filesWithPreviews[i].file);
                setFiles(prevFiles => {
                    const newFiles = [...prevFiles];
                    const fileIndex = prevFiles.length - filesWithPreviews.length + i;
                    newFiles[fileIndex] = {
                        ...newFiles[fileIndex],
                        bpm: Math.round(analysis.bpm).toString(),
                        key: convertSharpToFlat(analysis.key),
                        genres: analysis.genres.split(', ')
                            .filter(genreName => genreName.trim() !== '') // Filter out empty strings
                            .map(genreName => ({
                                name: genreName,
                                id: availableGenres.find(g => g.name.toLowerCase() === genreName.toLowerCase())?.id
                            })),
                        instruments: analysis.instruments
                            .filter(instrumentName => instrumentName.trim() !== '') // Filter out empty strings
                            .map(instrumentName => ({
                                name: instrumentName,
                                id: availableInstruments.find(i => i.name.toLowerCase() === instrumentName.toLowerCase())?.id
                            })),
                        tags: analysis.moods.split(', ')
                            .filter(moodName => moodName.trim() !== '') // Filter out empty strings
                            .map(moodName => ({
                                name: moodName,
                                id: availableTags.find(t => t.name.toLowerCase() === moodName.toLowerCase())?.id
                            })),
                        isAnalyzed: true,
                        isAnalyzing: false
                    };
                    return newFiles;
                });
            } catch (error) {
                console.error(`Error analyzing file ${filesWithPreviews[i].name}:`, error);
                showToast(`Failed to analyze ${filesWithPreviews[i].name}`, "error");
                setFiles(prevFiles => {
                    const newFiles = [...prevFiles];
                    const fileIndex = prevFiles.length - filesWithPreviews.length + i;
                    newFiles[fileIndex] = {
                        ...newFiles[fileIndex],
                        isAnalyzed: false,
                        isAnalyzing: false,
                        uploadStatus: 'error',
                    };
                    return newFiles;
                });
            }
        }
    };

    const handleUpload = async () => {
        setIsUploading(true);
        let successCount = 0;
        let errorCount = 0;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.uploadStatus !== 'success') {
                try {
                    setFiles(prevFiles => {
                        const newFiles = [...prevFiles];
                        newFiles[i] = { ...newFiles[i], uploadStatus: 'uploading' };
                        return newFiles;
                    });

                    const updatedTags = await Promise.all(file.tags.map(async (tag) => {
                        if (!tag.id) {
                            const newTag = await createTag({ name: tag.name.toLowerCase() });
                            return { id: newTag.id, name: newTag.name };
                        }
                        return { ...tag, name: tag.name.toLowerCase() };
                    }));

                    const updatedGenres = await Promise.all(file.genres.map(async (genre) => {
                        if (!genre.id) {
                            const newGenre = await createGenre({ name: genre.name.toLowerCase() });
                            return { id: newGenre.id, name: newGenre.name };
                        }
                        return { ...genre, name: genre.name.toLowerCase() };
                    }));

                    const updatedInstruments = await Promise.all(file.instruments.map(async (instrument) => {
                        if (!instrument.id) {
                            const newInstrument = await createInstrument({ name: instrument.name.toLowerCase() });
                            return { id: newInstrument.id, name: newInstrument.name };
                        }
                        return { ...instrument, name: instrument.name.toLowerCase() };
                    }));

                    await createSound({
                        ...file,
                        tags: updatedTags,
                        genres: updatedGenres,
                        instruments: updatedInstruments,
                        image: file.image,
                    });

                    setFiles(prevFiles => {
                        const newFiles = [...prevFiles];
                        newFiles[i] = { ...newFiles[i], uploadStatus: 'success' };
                        return newFiles;
                    });
                    successCount++;
                } catch (error) {
                    console.error(`Error uploading sound: ${file.name}`, error);
                    showToast(`Failed to upload ${file.name}: ${error.message}`, "error");
                    setFiles(prevFiles => {
                        const newFiles = [...prevFiles];
                        newFiles[i] = { ...newFiles[i], uploadStatus: 'error' };
                        return newFiles;
                    });
                    errorCount++;
                }
            }
        }
        
        setIsUploading(false);
        
        if (successCount > 0) {
            showToast(`Successfully uploaded ${successCount} file${successCount > 1 ? 's' : ''}`, "success");
        }
        if (errorCount > 0) {
            showToast(`Failed to upload ${errorCount} file${errorCount > 1 ? 's' : ''}`, "error");
        }
    };

    if (isLoading) {
        return <SpinnerFull />;
    }

    return (
        <div className="p-4 sm:p-8 mx-auto mt-6">
            <h1 className="text-3xl font-bold mb-6">Batch Upload Sounds</h1>
            
            <div 
                onDrop={handleFileDrop}
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onClick={() => fileInputRef.current.click()}
                className={`
                    relative overflow-hidden
                    min-h-[300px] w-full
                    border-2 border-dashed rounded-lg
                    transition-all duration-300 ease-in-out
                    flex flex-col items-center justify-center
                    cursor-pointer
                    ${isDragging 
                        ? 'border-accent-start bg-accent-start/10 scale-[1.02]' 
                        : 'border-gray-300 hover:border-accent-start hover:bg-accent-start/5'
                    }
                `}
            >
                <Upload 
                    className={`w-16 h-16 mb-4 transition-all duration-300 ${
                        isDragging ? 'text-accent-start scale-110' : 'text-gray-400'
                    }`}
                />
                <div className="space-y-2 text-center">
                    <p className="text-xl font-medium">
                        {isDragging ? 'Drop your files here' : 'Drag & drop your WAV files'}
                    </p>
                    <p className="text-sm text-gray-500">
                        or click to browse
                    </p>
                </div>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileInput}
                    multiple
                    accept="audio/wav"
                    className="hidden"
                />
            </div>
            
            {files.length > 0 && (
                <>
                    <BatchUploadTable
                        files={files}
                        setFiles={setFiles}
                        soundTypes={soundTypes}
                        availableTags={availableTags}
                        availableGenres={availableGenres}
                        availableInstruments={availableInstruments}
                        licenses={licenses}
                        createSound={createSound}
                        createTag={createTag}
                        createGenre={createGenre}
                        createInstrument={createInstrument}
                        setErrorMessage={(msg) => showToast(msg, "error")}
                        editMode={false}
                    />
                </>
            )}
        </div>
    );
};

export default BatchUpload;