import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useParams, Navigate, useOutletContext } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { fetchSounds, fetchUserById, followUser, unfollowUser, updateUser } from '../api/APIManager';
import { SoundList } from '../components/SoundList';
import Spinner from '../components/Spinner';
import RevenueChart from '../components/RevenueChart';
import { DEFAULT_USER_IMAGE } from '../api/APIConfig';
import { CashBadge, TokenBadge, VerifiedBadge } from '../assets/Icons';
import EditableAvatar from '../components/EditableAvatar';
import { JUCESend, JUCEReceive, isJUCE } from '../context/JUCE';

const UserProfile = () => {
  const { userId } = useParams();
  const { user } = useContext(AuthContext);
  const [profileUser, setProfileUser] = useState(null);
  const [userSounds, setUserSounds] = useState([]);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isLoadingSounds, setIsLoadingSounds] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalSounds, setTotalSounds] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [credits, setCredits] = useState(0);
  const [cashBalance, setCashBalance] = useState(0);
  const [dateFilter, setDateFilter] = useState('all');
  const [revenueData, setRevenueData] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);
  const { setCurrentPlayingSound: setGlobalCurrentPlayingSound, setIsPlaying: setGlobalIsPlaying } = useOutletContext();
  const [currentPlayingSound, setCurrentPlayingSound] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const pageSize = 10;
  const loadingRef = useRef(null);

  const loadUserData = useCallback(async () => {
    setIsLoadingUser(true);
    setProfileUser(null);
    setTotalSounds(0);
    try {
      let userData;
      if (userId === 'me' || !userId) {
        // Check if user.soundware_id exists, use it if it does, otherwise use user.id
        const idToFetch = user.soundware_id ? user.soundware_id : user.id;
        userData = await fetchUserById(idToFetch);
    } else {
        // If userId is provided and not 'me', use it
        userData = await fetchUserById(userId);
    }
      
      if (!userData) {
        throw new Error('Failed to load user data');
      }
      
      setProfileUser(userData);
      // Remove commas from credits and convert it to a number
      setCredits(userData.credits);
      const cashBalance = (Number(String(user.usd_balance || 0).replace(/,/g, '')) / 100)
      .toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      setCashBalance(cashBalance);
      
      // Generate fake revenue data
      const fakeRevenueData = Array.from({ length: 7 }, (_, i) => ({
        month: new Date(2024, i, 1).toLocaleString('default', { month: 'short' }),
        revenue: Math.floor(Math.random() * 5000)
      }));
      setRevenueData(fakeRevenueData);
      setTotalRevenue(fakeRevenueData.reduce((sum, item) => sum + item.revenue, 0));
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsLoadingUser(false);
    }
  }, [userId, user.id]);

  const loadSounds = useCallback(async (newPage, ownerId, isInitialLoad = false) => {
    if (isLoadingMore && !isInitialLoad) return;
    setIsLoadingMore(true);
    try {
      const response = await fetchSounds(newPage, pageSize, { owner: ownerId });
      if (isInitialLoad) {
        setUserSounds(response.items);
      } else {
        setUserSounds(prev => [...prev, ...response.items]);
      }
      setPage(newPage);
      setHasMore(response.items.length === pageSize);
      setTotalSounds(response.total);
    } catch (error) {
      console.error('Error fetching sounds:', error);
    } finally {
      setIsLoadingMore(false);
      setIsLoadingSounds(false);
    }
  }, []);

  useEffect(() => {
    loadUserData();
  }, [loadUserData]);

  useEffect(() => {
    if (profileUser) {
      setUserSounds([]);
      setPage(1);
      setHasMore(true);
      setIsLoadingSounds(true);
      setTotalSounds(0);
      loadSounds(1, profileUser.id, true);
    }
  }, [profileUser, loadSounds]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMore && !isLoadingMore) {
          loadSounds(page + 1, profileUser.id);
        }
      },
      { threshold: 1.0 }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [hasMore, isLoadingMore, page, profileUser, loadSounds]);

  const handleAvatarChange = async (file) => {
    if (!file) return;
    
    setIsUploadingAvatar(true);
    try {
      const updatedUser = await updateUser(profileUser.id, {
        avatarFile: file
      });
      
      if (updatedUser) {
        setProfileUser(updatedUser);
      }
    } catch (error) {
      console.error('Error updating avatar:', error);
    } finally {
      setIsUploadingAvatar(false);
    }
  };

  const handlePlay = useCallback((sound) => {
    if (currentPlayingSound && currentPlayingSound.id === sound.id) {
      isPlaying ? JUCESend('pauseSound') : JUCESend('playSound', sound);
      setIsPlaying(!isPlaying);
      setGlobalIsPlaying(!isPlaying);
    } else {
      setGlobalCurrentPlayingSound(sound);
      setCurrentPlayingSound(sound);
      JUCESend('playSound', sound);
      setIsPlaying(true);
      setGlobalIsPlaying(true);
    }
  }, [currentPlayingSound, isPlaying, setGlobalCurrentPlayingSound, setGlobalIsPlaying]);

  const handleFollow = async () => {
    try {
      await followUser(profileUser.id);
      setProfileUser(prev => ({
        ...prev,
        followers_count: prev.followers_count + 1,
        is_followed: true
      }));
    } catch (error) {
      console.error('Error following user:', error);
    }
  };

  const handleUnfollow = async () => {
    try {
      await unfollowUser(profileUser.id);
      setProfileUser(prev => ({
        ...prev,
        followers_count: prev.followers_count - 1,
        is_followed: false
      }));
    } catch (error) {
      console.error('Error unfollowing user:', error);
    }
  };

  const handleDateFilterChange = (e) => {
    setDateFilter(e.target.value);
    // Simulate filtering data based on the selected date range
    let filteredData;
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();

    switch (e.target.value) {
      case 'week':
        filteredData = revenueData.slice(currentMonth, currentMonth + 1);
        break;
      case 'month':
        filteredData = revenueData.slice(currentMonth, currentMonth + 1);
        break;
      case 'year':
        filteredData = revenueData.slice(0, currentMonth + 1);
        break;
      default:
        filteredData = revenueData;
    }

    setRevenueData(filteredData);
    setTotalRevenue(filteredData.reduce((sum, item) => sum + item.revenue, 0));
  };

  const handleCashOut = () => {
    window.open('https://store.soundware.io/payout', '_blank', 'noopener,noreferrer');
  };

  if (isLoadingUser) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  if (!profileUser) {
    return <Navigate to="/404" />;
  }

  const isOwnProfile = userId === 'me' || !userId;

  return (
    <div className="bg-bg-primary text-text-primary p-4 sm:p-6">
      <div className="max-w-4xl mx-auto">
        <div className="bg-bg-secondary rounded-lg p-4 sm:p-6 mb-6">
          <div className="flex flex-col sm:flex-row items-center mb-4">
          <EditableAvatar
  currentAvatar={profileUser.avatar || DEFAULT_USER_IMAGE}
  onAvatarChange={handleAvatarChange}
  isEditable={isOwnProfile}
  username={profileUser.username}
  uploading={isUploadingAvatar}
/>
            <div className="flex-grow text-center sm:text-left">
              <h1 className="text-2xl sm:text-3xl font-bold mb-2 flex items-center justify-center sm:justify-start">
                {profileUser.username}
                {profileUser.verified && <VerifiedBadge />}
              
              </h1>
              {isOwnProfile && <p className="text-text-secondary mb-2">{profileUser.email}</p>}
            </div>
            {!isOwnProfile && (
              <button
                onClick={profileUser.is_followed ? handleUnfollow : handleFollow}
                className={`px-4 py-2 rounded mt-4 sm:mt-0 ${
                  profileUser.is_followed
                    ? 'bg-gray-500 hover:bg-gray-600'
                    : 'bg-accent-start hover:bg-accent-end'
                } text-white transition-colors duration-200`}
              >
                {profileUser.is_followed ? 'Unfollow' : 'Follow'}
              </button>
            )}
          </div>
          <div className="grid grid-cols-3 gap-4 mb-4">
            <div className="bg-bg-primary rounded p-3 text-center">
              <p className="text-xl sm:text-2xl font-bold">{totalSounds}</p>
              <p className="text-xs sm:text-sm text-text-secondary">Sounds</p>
            </div>
            <div className="bg-bg-primary rounded p-3 text-center">
              <p className="text-xl sm:text-2xl font-bold">{profileUser.followers_count || 0}</p>
              <p className="text-xs sm:text-sm text-text-secondary">Followers</p>
            </div>
            <div className="bg-bg-primary rounded p-3 text-center">
              <p className="text-xl sm:text-2xl font-bold">{profileUser.following_count || 0}</p>
              <p className="text-xs sm:text-sm text-text-secondary">Following</p>
            </div>
          </div>

          
          {isOwnProfile && (
          <div className="bg-bg-primary rounded-lg p-4 mt-6">
            <h2 className="text-xl font-bold mb-2">Account Balances</h2>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <p className="text-sm text-text-secondary">Tokens</p>
                <p className="text-2xl font-bold flex items-center">
                  {credits}
                  <TokenBadge />
                </p>
              </div>
              <div>
                <p className="text-sm text-text-secondary">Cash</p>
                <p className="text-2xl font-bold flex items-center">
                  {cashBalance}
                  <CashBadge />
                </p>
              </div>
            </div>
            <button
              onClick={handleCashOut}
              className="w-full px-4 py-2 bg-accent-start hover:bg-accent-end text-white rounded transition-colors duration-200"
            >
              Cash Out
            </button>
          </div>
        )}
        </div>

        {isOwnProfile && (
  <div className="relative bg-bg-secondary rounded-lg p-4 sm:p-6 mb-6">
        <p className="text-white text-2xl font-bold">Analytics</p>

    <div className="absolute inset-0 bg-black/70 rounded-lg flex items-center justify-center z-10">
      <p className="text-white text-2xl font-bold">Coming Soon</p>
    </div>
    <h2 className="text-xl font-bold mb-4">Revenue Overview</h2>
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center space-x-4">
        <span className="text-sm text-text-secondary">Filter by:</span>
        <select
          value={dateFilter}
          onChange={handleDateFilterChange}
          className="bg-bg-primary text-text-primary rounded px-2 py-1"
        >
          <option value="all">All time</option>
          <option value="year">This year</option>
          <option value="month">This month</option>
          <option value="week">This week</option>
        </select>
      </div>
      <div className="text-right">
        <p className="text-sm text-text-secondary">Total Revenue</p>
        <p className="text-xl font-bold">${totalRevenue.toFixed(2)}</p>
      </div>
    </div>
    <div className="bg-bg-primary rounded-lg p-4">
      <RevenueChart data={revenueData} />
    </div>
  </div>
)}

        <div className="bg-bg-secondary rounded-lg p-4 sm:p-6 overflow-hidden">
          <h2 className="text-xl sm:text-2xl font-bold mb-4">Sounds</h2>
          {isLoadingSounds ? (
            <div className="flex justify-center items-center h-32">
              <Spinner />
            </div>
          ) : (
            <div className="space-y-4">
              {userSounds.length > 0 ? (
                <SoundList
                  sounds={userSounds}
                  onLike={() => {}}
                  onUnlike={() => {}}
                  onPurchase={() => {}}
                  onPlay={handlePlay}
                  onDownload={() => {}}
                />
              ) : (
                <p className="text-text-secondary">No sounds uploaded yet.</p>
              )}
              {hasMore && (
                <div ref={loadingRef} className="flex justify-center py-4">
                  <Spinner />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;