import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

export const CustomPopover = ({ trigger, content, isOpen, setIsOpen }) => {
  const popoverRef = useRef(null);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, setIsOpen]);

  return (
    <div className="relative" ref={popoverRef}>
      {trigger}
      {isOpen && (
        <div className="absolute right-0 top-full mt-2 bg-bg-secondary rounded-lg shadow-lg py-1 z-50">
          {content}
        </div>
      )}
    </div>
  );
};


export const CustomDialog = ({ isOpen, onClose, title, children, maxWidth = 'md:max-w-2xl' }) => {
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'unset';
    };
  }, [onClose, isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div 
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[99999] md:p-6 flex items-center justify-center"
      onClick={onClose}
    >
      <div 
        className={`
          bg-bg-secondary w-full relative text-text-primary
          md:rounded-lg ${maxWidth} md:my-auto
          flex flex-col h-full md:h-auto md:max-h-[85vh]
        `}
        onClick={e => e.stopPropagation()}
      >
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-white/10">
          <h2 className="text-lg font-semibold pr-4 text-text-primary">{title}</h2>
          <button 
            onClick={onClose}
            className="text-text-secondary hover:text-text-primary transition-colors p-2 -mr-2 hover:bg-white/5 rounded-full"
            aria-label="Close dialog"
          >
            <svg 
              className="w-6 h-6" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M6 18L18 6M6 6l12 12" 
              />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto p-4">
          {children}
        </div>
      </div>
    </div>,
    document.body
  );
};