import React, { useContext } from 'react';
import SoundBrowser from '../components/SoundBrowser';
import { AuthContext } from '../context/AuthContext';

const UploadIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"/>
  </svg>
);

const Upladed = () => {
  const { user } = useContext(AuthContext);
  
  return <SoundBrowser 
    additionalFilters={ { owner: user.id, status: "all" }} 
    title="Uploaded"
    icon={UploadIcon}
  />;
};

export default Upladed;
