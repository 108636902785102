import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { JUCESend, JUCEReceive, isJUCE } from '../context/JUCE';
import { getDownloadURL } from '../api/APIManager';
import SpinnerFull from './SpinnerFull';

const PlayIcon = () => (
  <svg className="w-5 h-5 sm:w-6 sm:h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M8 5v14l11-7z" />
  </svg>
);

const PauseIcon = () => (
  <svg className="w-5 h-5 sm:w-6 sm:h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M6 4h4v16H6zM14 4h4v16h-4z" />
  </svg>
);

const AudioPlayer = ({ sound, isPlaying, onPlayPause, onEnded }) => {
  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null);
  const [isWaveSurferReady, setIsWaveSurferReady] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const lastJUCEPosition = useRef(0);

  useEffect(() => {
    const handleJUCEReceive = ({ eventName, eventData }) => {
      if (eventName === 'dragExportComplete') {
        setIsDragging(false);
      }
      if (eventName === 'audioPlayerPosition') {
        lastJUCEPosition.current = eventData;
        
        if (waveSurferRef.current) {
          waveSurferRef.current.seekTo(eventData);
          const newTime = eventData * duration;
          setCurrentTime(newTime);
        }
      }
      if (eventName === 'audioPlayerDuration') {
        setDuration(eventData);
      }
    };

    JUCEReceive.on('JUCEReceive', handleJUCEReceive);

    let timeUpdateInterval;
    if (isJUCE() && isPlaying) {
      timeUpdateInterval = setInterval(() => {
        setCurrentTime(prevTime => {
          return lastJUCEPosition.current * duration;
        });
      }, 100);
    }

    return () => {
      JUCEReceive.off('JUCEReceive', handleJUCEReceive);
      if (timeUpdateInterval) {
        clearInterval(timeUpdateInterval);
      }
    };
  }, [duration, isPlaying]);

  useEffect(() => {
    if (waveSurferRef.current) {
      try {
        waveSurferRef.current.destroy();
      } catch (error) {
        console.error("Error while destroying WaveSurfer:", error);
      }
    }
  
    const initWaveSurfer = () => {
      if (!sound || !sound.audio_preview) return;
  
      const primaryColorElement = document.createElement('div');
      primaryColorElement.className = 'text-text-primary hidden';
      document.body.appendChild(primaryColorElement);
      const secondaryColorElement = document.createElement('div');
      secondaryColorElement.className = 'text-text-secondary hidden';
      document.body.appendChild(secondaryColorElement);
  
      const primaryColor = getComputedStyle(primaryColorElement).color;
      const secondaryColor = getComputedStyle(secondaryColorElement).color;
  
      document.body.removeChild(primaryColorElement);
      document.body.removeChild(secondaryColorElement);
  
      waveSurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: secondaryColor,
        progressColor: primaryColor,
        height: window.innerWidth < 640 ? 48 : 64,
        responsive: true,
        barWidth: 2,
        cursorWidth: 1,
        hideScrollbar: true,
        backend: 'MediaElement',
      });
  
      const urlWithEncodedHash = sound.audio_preview.replace(/#/g, '%23');
      waveSurferRef.current.load(urlWithEncodedHash);
  
      waveSurferRef.current.on('ready', () => {
        const waveSurferDuration = waveSurferRef.current.getDuration();
        setDuration(waveSurferDuration);
        if (isJUCE()) {
          JUCESend('audioPlayerDuration', waveSurferDuration);
        }
        setIsWaveSurferReady(true);
        if (isPlaying && !isJUCE()) waveSurferRef.current.play();
      });
  
      waveSurferRef.current.on('audioprocess', () => {
        if (!isJUCE()) {
          setCurrentTime(waveSurferRef.current.getCurrentTime());
        }
      });
  
      waveSurferRef.current.on('finish', onEnded);
    };
  
    const timer = setTimeout(initWaveSurfer, 100);
  
    return () => {
      clearTimeout(timer);
      if (waveSurferRef.current) {
        try {
          waveSurferRef.current.destroy();
        } catch (error) {
          console.error("Error while destroying WaveSurfer on cleanup:", error);
        }
      }
    };
  }, [sound, onEnded, isPlaying]);

  useEffect(() => {
    if (isWaveSurferReady && !isJUCE() && waveSurferRef.current) {
      if (isPlaying) {
        waveSurferRef.current.play();
      } else {
        waveSurferRef.current.pause();
      }
    }
  }, [isPlaying, isWaveSurferReady]);

  const handleSeek = (e) => {
    if (!waveSurferRef.current) return;
    
    const seekPosition = e.nativeEvent.offsetX / e.target.clientWidth;
    
    if (isJUCE()) {
      JUCESend('audioPlayerSeek', seekPosition);
      setCurrentTime(seekPosition * duration);
      lastJUCEPosition.current = seekPosition;
    } else {
      waveSurferRef.current.seekTo(seekPosition);
      setCurrentTime(seekPosition * duration);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleDragStart = async (e) => {
    if (isJUCE() && sound && sound.is_purchased) {
      e.preventDefault();
      setIsDragging(true);
      try {
        const downloadUrl = await getDownloadURL(sound.id);
        const soundWithUrl = { ...sound, download_url: downloadUrl };
        JUCESend('dragExport', soundWithUrl);
      } catch (error) {
        console.error('Error getting download URL:', error);
        JUCESend('dragExport', sound);
      }
    }
  };

  const playerHeight = sound && (isPlaying || isWaveSurferReady) ? 
    (window.innerWidth < 640 ? '96px' : '126px') : '0px';
  const bottomPosition = (isWaveSurferReady && isJUCE()) ? '56px' : '0px';
  const progressPercentage = (currentTime / duration) * 100 || 0;

  if (!sound) {
    return null;
  }

  if (isDragging) {
    return <SpinnerFull message="Processing audio, please wait..." />;
  }

  return (
    <div 
      className="fixed bottom-0 left-0 right-0 z-10 transition-all duration-300 ease-in-out"
      style={{ transform: `translateY(-${bottomPosition})` }}
    >
      {/* Progress bar */}
      <div className="fixed bottom-0 left-0 right-0 h-1 bg-white/5 z-50 overflow-hidden" style={{ bottom: `${playerHeight}` }}>
        <div 
          className="h-full w-full bg-gradient-to-r from-accent-start to-accent-end"
          style={{ 
            transform: `translateX(${progressPercentage - 100}%)`,
            transition: 'transform 100ms linear'
          }}
        />
      </div>

      {/* Backdrop blur and gradient overlay */}
      <div 
        className="absolute inset-0 backdrop-blur-md bg-gradient-to-t from-bg-secondary to-bg-secondary/90"
        style={{ height: playerHeight }}
      />
      
      {/* Main player container */}
      <div 
        className="relative px-4 sm:px-6 py-3 sm:py-4 border-t border-white/10 shadow-lg"
        style={{ height: playerHeight }}
      >
        <div className="flex items-center space-x-3 sm:space-x-4 max-w-7xl mx-auto">
          {/* Image with enhanced shadow */}
          <div className="relative">
            <div className="absolute inset-0 bg-black/20 rounded blur-md transform translate-y-1"></div>
            <img 
              src={sound.image || sound.image2 || '/default-sound-image.jpg'} 
              alt={sound.name} 
              className="w-12 h-12 sm:w-16 sm:h-16 object-cover rounded relative z-10"
            />
          </div>

          <div className="flex-grow overflow-hidden">
            {/* Title with subtle glow effect */}
            <div className="text-xs sm:text-sm font-semibold text-text-primary truncate mb-1 sm:mb-2 drop-shadow-sm">
              {sound.name}
            </div>

            <div className="flex items-center space-x-3">
              {/* Enhanced play button */}
              <button
                onClick={onPlayPause}
                className="bg-gradient-to-r from-accent-start to-accent-end text-white p-1.5 sm:p-2 rounded-full hover:opacity-90 transition-all duration-200 transform hover:scale-105 shadow-lg flex items-center justify-center flex-shrink-0"
              >
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
              </button>

              {/* Waveform container */}
              <div 
                ref={waveformRef} 
                className="flex-grow h-[48px] sm:h-[64px] cursor-pointer hover:opacity-95 transition-opacity duration-200"
                onClick={handleSeek}
                draggable={isJUCE() && sound.is_purchased}
                onDragStart={handleDragStart}
              />

              {/* Time display */}
              <div className="text-xs text-text-secondary whitespace-nowrap font-medium">
                {formatTime(currentTime)} / {formatTime(duration)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;