import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Logo } from '../components/Logo';
import SpinnerFull from '../components/SpinnerFull';
import { motion, AnimatePresence } from 'framer-motion';
import { AuthSection } from '../components/AuthSection';
import PricingSection from '../components/PricingSection';

// Animation variants
const sectionVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut"
    }
  }
};

const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut"
    }
  },
  hover: {
    y: -8,
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  }
};

const PricingCard = ({ plan, isPopular, onSelect }) => (
  <motion.div
    whileHover={{ y: -8 }}
    className={`relative p-6 rounded-2xl backdrop-blur-xl border ${
      isPopular ? 'bg-gradient-to-b from-accent-start/10 to-accent-end/10 border-accent-start/20' 
      : 'bg-bg-secondary/50 border-white/10'
    }`}
  >
    {isPopular && (
      <div className="absolute -top-4 left-1/2 -translate-x-1/2 px-4 py-1 rounded-full bg-gradient-to-r from-accent-start to-accent-end text-white text-sm">
        Most Popular
      </div>
    )}
    <div className="text-xl font-bold mb-2">{plan.name}</div>
    <div className="flex items-baseline mb-4">
      <span className="text-3xl font-bold">${plan.price}</span>
      <span className="text-text-secondary">/month</span>
    </div>
    <div className="text-text-secondary mb-6">
      {plan.tokens} tokens monthly
    </div>
    <ul className="space-y-3 mb-6">
      {plan.features.map((feature, index) => (
        <li key={index} className="flex items-center text-sm">
          <span className="mr-2 text-accent-start">✓</span>
          {feature}
        </li>
      ))}
    </ul>
    <motion.button
      whileHover={{ scale: 1.02 }}
      onClick={() => onSelect(plan)}
      whileTap={{ scale: 0.98 }}
      className={`w-full py-3 rounded-xl font-medium ${
        isPopular 
        ? 'bg-gradient-to-r from-accent-start to-accent-end text-white' 
        : 'bg-white/10 text-white hover:bg-white/20'
      }`}
    >
      Choose {plan.name}
    </motion.button>
  </motion.div>
);
const LandingPage = () => {
  // State management
  const [isLogin, setIsLogin] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    email: '',
    confirmPassword: '',
    agreeToTerms: false
  });
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { loginAndLoadUser, signupAndLoadUser, isLoading } = useContext(AuthContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Refs for scroll targets
  const authSectionRef = useRef(null);
  const soundsSectionRef = useRef(null);
  const pricingSectionRef = useRef(null);
  const creatorSectionRef = useRef(null);
  const demoSectionRef = useRef(null);
  const downloadSectionRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);

  // Smooth scroll function
  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      setIsMobileMenuOpen(false);
      setTimeout(() => {
        const yOffset = -80;
        const element = ref.current;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({
          top: y,
          behavior: 'smooth'
        });
      }, 100);
    }
  };

  const handleMenuClick = (sectionRef) => {
    scrollToSection(sectionRef);
  };

  const scrollToTop = () => {
    setIsMobileMenuOpen(false);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (shouldScroll) {
      scrollToSection(authSectionRef);
      setShouldScroll(false);
    }
  }, [isLogin, shouldScroll]);

  const handleAuthClick = (loginState) => {
    setIsLogin(loginState);
    setShouldScroll(true);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    try {
      if (isLogin) {
        const success = await loginAndLoadUser(formData.username, formData.password);
        if (success) {
          navigate('/dashboard');
        } else {
          setError('Login failed. Please check your credentials.');
        }
      } else {
        if (formData.password !== formData.confirmPassword) {
          setError('Passwords do not match.');
          return;
        }
        if (!formData.agreeToTerms) {
          setError('Please agree to the Terms of Service.');
          return;
        }
        const success = await signupAndLoadUser(
          formData.username,
          formData.password,
          formData.email
        );
        if (success) {
          navigate('/dashboard');
        } else {
          setError('Signup failed. Please try again.');
        }
      }
    } catch (err) {
      console.error('Auth error:', err);
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const { credential } = credentialResponse;
      const response = await loginAndLoadUser('', '', credential);
      if (response) {
        navigate('/dashboard');
      }
    } catch (err) {
      console.error('Google auth error:', err);
      setError('Failed to authenticate with Google. Please try again.');
    }
  };

  const handleGoogleError = () => {
    setError('Google sign-in was unsuccessful. Please try again.');
  };

  const features = [
    {
      title: "Unlimited Inspiration",
      description: "A community filled with inspiration and sounds from professional and independent creators around the world.",
      icon: "💡"
    },
    {
      title: "Cross-Platform",
      description: "Available on Mac, Windows, iOS, Web, with Android coming soon. Plus VST/VST3/AU plugin and standalone support.",
      icon: "🔄"
    },
    {
      title: "Access your sounds from anywhere",
      description: "Whether you’ve uploaded your own private collection or purchased in the marketplace, you’ll always have access to your sounds.",
      icon: "📤"
    },
    {
      title: "Pro Audio Tools",
      description: "Time-stretch and pitch-shift samples to match your project's BPM and key. Perfect sync, every time.",
      icon: "🎵"
    }
  ];

  const plans = [
    {
      name: "Starter",
      price: "9.99",
      tokens: 100,
      url: "https://store.soundware.io?add-to-cart=166941",
      features: [
        "100 monthly tokens",
        "100MB sound upload storage",
        "Standalone/VST/AU Plugin"
      ]
    },
    {
      name: "Pro",
      price: "19.99",
      tokens: 200,
      url: "https://store.soundware.io?add-to-cart=166976",
      features: [
        "200 monthly tokens",
        "1GB sound upload storage",
        "Standalone/VST/AU Plugin"
      ]
    },
    {
      name: "Ultimate",
      price: "39.99",
      tokens: 400,
      url: "https://store.soundware.io?add-to-cart=166978",
      features: [
        "400 monthly tokens",
        "5GB sound upload storage",
        "Standalone/VST/AU Plugin"
      ]
    }
  ];

  if (isLoading || isSubmitting) {
    return <SpinnerFull />;
  }

  const handlePlanSelect = (plan) => {
    window.location.href = plan.url;
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-bg-primary to-bg-primary/95">
      {/* Navigation */}
      <nav className="fixed w-full z-50 bg-bg-primary/80 backdrop-blur-xl border-b border-white/10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <motion.button
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="flex items-center space-x-4 cursor-pointer"
              onClick={scrollToTop}
              aria-label="Scroll to top"
            >
              <Logo className="h-8 w-auto" />
            </motion.button>
            
            <div className="flex md:hidden">
              <button 
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="text-text-secondary hover:text-text-primary p-2 rounded-lg"
                aria-label="Toggle menu"
              >
                
                {!isMobileMenuOpen ? (
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                ) : (
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                )}
              </button>
            </div>
            <motion.div 
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              className="hidden md:flex items-center space-x-8"
            >
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => handleMenuClick(creatorSectionRef)}
                className="text-text-secondary hover:text-text-primary transition-colors"
              >
                For Creators
              </motion.button>
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => handleMenuClick(soundsSectionRef)}
                className="text-text-secondary hover:text-text-primary transition-colors"
              >
                Sounds
              </motion.button>
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => handleMenuClick(pricingSectionRef)}
                className="text-text-secondary hover:text-text-primary transition-colors"
              >
                Pricing
              </motion.button>
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => handleMenuClick(authSectionRef)}
                className="text-text-secondary hover:text-text-primary transition-colors"
              >
                Download
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  setIsLogin(false);
                  handleMenuClick(authSectionRef);
                }}
                className="bg-gradient-to-r from-accent-start to-accent-end text-text-primary px-6 py-2 rounded-full font-medium"
              >
                Sign Up
              </motion.button>
            </motion.div>
          </div>
        </div>

        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2 }}
              className="md:hidden border-t border-white/10 bg-bg-secondary/95 backdrop-blur-xl"
            >

              <div className="px-4 py-4 space-y-4">
              <motion.button
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleMenuClick(creatorSectionRef)}
                  className="block w-full text-left text-text-secondary hover:text-text-primary transition-colors px-4 py-2 rounded-lg hover:bg-white/5 active:bg-white/10"
                >
                  For Creators
                </motion.button>
                <motion.button
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleMenuClick(soundsSectionRef)}
                  className="block w-full text-left text-text-secondary hover:text-text-primary transition-colors px-4 py-2 rounded-lg hover:bg-white/5 active:bg-white/10"
                >
                  Sounds
                </motion.button>
                <motion.button
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleMenuClick(pricingSectionRef)}
                  className="block w-full text-left text-text-secondary hover:text-text-primary transition-colors px-4 py-2 rounded-lg hover:bg-white/5 active:bg-white/10"
                >
                  Pricing
                </motion.button>

                <div className="grid grid-cols-2 gap-4 pt-2">
                  <motion.button
                    whileTap={{ scale: 0.95 }}
                    onClick={() => {
                      setIsLogin(true);
                      handleMenuClick(authSectionRef);
                    }}
                    className="w-full bg-bg-secondary/50 text-text-primary px-6 py-2 rounded-lg font-medium border border-white/10 hover:bg-bg-secondary/70 transition-colors active:bg-bg-secondary/90"
                  >
                    Sign In
                  </motion.button>
                  <motion.button
                    whileTap={{ scale: 0.95 }}
                    onClick={() => {
                      setIsLogin(false);
                      handleMenuClick(authSectionRef);
                    }}
                    className="w-full bg-gradient-to-r from-accent-start to-accent-end text-text-primary px-6 py-2 rounded-lg font-medium active:opacity-90"
                  >
                    Sign Up
                  </motion.button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>

{/* Hero Section */}
<div className="relative min-h-screen pt-16 pb-32 flex items-center">
        {/* Animated background */}
        <div className="absolute inset-0 overflow-hidden">
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <div className="w-[800px] h-[800px] bg-gradient-to-r from-accent-start/20 to-accent-end/20 rounded-full blur-3xl" />
          </motion.div>
        </div>
        
        {/* Content */}
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row items-center gap-16">               
           {/* Spacer for mobile */}
        <div className="h-2 md:h-0"></div>
         {/* Left Column - Hero Content */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex-1 text-center lg:text-left"
          >
            <h1 className="text-3xl sm:text-5xl lg:text-6xl font-bold text-text-primary mb-6">
              Create. Share.{' '}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
                Earn.
              </span>
            </h1>
            <p className="text-xl text-text-secondary mb-8 max-w-2xl mx-auto lg:mx-0">
              Join the open marketplace for music producers. Download premium sounds 
              or sell your own. Start earning from your music today.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
            <motion.button
              onClick={() => window.location.href = "https://f002.backblazeb2.com/file/soundwarereleases/SoundBank%20Win%20Installer.exe"} 
              whileHover={{ scale: 1.05 }}
  whileTap={{ scale: 0.95 }}
  className="bg-bg-secondary text-text-primary px-8 py-4 rounded-xl font-medium border border-white/10 hover:bg-bg-primary/50 transition-all duration-300 flex items-center gap-2"
>
  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
    <path d="M0 3.449L9.75 2.1v9.451H0m10.949-9.602L24 0v11.4H10.949M0 12.6h9.75v9.451L0 20.699M10.949 12.6H24V24l-12.9-1.801"/>
  </svg>
  Download for Windows
</motion.button>
<motion.button
                onClick={() => window.location.href = "https://f002.backblazeb2.com/file/soundwarereleases/SoundBank%20Mac%20Installer_signed.pkg"} 

  whileHover={{ scale: 1.05 }}
  whileTap={{ scale: 0.95 }}
  className="bg-bg-secondary text-text-primary px-8 py-4 rounded-xl font-medium border border-white/10 hover:bg-bg-primary/50 transition-all duration-300 flex items-center gap-2"
>
  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
    <path d="M22 17.607c-.786 2.28-3.139 6.317-5.563 6.361-1.608.031-2.125-.953-3.963-.953-1.837 0-2.412.923-3.932.983-2.572.099-6.542-5.827-6.542-10.995 0-4.747 3.308-7.1 6.198-7.143 1.55-.028 3.014 1.045 3.959 1.045.949 0 2.727-1.29 4.596-1.101.782.033 2.979.315 4.389 2.377-3.741 2.442-3.158 7.549.858 9.426zm-5.222-17.607c-2.826.114-5.132 3.079-4.81 5.531 2.612.203 5.118-2.725 4.81-5.531z"/>
  </svg>
  Download for macOS
</motion.button>
            </div>
          </motion.div>

          {/* Right Column - Auth Form */}
          <div ref={authSectionRef} className="flex-1 w-full px-4 sm:w-auto sm:px-0">
            <AuthSection
              isLogin={isLogin}
              setIsLogin={setIsLogin}
              formData={formData}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              error={error}
              isSubmitting={isSubmitting}
              handleGoogleSuccess={handleGoogleSuccess}
              handleGoogleError={handleGoogleError}
            />
          </div>
        </div>
      </div>

      {/* Creator Section */}
      <section ref={creatorSectionRef} className="relative py-32 overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-bg-secondary/5" />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-accent-end/5 to-transparent opacity-50" />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={sectionVariants}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold mb-4 text-text-primary">For Sound Creators</h2>
            <p className="text-text-secondary text-lg">Turn your sounds into income</p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-12 items-center">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="text-center md:text-left"
            >
              <h3 className="text-3xl font-bold mb-6 text-text-primary">
                Start Earning From Your{' '}
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
                  Creativity
                </span>
              </h3>
              <ul className="space-y-4 inline-block text-left">
                {[
                  "Upload, store, and publish your sounds",
                  "Set your own prices",
                  "Keep 70% of every sale",
                  "Cash out anytime",
                  "Build your brand",
                  "Real-time analytics",
                  "Control your royalties"
                ].map((item, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.1 }}
                    className="flex items-center text-text-secondary"
                  >
                    <span className="mr-2 text-accent-start">✓</span>
                    {item}
                  </motion.li>
                ))}
              </ul>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  handleAuthClick(false);
                }}
                className="mt-8 bg-gradient-to-r from-accent-start to-accent-end text-text-primary px-8 py-4 rounded-xl font-medium shadow-lg hover:shadow-accent-start/25 transition-all duration-300"
              >
                Start Selling
              </motion.button>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="relative flex justify-center"
            >
              <div className="relative max-w-full">
                <img
                  src="https://store.soundware.io/wp-content/uploads/2024/11/ammp-3d-screenshot-2.png"
                  alt="Creator Dashboard"
                  className="rounded-xl w-full"
                />
              </div>
            </motion.div>
          </div>
        </div>
      </section>
      {/* Features Section */}
      <section ref={soundsSectionRef} className="relative py-32 overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-bg-secondary/5" />
          <div className="absolute w-full h-full bg-gradient-to-b from-transparent via-accent-start/5 to-transparent opacity-50" />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={sectionVariants}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold mb-4 text-text-primary">Find Your Spark</h2>
            <p className="text-text-secondary text-lg">Everything you need to find and create amazing music</p>
          </motion.div>

          <div className="grid lg:grid-cols-2 gap-16 items-center mb-24">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="relative group"
            >
              <div className="absolute inset-0" />
              <img
                src="https://store.soundware.io/wp-content/uploads/2024/11/ammp-3d-screenshot-1.png"
                alt="Sound Design Interface"
                className="relative rounded-xl w-full"
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="space-y-8"
            >
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial="hidden"
                  whileInView="visible"
                  whileHover="hover"
                  viewport={{ once: true }}
                  variants={cardVariants}
                  transition={{ delay: index * 0.2 }}
                  className="p-6 rounded-xl backdrop-blur-sm bg-bg-secondary/5 border border-white/10 hover:border-accent-start/30 transition-colors"
                >
                  <div className="flex items-start space-x-4">
                    <div className="text-3xl">{feature.icon}</div>
                    <div>
                      <h3 className="text-lg font-semibold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
                        {feature.title}
                      </h3>
                      <p className="text-text-secondary text-sm">{feature.description}</p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </div>
      </section>

{/* Pricing Section */}
<section ref={pricingSectionRef} className="relative py-32 overflow-hidden">
  <div className="absolute inset-0">
    <div className="absolute inset-0 bg-bg-primary" />
    <div className="absolute inset-0 bg-gradient-to-b from-transparent via-bg-secondary/10 to-transparent" />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={sectionVariants}
      className="text-center mb-16"
    >
      <h2 className="text-4xl font-bold mb-4 text-text-primary">Choose Your Plan</h2>
      <p className="text-text-secondary text-lg">Start with 100 tokens, upgrade anytime</p>
    </motion.div>

    <div className="grid md:grid-cols-3 gap-8 text-white">
      {plans.map((plan, index) => (
        <motion.div
          key={index}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={cardVariants}
          transition={{ delay: index * 0.2 }}
        >
          <PricingCard 
            plan={plan} 
            isPopular={index === 1} 
            onSelect={handlePlanSelect}
          />
        </motion.div>
      ))}
    </div>
  </div>
</section>

{/* Add this section right after the Pricing Section, before the Footer */}
<section className="relative py-32 overflow-hidden">
  <div className="absolute inset-0">
    <div className="absolute inset-0 bg-bg-primary" />
    <div className="absolute inset-0 bg-gradient-to-b from-transparent via-bg-secondary/10 to-transparent" />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={sectionVariants}
      className="text-center mb-16"
    >
      <h2 className="text-4xl font-bold mb-4 text-text-primary">System Requirements</h2>
    </motion.div>

    <div className="grid md:grid-cols-2 gap-8">
      {/* Windows Requirements */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={cardVariants}
        className="p-6 rounded-2xl backdrop-blur-xl bg-bg-secondary/50 border border-white/10"
      >
        <div className="flex items-center space-x-3 mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-text-primary" viewBox="0 0 24 24" fill="currentColor">
            <path d="M0 3.449L9.75 2.1v9.451H0m10.949-9.602L24 0v11.4H10.949M0 12.6h9.75v9.451L0 20.699M10.949 12.6H24V24l-12.9-1.801"/>
          </svg>
          <h3 className="text-xl font-bold text-text-primary">Windows</h3>
        </div>
        <ul className="space-y-3">
          {[
            'VST or VST3 host software',
            'Pentium class 2.0 GHz processor with SSE3 support',
            '8GB of RAM (16GB or more highly recommended)',
            'Display with 1024-by-768 or higher resolution',
            'Windows 8, Windows 10, and Windows 11',
            '32-bit, 64-bit'
          ].map((req, index) => (
            <motion.li
              key={index}
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="flex items-start space-x-3"
            >
              <span className="text-accent-start mt-1">•</span>
              <span className="text-text-secondary text-sm">{req}</span>
            </motion.li>
          ))}
        </ul>
      </motion.div>

      {/* macOS Requirements */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={cardVariants}
        className="p-6 rounded-2xl backdrop-blur-xl bg-bg-secondary/50 border border-white/10"
      >
        <div className="flex items-center space-x-3 mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-text-primary" viewBox="0 0 24 24" fill="currentColor">
            <path d="M22 17.607c-.786 2.28-3.139 6.317-5.563 6.361-1.608.031-2.125-.953-3.963-.953-1.837 0-2.412.923-3.932.983-2.572.099-6.542-5.827-6.542-10.995 0-4.747 3.308-7.1 6.198-7.143 1.55-.028 3.014 1.045 3.959 1.045.949 0 2.727-1.29 4.596-1.101.782.033 2.979.315 4.389 2.377-3.741 2.442-3.158 7.549.858 9.426zm-5.222-17.607c-2.826.114-5.132 3.079-4.81 5.531 2.612.203 5.118-2.725 4.81-5.531z"/>
          </svg>
          <h3 className="text-xl font-bold text-text-primary">macOS</h3>
        </div>
        <ul className="space-y-3">
          {[
            'AudioUnit, VST or VST3 host software',
            'Apple Silicon M1 (native) or Intel 2.0 GHz processor',
            '8GB of RAM (16GB or more highly recommended)',
            'Display with 1024-by-768 or higher resolution',
            'macOS 10.13 and later including macOS Ventura',
            'Internet connection',
            '64-bit only'
          ].map((req, index) => (
            <motion.li
              key={index}
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="flex items-start space-x-3"
            >
              <span className="text-accent-start mt-1">•</span>
              <span className="text-text-secondary text-sm">{req}</span>
            </motion.li>
          ))}
        </ul>
      </motion.div>
    </div>
    <div className='h-8'/>
        {/* DAW Compatibility Section */}
        <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="text-center"
    >
      <div className="p-6">
        <img
          src="https://octane.soundware.io/wp-content/uploads/sites/3/2023/08/Daw-Icons-with-bitwig.png.webp"
          alt="DAW Compatibility"
          className="max-w-full h-auto mx-auto"
        />
      </div>
    </motion.div>
  </div>
</section>

      {/* Demo Section */}
      {/* <section ref={demoSectionRef} className="relative py-32 overflow-hidden">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={sectionVariants}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold mb-4 text-text-primary">See It In Action</h2>
            <p className="text-text-secondary text-lg">Watch how easy it is to get started</p>
          </motion.div>
          
          <div className="aspect-w-16 aspect-h-9">
            <div className="w-full h-full rounded-xl bg-bg-secondary/50 border border-white/10 flex items-center justify-center">
              <p className="text-text-secondary">Coming Soon</p>
            </div>
          </div>
        </div>
      </section> */}

      {/* Footer */}
      <footer className="relative py-12 border-t border-white/10">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-bg-secondary/30" />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-bg-primary" />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-5 gap-8 md:gap-12">
            {/* Logo and description */}
            <div className="col-span-1 md:col-span-2 text-center md:text-left">
              <div className="flex items-center justify-center md:justify-start space-x-4 mb-4">
                <Logo className="h-8 w-auto" />
              </div>
              <p className="text-text-secondary">
                Artist Music Marketplace Platform
              </p>
            </div>
            
            {/* Footer navigation */}
            {[
              {
                title: "Product",
                links: ["Sounds", "Features", "Pricing", "Updates"]
              },
              {
                title: "Company",
                links: ["About", "Blog", "Careers", "Contact"]
              },
              {
                title: "Resources",
                links: ["Documentation", "Help Center", "Terms", "Privacy"]
              }
            ].map((section, index) => (
              <div key={index} className="text-center md:text-left">
                <h4 className="font-bold text-lg mb-4 text-text-primary">{section.title}</h4>
                <ul className="space-y-2">
                  {section.links.map((link, i) => (
                    <li key={i}>
                      <button onClick={() => handleAuthClick()} className="text-text-secondary hover:text-text-primary transition-colors">
                        {link}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          
          {/* Copyright */}
          <div className="mt-12 pt-8 border-t border-white/10 text-center text-text-secondary">
            <p>© {new Date().getFullYear()} SoundWare, Inc. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;