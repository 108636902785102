// components/UploadSteps.jsx

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { CustomMultiSelect } from './CustomMultiSelect';
import { VALID_KEYS, VALID_STATUS, pageVariants } from '../utils/UploadUtils';
import { CustomDialog } from './CustomComponents';

export const StepContainer = ({ children }) => (
    <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={pageVariants}
        className="bg-bg-secondary rounded-xl shadow-xl backdrop-blur-sm 
                  border border-white/10 p-8 relative overflow-hidden"
    >
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-accent-start/5 to-accent-end/5" />
        <div className="relative z-10">
            {children}
        </div>
    </motion.div>
);

export const NavigationButtons = ({ onPrev, onNext, isLastStep, isSubmit, isUploading, contentOwnership }) => (
    <div className="flex justify-between mt-6">
        <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            type="button"
            onClick={onPrev}
            className="px-4 py-2 text-text-secondary hover:text-accent-start transition-colors"
        >
            Back
        </motion.button>
        <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            type={isSubmit ? "submit" : "button"}
            onClick={onNext}
            disabled={isSubmit && (isUploading || !contentOwnership)}
            className={`px-6 py-3 bg-gradient-to-r from-accent-start to-accent-end 
                     text-white rounded-full shadow-lg shadow-accent-start/20 
                     hover:shadow-accent-start/40 transition-all duration-300
                     ${(isSubmit && (isUploading || !contentOwnership)) ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
            {isSubmit ? (isUploading ? 'Uploading...' : 'Upload Sound') : 'Next'}
        </motion.button>
    </div>
);

export const ReviewItem = ({ label, value }) => (
    <p className="mb-1">
        <span className="text-text-secondary">{label}:</span>{' '}
        <span className="text-text-primary">{value}</span>
    </p>
);

export const SoundFileStep = ({ fileInputRef, dropZoneRef, handleFileSelection }) => (
    <div 
        ref={dropZoneRef}
        onDragOver={(e) => {
            e.preventDefault();
            dropZoneRef.current?.classList.add('border-accent-start');
        }}
        onDragLeave={(e) => {
            e.preventDefault();
            dropZoneRef.current?.classList.remove('border-accent-start');
        }}
        onDrop={(e) => {
            e.preventDefault();
            dropZoneRef.current?.classList.remove('border-accent-start');
            const droppedFile = e.dataTransfer.files[0];
            if (droppedFile && droppedFile.type.includes('audio')) {
                handleFileSelection(droppedFile);
            }
        }}
        className="border-4 border-dashed rounded-lg p-12 text-center hover:border-accent-start 
                 transition-all duration-300 ease-in-out transform hover:scale-[1.01] 
                 hover:shadow-lg hover:shadow-accent-start/20"
    >
        <div className="space-y-4">
            <motion.div 
                className="text-6xl mb-4"
                animate={{ 
                    scale: [1, 1.1, 1],
                    rotate: [0, 5, -5, 0]
                }}
                transition={{ 
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "reverse"
                }}
            >
                🎵
            </motion.div>
            <h2 className="text-2xl font-bold">Drop your sound file here</h2>
            <p className="text-text-secondary">or</p>
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => fileInputRef.current?.click()}
                className="px-6 py-3 bg-gradient-to-r from-accent-start to-accent-end 
                         text-white rounded-full transition-all duration-300 
                         hover:shadow-lg hover:shadow-accent-start/20"
            >
                Choose File
            </motion.button>
            <input
                ref={fileInputRef}
                type="file"
                accept=".wav,audio/wav,audio/x-wav"
                onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) handleFileSelection(file);
                }}
                className="hidden"
            />
            <p className="text-sm text-text-secondary">Supported formats: WAV</p>
        </div>
    </div>
);

export const ImageStep = ({ 
    imageInputRef, 
    imagePreview, 
    setImage, 
    setImagePreview, 
    onPrev, 
    onNext,
    name,
    isGeneratingImage,
    onGenerateAiImage 
}) => {
    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.type.startsWith('image/')) {
            if (imagePreview) {
                URL.revokeObjectURL(imagePreview);
            }
            setImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    return (
        <div className="space-y-6">
            <div>
                <label className="block text-sm font-medium text-text-secondary mb-2">
                    Upload Image
                </label>
                <div 
                    className="border-2 border-dashed border-gray-400 rounded-lg p-6 hover:border-accent-start transition-colors duration-300"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    {!imagePreview ? (
                        <div className="flex flex-col items-center gap-4">
                            <p className="text-text-secondary text-sm">Drop image here</p>
                            <div className="flex justify-center items-center gap-4">
                                <div className="relative">
                                    <button
                                        onClick={() => imageInputRef.current?.click()}
                                        className="px-6 py-2 bg-accent-start hover:bg-accent-end 
                                                 text-white rounded-full shadow-lg
                                                 transition-all duration-300 font-semibold"
                                    >
                                        Choose File
                                    </button>
                                    <input
                                        type="file"
                                        ref={imageInputRef}
                                        accept="image/*"
                                        onChange={(e) => {
                                            const file = e.target.files?.[0];
                                            if (file) {
                                                if (imagePreview) {
                                                    URL.revokeObjectURL(imagePreview);
                                                }
                                                setImage(file);
                                                setImagePreview(URL.createObjectURL(file));
                                            }
                                        }}
                                        className="hidden"
                                    />
                                </div>

                                <span className="text-text-secondary">or</span>

                                <motion.button
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    onClick={onGenerateAiImage}
                                    disabled={isGeneratingImage || !name}
                                    className={`px-6 py-2 bg-accent-start 
                                             text-white rounded-full shadow-lg shadow-accent-start/20 
                                             hover:bg-accent-end transition-all duration-300
                                             flex items-center justify-center space-x-2 whitespace-nowrap
                                             font-semibold
                                             ${(isGeneratingImage || !name) ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    {isGeneratingImage ? (
                                        <>
                                            <motion.div
                                                animate={{ rotate: 360 }}
                                                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                                                className="w-5 h-5 border-2 border-white border-t-transparent rounded-full"
                                            />
                                            <span>Finding artwork...</span>
                                        </>
                                    ) : (
                                        <>
                                            <span>Generate</span>
                                        </>
                                    )}
                                </motion.button>
                            </div>
                        </div>
                    ) : (
                        <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="relative group"
                        >
                            <img 
                                src={imagePreview} 
                                alt="Preview" 
                                className="max-w-full h-auto max-h-48 rounded-lg shadow-lg mx-auto" 
                            />
                            <div 
                                className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 
                                         transition-opacity duration-300 rounded-lg flex items-center justify-center"
                            >
                                <button
                                    onClick={() => {
                                        if (imagePreview) {
                                            URL.revokeObjectURL(imagePreview);
                                        }
                                        setImage(null);
                                        setImagePreview(null);
                                    }}
                                    className="text-white bg-red-500 hover:bg-red-600 px-4 py-2 rounded-full
                                             transition-colors duration-300"
                                >
                                    Remove Image
                                </button>
                            </div>
                        </motion.div>
                    )}
                </div>
                {!name && (
                    <p className="text-sm text-text-secondary mt-2">
                        Please add a name in the metadata step to generate artwork
                    </p>
                )}
            </div>

            <NavigationButtons onPrev={onPrev} onNext={onNext} />
        </div>
    );
};

export const VisibilityStep = ({ 
    status, 
    setStatus, 
    selectedLicense, 
    setSelectedLicense, 
    costInCredits, 
    setCostInCredits, 
    licenses, 
    onPrev, 
    onNext 
}) => {
    const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
    const selectedLicenseData = licenses.find(license => license.id === selectedLicense);

    const handleLicenseClick = (e) => {
        e.preventDefault();
        if (selectedLicense && selectedLicenseData?.url) {
            setIsLicenseModalOpen(true);
        }
    };

    return (
        <div className="space-y-6">
            <div className="space-y-4">
                <label className="block text-sm font-medium text-text-secondary mb-2">
                    Visibility
                </label>
                <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className="w-full rounded-md bg-bg-secondary border-gray-600 shadow-sm 
                             focus:border-accent-start focus:ring focus:ring-accent-start focus:ring-opacity-50 
                             h-12 px-4 transition-all duration-300"
                >
                    <option value="">Select visibility</option>
                    {VALID_STATUS.map((statusOption) => (
                        <option key={statusOption.value} value={statusOption.value}>
                            {statusOption.label}
                        </option>
                    ))}
                </select>
            </div>
            
            {status === 'active' && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="space-y-4"
                >
                    <div>
                        <label className="block text-sm font-medium text-text-secondary mb-2">
                            License
                        </label>
                        <div className="flex space-x-2">
                            <select
                                value={selectedLicense}
                                onChange={(e) => setSelectedLicense(e.target.value)}
                                className="flex-1 rounded-md bg-bg-secondary border-gray-600 shadow-sm 
                                         focus:border-accent-start focus:ring focus:ring-accent-start focus:ring-opacity-50 
                                         h-12 px-4 transition-all duration-300"
                                required
                            >
                                <option value="">Select a license</option>
                                {licenses.map((license) => (
                                    <option key={license.id} value={license.id}>
                                        {license.name}
                                    </option>
                                ))}
                            </select>
                            {selectedLicense && selectedLicenseData?.url && (
                                <button
                                    onClick={handleLicenseClick}
                                    className="px-4 h-12 bg-accent-start text-white rounded-md hover:bg-accent-end 
                                             transition-colors duration-300 flex items-center justify-center"
                                >
                                    View
                                </button>
                            )}
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-text-secondary mb-2">
                            Price in Tokens (1 Token = $0.07)
                        </label>
                        <input
                            type="number"
                            value={costInCredits}
                            onChange={(e) => setCostInCredits(e.target.value)}
                            className="w-full rounded-md bg-bg-secondary border-gray-600 shadow-sm 
                                     focus:border-accent-start focus:ring focus:ring-accent-start focus:ring-opacity-50 
                                     h-12 px-4 transition-all duration-300"
                            min="0"
                        />
                    </div>
                </motion.div>
            )}

            <CustomDialog 
                isOpen={isLicenseModalOpen} 
                onClose={() => setIsLicenseModalOpen(false)}
                title={`${selectedLicenseData?.name || 'License'} Agreement`}
            >
                <div className="space-y-4">
                    <div className="h-[calc(100vh-250px)] md:h-[600px] mb-4 bg-bg-primary rounded overflow-hidden">
                        <iframe
                            src={selectedLicenseData?.url}
                            className="w-full h-full border-0"
                            title="License Agreement"
                        />
                    </div>
                    <div className="flex justify-end">
                        <button
                            onClick={() => setIsLicenseModalOpen(false)}
                            className="px-4 py-2 rounded bg-accent-start text-white hover:bg-accent-end 
                                     transition-colors duration-200"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </CustomDialog>

            <div className="flex justify-between mt-6">
                <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    type="button"
                    onClick={onPrev}
                    className="px-4 py-2 text-text-secondary hover:text-accent-start transition-colors"
                >
                    Back
                </motion.button>
                <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    type="button"
                    onClick={onNext}
                    className="px-6 py-3 bg-gradient-to-r from-accent-start to-accent-end 
                             text-white rounded-full shadow-lg shadow-accent-start/20 
                             hover:shadow-accent-start/40 transition-all duration-300"
                >
                    Next
                </motion.button>
            </div>
        </div>
    );
};

export const MetadataStep = ({ 
    name, setName, description, setDescription, bpm, setBpm, 
    key, setKey, isAnalyzing, onPrev, onNext 
}) => (
    <div className="space-y-6">
        {isAnalyzing && (
            <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-accent-start/10 p-4 rounded-lg border border-accent-start/20"
            >
                <p className="text-sm text-accent-start flex items-center">
                    <motion.div
                        animate={{ rotate: 360 }}
                        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                        className="mr-2"
                    >
                        ⚡
                    </motion.div>
                    Analysis in progress... Fields will be automatically populated when complete.
                </p>
            </motion.div>
        )}

        <div className="space-y-4">
            <label className="block text-sm font-medium text-text-secondary mb-2">
                Name
            </label>
            <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full rounded-md bg-bg-secondary border-gray-600 shadow-sm 
                         focus:border-accent-start focus:ring focus:ring-accent-start focus:ring-opacity-50 
                         h-12 px-4 transition-all duration-300"
                required
            />
        </div>

        <div className="space-y-4">
            <label className="block text-sm font-medium text-text-secondary mb-2">
                Description
            </label>
            <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows="4"
                className="w-full rounded-md bg-bg-secondary border-gray-600 shadow-sm 
                         focus:border-accent-start focus:ring focus:ring-accent-start focus:ring-opacity-50 
                         px-4 py-2 transition-all duration-300"
            />
        </div>

        <div className="grid grid-cols-2 gap-4">
            <div>
                <label className="block text-sm font-medium text-text-secondary mb-2">
                    BPM
                </label>
                <input
                    type="number"
                    value={bpm}
                    onChange={(e) => setBpm(e.target.value)}
                    className="w-full rounded-md bg-bg-secondary border-gray-600 shadow-sm 
                             focus:border-accent-start focus:ring focus:ring-accent-start focus:ring-opacity-50 
                             h-12 px-4 transition-all duration-300"
                    min="0"
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-text-secondary mb-2">
                    Key
                </label>
                <select
                   value={key}
                   onChange={(e) => setKey(e.target.value)}
                   className="w-full rounded-md bg-bg-secondary border-gray-600 shadow-sm 
                            focus:border-accent-start focus:ring focus:ring-accent-start focus:ring-opacity-50 
                            h-12 px-4 transition-all duration-300"
               >
                   <option value="">Select a key</option>
                   {VALID_KEYS.map((validKey) => (
                       <option key={validKey} value={validKey}>
                           {validKey}
                       </option>
                   ))}
               </select>
           </div>
       </div>

       <NavigationButtons onPrev={onPrev} onNext={onNext} />
   </div>
);

export const ClassificationsStep = ({
   soundTypes,
   typeId,
   setTypeId,
   availableGenres,
   selectedGenres,
   setSelectedGenres,
   availableInstruments,
   selectedInstruments,
   setSelectedInstruments,
   availableTags,
   selectedTags,
   setSelectedTags,
   onPrev,
   onNext
}) => (
   <div className="space-y-6">
       <div className="space-y-6">
           <CustomMultiSelect
               options={soundTypes}
               value={typeId ? [soundTypes.find(type => type.id === typeId)] : []}
               onChange={selected => setTypeId(selected.length > 0 ? selected[0].id : '')}
               placeholder="Select a sound type..."
               label="Sound Type"
               isMulti={false}
               required={true}
           />

           <CustomMultiSelect
               options={availableGenres}
               value={selectedGenres}
               onChange={setSelectedGenres}
               placeholder="Select genres..."
               label="Genres"
               isMulti={true}
           />

           <CustomMultiSelect
               options={availableInstruments}
               value={selectedInstruments}
               onChange={setSelectedInstruments}
               placeholder="Select instruments..."
               label="Instruments"
               isMulti={true}
           />

           <CustomMultiSelect
               options={availableTags}
               value={selectedTags}
               onChange={setSelectedTags}
               placeholder="Select tags..."
               label="Tags"
               isMulti={true}
           />
       </div>

       <NavigationButtons onPrev={onPrev} onNext={onNext} />
   </div>
);

export const ReviewStep = ({
   fileName,
   audioPreview,
   togglePlayPause,
   isLocalPlaying,
   imagePreview,
   name,
   description,
   bpm,
   key,
   status,
   costInCredits,
   soundTypes,
   typeId,
   selectedGenres,
   selectedInstruments,
   selectedTags,
   selectedLicense,
   licenses,
   contentOwnership,
   setContentOwnership,
   onPrev,
   onSubmit,
   isUploading
}) => (
   <div className="space-y-6">
       <h2 className="text-xl font-semibold bg-gradient-to-r from-accent-start to-accent-end 
                     bg-clip-text text-transparent">Review Your Upload</h2>
       
       <div className="grid grid-cols-2 gap-4 p-6 bg-bg-secondary/50 rounded-lg backdrop-blur-sm 
                     border border-white/10 shadow-lg">
           <div>
               <h3 className="font-medium mb-2">Sound File</h3>
               <p className="text-sm text-text-secondary">{fileName}</p>
               {audioPreview && (
                   <motion.button
                       whileHover={{ scale: 1.02 }}
                       whileTap={{ scale: 0.98 }}
                       onClick={togglePlayPause}
                       className="mt-2 px-4 py-2 bg-gradient-to-r from-accent-start to-accent-end 
                                text-white rounded-full text-sm shadow-lg shadow-accent-start/20 
                                hover:shadow-accent-start/40 transition-all duration-300"
                   >
                       {isLocalPlaying ? 'Pause' : 'Play'} Preview
                   </motion.button>
               )}
           </div>
           
           <div>
               <h3 className="font-medium mb-2">Image</h3>
               {imagePreview && (
                   <motion.img 
                       initial={{ opacity: 0, scale: 0.9 }}
                       animate={{ opacity: 1, scale: 1 }}
                       src={imagePreview} 
                       alt="Preview" 
                       className="w-24 h-24 object-cover rounded-lg shadow-lg"
                   />
               )}
           </div>
       </div>

       <div className="grid grid-cols-2 gap-4">
           <div>
               <h3 className="font-medium mb-2">Details</h3>
               <ReviewItem label="Name" value={name} />
               <ReviewItem label="BPM" value={bpm} />
               <ReviewItem label="Key" value={key} />
               <ReviewItem label="Visibility" value={status === 'active' ? 'Public' : 'Private'} />
               {status === 'active' && (
                   <>
                       <ReviewItem label="Cost" value={`${costInCredits} credits`} />
                       <ReviewItem 
                           label="License" 
                           value={licenses.find(l => l.id === selectedLicense)?.name}
                       />
                   </>
               )}
           </div>

           <div>
               <h3 className="font-medium mb-2">Classifications</h3>
               <ReviewItem 
                   label="Type" 
                   value={soundTypes.find(type => type.id === typeId)?.name}
               />
               <ReviewItem 
                   label="Genres" 
                   value={selectedGenres.map(g => g.name).join(', ')}
               />
               <ReviewItem 
                   label="Instruments" 
                   value={selectedInstruments.map(i => i.name).join(', ')}
               />
               <ReviewItem 
                   label="Tags" 
                   value={selectedTags.map(t => t.name).join(', ')}
               />
           </div>
       </div>

       <div className="p-6 bg-bg-secondary/50 rounded-lg backdrop-blur-sm border border-white/10 shadow-lg">
           <h3 className="font-medium mb-2">Description</h3>
           <p className="text-sm">{description}</p>
       </div>

       <div className="flex items-center space-x-2 p-4 bg-bg-secondary/30 rounded-lg">
           <input
               type="checkbox"
               id="contentOwnership"
               checked={contentOwnership}
               onChange={(e) => setContentOwnership(e.target.checked)}
               className="h-4 w-4 text-accent-start focus:ring-accent-start border-gray-300 rounded 
                        transition-all duration-300"
               required
           />
           <label htmlFor="contentOwnership" className="text-sm text-text-secondary">
               I declare that I own or have rights to the sounds I'm uploading and that they do not infringe on third-party rights.
           </label>
       </div>

       <NavigationButtons 
           onPrev={onPrev} 
           onNext={onSubmit}
           isLastStep={true}
           isSubmit={true}
           isUploading={isUploading}
           contentOwnership={contentOwnership}
       />
   </div>
);