import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchRow } from '../components/SearchRow';
import { SoundList } from './SoundList';
import Spinner from '../components/Spinner';
import { fetchSounds, likeSound, unlikeSound, purchaseSound, getDownloadURL, fetchSoundById, fetchFeaturedSounds } from '../api/APIManager';
import { AuthContext } from '../context/AuthContext';
import { JUCESend, JUCEReceive, isJUCE } from '../context/JUCE';
import FeaturedSoundsCarousel from './FeaturedSoundsCarousel';

const SoundBrowser = ({ 
  additionalFilters = {}, 
  showFeatured = false, 
  title, 
  icon: IconComponent 
}) => {
  const { refreshToken } = useContext(AuthContext);
  const { setCurrentPlayingSound: setGlobalCurrentPlayingSound, setIsPlaying: setGlobalIsPlaying } = useOutletContext();
  const [sounds, setSounds] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [filters, setFilters] = useState({});
  const [isLoadingSounds, setIsLoadingSounds] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [currentPlayingSound, setCurrentPlayingSound] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  
  const pageSize = 20;
  const soundsRef = useRef(sounds);

  // Move all hooks to the top, before any conditional returns
  useEffect(() => {
    soundsRef.current = sounds;
  }, [sounds]);

  const handlePlay = useCallback((sound) => {
    if (currentPlayingSound && currentPlayingSound.id === sound.id) {
      isPlaying ? JUCESend('pauseSound') : JUCESend('playSound', sound);
      setIsPlaying(!isPlaying);
      setGlobalIsPlaying(!isPlaying);
    } else {
      setGlobalCurrentPlayingSound(sound);
      setCurrentPlayingSound(sound);
      JUCESend('playSound', sound);
      setIsPlaying(true);
      setGlobalIsPlaying(true);
    }
  }, [currentPlayingSound, isPlaying, setGlobalCurrentPlayingSound, setGlobalIsPlaying]);

  const handleKeyDown = useCallback((event) => {
    if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
      return;
    }

    if (event.code === 'Space') {
      event.preventDefault();
      if (currentPlayingSound) {
        handlePlay(currentPlayingSound);
      }
      return;
    }

    if (!currentPlayingSound) return;

    const currentIndex = soundsRef.current.findIndex(sound => sound.id === currentPlayingSound.id);
    let newIndex;

    if (event.key === 'ArrowDown') {
      newIndex = (currentIndex + 1) % soundsRef.current.length;
    } else if (event.key === 'ArrowUp') {
      newIndex = (currentIndex - 1 + soundsRef.current.length) % soundsRef.current.length;
    } else {
      return;
    }

    const newSound = soundsRef.current[newIndex];
    handlePlay(newSound);
  }, [currentPlayingSound, handlePlay]);

  const loadSounds = useCallback(async (newPage, newFilters = filters, isNewSearch = false) => {
    if (isLoadingSounds) return;
    setIsLoadingSounds(true);
    
    try {
      const response = await fetchSounds(newPage, pageSize, { ...newFilters, ...additionalFilters });
      
      const newSounds = isNewSearch ? response.items : [...sounds, ...response.items];
      const newHasMore = response.items.length === pageSize;

      setSounds(newSounds);
      setPage(newPage);
      setHasMore(newHasMore);
      setTotalResults(response.total);
    } catch (error) {
      console.error('Error fetching sounds:', error);
    } finally {
      setIsLoadingSounds(false);
      setIsSearching(false);
      setIsInitialLoad(false);
    }
  }, [isLoadingSounds, sounds, filters, additionalFilters]);

  useEffect(() => {
    if (sounds.length === 0 && !isSearching) {
      loadSounds(1);
    }
  }, [loadSounds, sounds.length, isSearching]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleSearch = (newFilters) => {
    const updatedFilters = {
      query: newFilters.searchText || ""
    };
  
    if (newFilters.genres?.length > 0) {
      updatedFilters.genre_ids = newFilters.genres.map((genre) => genre.id).join(',');
    }
  
    if (newFilters.tags?.length > 0) {
      updatedFilters.tag_ids = newFilters.tags.map((tag) => tag.id).join(',');
    }
  
    if (newFilters.instruments?.length > 0) {
      updatedFilters.instrument_ids = newFilters.instruments.map((instrument) => instrument.id).join(',');
    }
  
    if (newFilters.key?.length > 0) {
      updatedFilters.key = newFilters.key[0].name;
    }
  
    setFilters(updatedFilters);
    setIsSearching(true);
    setSounds([]);
    loadSounds(1, updatedFilters, true);
  };

  const handleLoadMore = () => {
    if (!isLoadingSounds && hasMore) {
      loadSounds(page + 1);
    }
  };

  const handleLike = async (soundId) => {
    const success = await likeSound(soundId);
    if (success) {
      const updatedSounds = sounds.map(sound => 
        sound.id === soundId ? { ...sound, is_liked: true } : sound
      );
      setSounds(updatedSounds);
    }
  };

  const handleUnlike = async (soundId) => {
    const success = await unlikeSound(soundId);
    if (success) {
      const updatedSounds = sounds.map(sound => 
        sound.id === soundId ? { ...sound, is_liked: false } : sound
      );
      setSounds(updatedSounds);
    }
  };

  const handlePurchase = async (soundId) => {
    const success = await purchaseSound(soundId);
    if (success) {
      const updatedSounds = sounds.map(sound => 
        sound.id === soundId ? { ...sound, is_purchased: true } : sound
      );
      setSounds(updatedSounds);
    }
    return success;
  };

  const handleDownload = async (soundId) => {
    const downloadUrl = await getDownloadURL(soundId);
    if (downloadUrl) {
      const sound = await fetchSoundById(soundId);
      const fileName = sound.file_name.substring(sound.file_name.lastIndexOf('/') + 1);
      await downloadFile(downloadUrl, fileName);
    }
  };

  const downloadFile = async (url, filename = 'sound.wav') => {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobURL = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = blobURL;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    
    document.body.removeChild(link);
    URL.revokeObjectURL(blobURL);
  };



const headerContent = (
  <>
    <div className="flex justify-between items-center p-4">
      <div className="flex items-center space-x-2">
        {IconComponent && <IconComponent className="w-6 h-6" />}
        <h1 className="text-2xl font-bold text-text-primary">{title}</h1>
      </div>
      {totalResults > 0 && (
        <div className="text-sm text-text-secondary">
          {totalResults.toLocaleString()} {totalResults === 1 ? 'sound' : 'sounds'} found
        </div>
      )}
    </div>
    <div className="sticky top-0 z-20 p-4 sm:p-4">
      {showFeatured && <FeaturedSoundsCarousel onPlay={handlePlay} />}
      <SearchRow onSearch={handleSearch} initialFilters={filters} />
    </div>
  </>
);

 // First loading state
 if (isInitialLoad) {
  return (
    <div>
    <div className="flex justify-center items-center h-64">
      <Spinner />
    </div>
    </div>
  );
}

// Show "No results" with header and search
if ((!sounds || sounds.length === 0) && !isSearching) {
  return (
    <div className="h-full flex flex-col">
      <div className="flex-grow overflow-y-auto bg-bg-primary">
        {headerContent}
        <div className="flex items-center justify-center h-32 text-text-secondary">
          No results
        </div>
      </div>
    </div>
  );
}

return (
  <div className="h-full flex flex-col">
    <div className="flex-grow overflow-y-auto bg-bg-primary" id="scrollableDiv">
      {isSearching ? (
        <>
          {headerContent}
          <div className="flex justify-center items-center h-64">
            <Spinner />
          </div>
        </>
      ) : sounds.length > 0 ? (
        <InfiniteScroll
          dataLength={sounds.length + 1}
          next={handleLoadMore}
          hasMore={hasMore}
          loader={
            <div className="flex justify-center items-center pb-12 relative z-50">
              <Spinner />
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          {headerContent}
          <div className="p-4 pt-4 sm:p-4">
            <SoundList 
              sounds={sounds} 
              onLike={handleLike}
              onUnlike={handleUnlike}
              onPurchase={handlePurchase}
              onPlay={handlePlay}
              onDownload={handleDownload}
              currentPlayingSound={currentPlayingSound}
              isPlaying={isPlaying}
            />
          </div>
        </InfiniteScroll>
      ) : null}
    </div>
  </div>
);
};

export default SoundBrowser;