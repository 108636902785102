import React, { useState, useEffect, useRef } from 'react';

export const PlayIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="white">
    <path d="M8 5v14l11-7z" />
  </svg>
);

export const PauseIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="white">
    <path d="M6 4h4v16H6zM14 4h4v16h-4z" />
  </svg>
);

export const LikeIcon = ({ filled }) => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill={filled ? "currentColor" : "none"} stroke="currentColor" strokeWidth="2">
    <path d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
  </svg>
);

export const MoreIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
    <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
  </svg>
);

export const DownloadIcon = () => {
  const [strokeColor, setStrokeColor] = useState('#000');

  useEffect(() => {
    const tempElement = document.createElement('div');
    tempElement.className = 'text-text-primary hidden';
    document.body.appendChild(tempElement);
    const primaryColor = getComputedStyle(tempElement).color;
    setStrokeColor(primaryColor);
    document.body.removeChild(tempElement);
  }, []);

  return (
    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke={strokeColor} strokeWidth="2">
      <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M7 10l5 5 5-5M12 15V3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const DragIcon = ({ onDragStart, onDragEnd, onMouseEnter, onMouseLeave }) => (
  <div 
    className="flex flex-col items-center justify-center cursor-grab active:cursor-grabbing w-full h-full"
    draggable="true"
    onDragStart={onDragStart}
    onDragEnd={onDragEnd}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <div className="flex flex-col items-center">
      <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
        <rect x="0" y="5" width="40" height="2" rx="1" />
        <rect x="0" y="11" width="40" height="2" rx="1" />
        <rect x="0" y="17" width="40" height="2" rx="1" />
      </svg>
      <span className="hidden sm:inline text-xs mt-1 text-center">Drag</span>
    </div>
  </div>
);

export const CheckIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
  </svg>
);

 export const TokenBadge = () => (
    <svg className="w-5 h-5 inline-block ml-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" fill="#FFD700"/>
      <text x="12" y="16" textAnchor="middle" fill="#000" fontSize="12" fontWeight="bold">₮</text>
    </svg>
  );

 export const CashBadge = () => (
    <svg className="w-5 h-5 inline-block ml-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" fill="#4CAF50"/>
      <text x="12" y="16" textAnchor="middle" fill="#fff" fontSize="14" fontWeight="bold">$</text>
    </svg>
  );

  export const VerifiedBadge = () => (
    <svg className="w-4 h-4 ml-1 inline-block" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM15.19 7.39L9.7 12.88C9.31 13.27 8.68 13.27 8.29 12.88L5.81 10.4C5.42 10.01 5.42 9.38 5.81 8.99C6.2 8.6 6.83 8.6 7.22 8.99L9 10.77L13.78 5.99C14.17 5.6 14.8 5.6 15.19 5.99C15.58 6.38 15.58 7.01 15.19 7.39Z" 
        fill="#0095F6"
      />
      <path 
        d="M15.19 7.39L9.7 12.88C9.31 13.27 8.68 13.27 8.29 12.88L5.81 10.4C5.42 10.01 5.42 9.38 5.81 8.99C6.2 8.6 6.83 8.6 7.22 8.99L9 10.77L13.78 5.99C14.17 5.6 14.8 5.6 15.19 5.99C15.58 6.38 15.58 7.01 15.19 7.39Z" 
        fill="white"
      />
    </svg>
  );