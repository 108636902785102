import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight, Play, CheckCircle } from 'lucide-react';
import { fetchFeaturedSounds } from '../api/APIManager';
import { DEFAULT_SOUND_IMAGE, DEFAULT_USER_IMAGE } from '../api/APIConfig';
import { VerifiedBadge } from '../assets/Icons';
import { useNavigate } from 'react-router-dom';

const FeaturedSoundsCarousel = ({ onPlay }) => {
  const navigate = useNavigate();
  const [featuredSounds, setFeaturedSounds] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getCardsToShow = () => {
    if (typeof window === 'undefined') return 2;
    if (window.innerWidth >= 1280) return 3;
    if (window.innerWidth >= 768) return 2;
    return 1.5;
  };

  const [cardsToShow, setCardsToShow] = useState(getCardsToShow());

  useEffect(() => {
    const handleResize = () => {
      setCardsToShow(getCardsToShow());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => {
      return (prevIndex + 1) % featuredSounds.length;
    });
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => {
      return prevIndex === 0 ? featuredSounds.length - 1 : prevIndex - 1;
    });
  };

  const handleProfileClick = (e, ownerId) => {
    e.stopPropagation();
    navigate(`/profile/${ownerId}`);
  };

  useEffect(() => {
    if (featuredSounds.length > 1) {
      const timer = setInterval(nextSlide, 6000);
      return () => clearInterval(timer);
    }
  }, [featuredSounds.length]);

  useEffect(() => {
    const loadFeaturedSounds = async () => {
      try {
        setIsLoading(true);
        const response = await fetchFeaturedSounds();
        if (response && response.items) {
          setFeaturedSounds(response.items);
        } else {
          setError('No featured sounds available');
        }
      } catch (error) {
        console.error('Error fetching featured sounds:', error);
        setError('Failed to load featured sounds');
      } finally {
        setIsLoading(false);
      }
    };
    loadFeaturedSounds();
  }, []);

  const containerClasses = "relative w-full h-[300px] sm:h-[400px] mb-8 overflow-hidden";
  const loadingContainerClasses = "w-full h-[300px] sm:h-[400px] mb-8 rounded-xl flex items-center justify-center bg-gradient-to-br from-purple-500/10 to-blue-500/10";

  if (isLoading) {
    return (
      <div className={loadingContainerClasses}>
        <div className="w-8 h-8 border-4 border-t-blue-500 border-blue-500/30 rounded-full animate-spin" />
      </div>
    );
  }

  if (error || featuredSounds.length === 0) {
    return (
      <div className={loadingContainerClasses}>
        <p className="text-lg text-gray-500">{error || 'No featured sounds available'}</p>
      </div>
    );
  }

  const cardWidth = `${100 / cardsToShow}%`;

  // Create a wrapped array for infinite scroll effect
  const displayedSounds = [
    ...featuredSounds.slice(-1),
    ...featuredSounds,
    ...featuredSounds.slice(0, 2)
  ];

  return (
    <div className={containerClasses}>
      <div className="absolute inset-0">
        <div className="flex items-center h-[280px] sm:h-[360px]">
          {featuredSounds.length > cardsToShow && (
            <button
              onClick={prevSlide}
              className="absolute left-4 z-10 bg-black/30 backdrop-blur-sm text-white p-2 rounded-full hover:bg-black/50 transition-all duration-200"
            >
              <ChevronLeft className="w-5 h-5" />
            </button>
          )}
          
          <div className="w-full h-full overflow-hidden">
            <motion.div
              className="flex gap-4 px-4 h-full"
              animate={{
                x: `-${(currentIndex * 100) / cardsToShow}%`
              }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
            >
              {displayedSounds.map((sound, index) => (
                <div
                  key={`${sound.id}-${index}`}
                  style={{ width: cardWidth }}
                  className="flex-none h-full rounded-xl overflow-hidden bg-gradient-to-br from-purple-500/10 to-blue-500/10"
                >
                  <div className="relative w-full h-full">
                    <img
                      src={sound.image || sound.image2 || DEFAULT_SOUND_IMAGE}
                      alt={sound.name}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/30 to-transparent" />
                    
                    <div className="absolute inset-0 p-4 flex flex-col justify-end">
                      <div className="flex items-center space-x-2 mb-2">
                        <button
                          onClick={(e) => handleProfileClick(e, sound.owner?.id)}
                          className="hover:opacity-80 transition-opacity"
                        >
                          <img
                            src={sound.owner?.avatar || DEFAULT_USER_IMAGE}
                            alt={sound.owner?.username}
                            className="w-6 h-6 rounded-full border border-white/20"
                          />
                        </button>
                        <button
                          onClick={(e) => handleProfileClick(e, sound.owner?.id)}
                          className="flex items-center hover:opacity-80 transition-opacity"
                        >
                          <span className="text-sm font-medium text-white/90 truncate">
                            {sound.owner?.username}
                          </span>
                          {sound.owner?.verified && (
                            <VerifiedBadge />
                          )}
                        </button>
                      </div>
                      
                      <div className="flex justify-between items-end">
                        <h3 className="text-lg font-bold text-white line-clamp-1 mr-4">{sound.name}</h3>
                        <button
                          onClick={() => onPlay(sound)}
                          className="flex-none inline-flex items-center p-2 rounded-full bg-white text-black hover:bg-white/90 transition-all duration-200"
                        >
                          <Play className="w-4 h-4" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </motion.div>
          </div>

          {featuredSounds.length > cardsToShow && (
            <button
              onClick={nextSlide}
              className="absolute right-4 z-10 bg-black/30 backdrop-blur-sm text-white p-2 rounded-full hover:bg-black/50 transition-all duration-200"
            >
              <ChevronRight className="w-5 h-5" />
            </button>
          )}
        </div>
        
        {featuredSounds.length > cardsToShow && (
          <div className="flex justify-center mt-4 space-x-2">
            {featuredSounds.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`w-2 h-2 rounded-full transition-all duration-200 ${
                  index === currentIndex ? 'bg-gray-800 w-6' : 'bg-gray-300'
                }`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FeaturedSoundsCarousel;