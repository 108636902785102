// pages/Upload.jsx

import React, { useState, useRef, useEffect } from 'react';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { STEPS, convertSharpToFlat, VALID_KEYS } from '../utils/UploadUtils';
import { generateAiImage } from '../api/APIManager';

import { 
    StepContainer, 
    SoundFileStep, 
    ImageStep, 
    VisibilityStep, 
    MetadataStep,
    ClassificationsStep,
    ReviewStep
} from '../components/UploadSteps';
import SpinnerFull from '../components/SpinnerFull';
import { 
    createSound, 
    updateSound, 
    fetchSoundById, 
    fetchSoundTypes, 
    fetchTags, 
    fetchGenres, 
    fetchInstruments, 
    analyzeAudio,
    createTag, 
    createGenre, 
    createInstrument, 
    fetchLicenses 
} from '../api/APIManager';

const Upload = () => {
    const { soundId } = useParams();
    const isEditing = !!soundId;
    const { setCurrentPlayingSound, setIsPlaying: setGlobalIsPlaying } = useOutletContext();
    const navigate = useNavigate();

    // Form state
    const [step, setStep] = useState(isEditing ? STEPS.REVIEW : STEPS.SOUND_FILE);
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [name, setName] = useState('');
    const [fileName, setFileName] = useState('');
    const [description, setDescription] = useState('');
    const [typeId, setTypeId] = useState('');
    const [bpm, setBpm] = useState('');
    const [key, setKey] = useState('');
    const [status, setStatus] = useState('');
    const [costInCredits, setCostInCredits] = useState('1');
    const [isUploading, setIsUploading] = useState(false);
    const [audioPreview, setAudioPreview] = useState(null);
    const [isLocalPlaying, setIsLocalPlaying] = useState(false);
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [analyzedData, setAnalyzedData] = useState(null);
    const [isLoading, setIsLoading] = useState(isEditing);
    const [contentOwnership, setContentOwnership] = useState(false);
    const [isGeneratingImage, setIsGeneratingImage] = useState(false);

    // References
    const fileInputRef = useRef(null);
    const imageInputRef = useRef(null);
    const dropZoneRef = useRef(null);

    // Options state
    const [soundTypes, setSoundTypes] = useState([]);
    const [availableTags, setAvailableTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [availableGenres, setAvailableGenres] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [availableInstruments, setAvailableInstruments] = useState([]);
    const [selectedInstruments, setSelectedInstruments] = useState([]);
    const [licenses, setLicenses] = useState([]);
    const [selectedLicense, setSelectedLicense] = useState('');

    useEffect(() => {
        const loadData = async () => {
            try {
                const [types, fetchedTags, fetchedGenres, fetchedInstruments, fetchedLicenses] = await Promise.all([
                    fetchSoundTypes(),
                    fetchTags(),
                    fetchGenres(),
                    fetchInstruments(),
                    fetchLicenses()
                ]);

                setSoundTypes(types);
                setAvailableTags(fetchedTags);
                setAvailableGenres(fetchedGenres);
                setAvailableInstruments(fetchedInstruments);
                setLicenses(fetchedLicenses);

                if (isEditing) {
                    const soundData = await fetchSoundById(soundId);
                    setName(soundData.name);
                    setDescription(soundData.description);
                    setTypeId(soundData.type.id);
                    setBpm(soundData.bpm?.toString() || '');
                    setKey(soundData.key || '');
                    setStatus(soundData.status || '');
                    setCostInCredits(soundData.cost_in_credits?.toString() || '1');
                    setSelectedTags(soundData.tags.map(tag => ({
                        id: tag.id,
                        name: tag.name
                    })));
                    setSelectedGenres(soundData.genres.map(genre => ({
                        id: genre.id,
                        name: genre.name
                    })));
                    setSelectedInstruments(soundData.instruments.map(instrument => ({
                        id: instrument.id,
                        name: instrument.name
                    })));
                    if (soundData.audio_preview) setAudioPreview(soundData.audio_preview);
                    if (soundData.image) setImagePreview(soundData.image);
                    if (soundData.license) setSelectedLicense(soundData.license?.id || '');
                }
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadData();
    }, [soundId, isEditing]);

    useEffect(() => {
        return () => {
            if (audioPreview) {
                URL.revokeObjectURL(audioPreview);
            }
            if (imagePreview) {
                URL.revokeObjectURL(imagePreview);
            }
        };
    }, [audioPreview, imagePreview]);

    // Add this new function inside the Upload component
    const handleGenerateAiImage = async () => {
        if (!name) return;
        
        setIsGeneratingImage(true);
        try {
            // Clean up the name for the query
            const cleanName = name.replace(/[^\w\s]/gi, '').trim();
            
            const imageBlob = await generateAiImage(cleanName);
            
            // Convert blob to file with a unique name
            const timestamp = new Date().getTime();
            const aiGeneratedFile = new File(
                [imageBlob], 
                `cover-${timestamp}.jpg`, 
                { type: 'image/jpeg' }
            );
            
            // Revoke previous preview URL if it exists
            if (imagePreview) {
                URL.revokeObjectURL(imagePreview);
            }
            
            setImage(aiGeneratedFile);
            setImagePreview(URL.createObjectURL(imageBlob));
        } catch (error) {
            console.error('Error generating image:', error);
            alert('Failed to generate image. Please try again or upload your own image.');
        } finally {
            setIsGeneratingImage(false);
        }
    };

    const handleFileSelection = async (selectedFile) => {
        setFile(selectedFile);
        if (selectedFile) {
            setFileName(selectedFile.name);
            const fileName = selectedFile.name.split('.').slice(0, -1).join('.');
            setName(fileName);
            const previewUrl = URL.createObjectURL(selectedFile);
            setAudioPreview(previewUrl);

            // Start analysis in background
            setIsAnalyzing(true);
            analyzeAudio(selectedFile).then(analysis => {
                setAnalyzedData(analysis);
                setBpm(Math.round(analysis.bpm).toString());
                const convertedKey = convertSharpToFlat(analysis.key);
                if (VALID_KEYS.includes(convertedKey)) {
                    setKey(convertedKey);
                }
            
                // Update genres
                const analyzedGenres = analysis.genres.split(', ')
                    .filter(genreName => genreName.trim() !== '') // Filter out empty strings
                    .map(genreName => {
                        const genre = availableGenres.find(g => g.name.toLowerCase() === genreName.toLowerCase());
                        return genre ? { id: genre.id, name: genre.name } : { name: genreName };
                    });
                setSelectedGenres(analyzedGenres);
            
                // Update instruments
                const analyzedInstruments = analysis.instruments
                    .filter(instrumentName => instrumentName.trim() !== '') // Filter out empty strings
                    .map(instrumentName => {
                        const instrument = availableInstruments.find(i => i.name.toLowerCase() === instrumentName.toLowerCase());
                        return instrument ? { id: instrument.id, name: instrument.name } : { name: instrumentName };
                    });
                setSelectedInstruments(analyzedInstruments);
            
                // Update tags/moods
                const analyzedTags = analysis.moods.split(', ')
                    .filter(moodName => moodName.trim() !== '') // Filter out empty strings
                    .map(moodName => {
                        const tag = availableTags.find(t => t.name.toLowerCase() === moodName.toLowerCase());
                        return tag ? { id: tag.id, name: tag.name } : { name: moodName };
                    });
                setSelectedTags(analyzedTags);
            }).catch(error => {
                console.error('Error analyzing audio:', error);
            }).finally(() => {
                setIsAnalyzing(false);
            });

            // Immediately proceed to next step without waiting for analysis
            setStep(STEPS.IMAGE);
        }
    };

    const togglePlayPause = () => {
        if (audioPreview) {
            if (isLocalPlaying) {
                setGlobalIsPlaying(false);
            } else {
                setCurrentPlayingSound({
                    id: 'preview',
                    audio_preview: audioPreview,
                    name: name || 'Preview'
                });
                setGlobalIsPlaying(true);
            }
            setIsLocalPlaying(!isLocalPlaying);
        }
    };

    const handleSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }

        if (!contentOwnership) {
            alert("You must declare content ownership before uploading.");
            return;
        }

        setIsUploading(true);

        try {
            const updatedTags = await Promise.all(selectedTags.map(async (tag) => {
                if (!tag.id) {
                    const newTag = await createTag({ name: tag.name.toLowerCase() });
                    return { id: newTag.id, name: newTag.name };
                }
                return { ...tag, name: tag.name.toLowerCase() };
            }));

            const updatedGenres = await Promise.all(selectedGenres.map(async (genre) => {
                if (!genre.id) {
                    const newGenre = await createGenre({ name: genre.name.toLowerCase() });
                    return { id: newGenre.id, name: newGenre.name };
                }
                return { ...genre, name: genre.name.toLowerCase() };
            }));

            const updatedInstruments = await Promise.all(selectedInstruments.map(async (instrument) => {
                if (!instrument.id) {
                    const newInstrument = await createInstrument({ name: instrument.name.toLowerCase() });
                    return { id: newInstrument.id, name: newInstrument.name };
                }
                return { ...instrument, name: instrument.name.toLowerCase() };
            }));

            const soundData = {
                file,
                fileName,
                image,
                name,
                description,
                tags: updatedTags,
                genres: updatedGenres,
                instruments: updatedInstruments,
                typeId,
                bpm,
                key,
                costInCredits,
                status,
                license: selectedLicense,
            };

            if (isEditing) {
                await updateSound(soundId, soundData);
            } else {
                await createSound(soundData);
            }

            navigate('/');
            window.location.reload();
        } catch (error) {
            // alert(`Error ${isEditing ? 'updating' : 'uploading'} sound. Please try again. ${error}`);
            console.error(`Error ${isEditing ? 'updating' : 'uploading'} sound:`, error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleNextStep = () => setStep(prevStep => prevStep + 1);
    const handlePrevStep = () => setStep(prevStep => prevStep - 1);

    const isStepCompleted = (stepIndex) => {
        switch (stepIndex) {
            case STEPS.SOUND_FILE:
                return !!file;
            case STEPS.IMAGE:
                return true; // Image is optional
            case STEPS.VISIBILITY:
                return !!status;
            case STEPS.METADATA:
                return !!name;
            case STEPS.CLASSIFICATIONS:
                return !!typeId;
            case STEPS.REVIEW:
                return true; // Review is always considered complete
            default:
                return false;
        }
    };

    const canAccessStep = (targetStep) => {
        if (isEditing) return true;

        switch (targetStep) {
            case STEPS.SOUND_FILE:
                return true;
            case STEPS.IMAGE:
                return !!file;
            case STEPS.VISIBILITY:
                return !!file;
            case STEPS.METADATA:
                return !!file;
            case STEPS.CLASSIFICATIONS:
                return !!file;
            case STEPS.REVIEW:
                return !!file;
            default:
                return false;
        }
    };

    const handleStepClick = (targetStep) => {
        if (canAccessStep(targetStep)) {
            setStep(targetStep);
        }
    };


    // Progress bar component
    const renderProgressBar = () => (
        <div className="mb-12 relative">
            <div className="absolute inset-0 bg-gradient-to-r from-accent-start/10 to-accent-end/10 blur-xl -z-10" />
            <div className="relative">
                <div className="flex justify-between mb-4">
                    {Object.keys(STEPS).map((stepKey, index) => {
                        const isActive = index <= step;
                        const isCurrentStep = index === step;
                        const isAnalyzingStep = isAnalyzing && index === STEPS.CLASSIFICATIONS;
                        const isClickable = canAccessStep(index);
                        
                        return (
                            <div 
                                key={stepKey} 
                                className="flex flex-col items-center relative"
                                onClick={() => handleStepClick(index)}
                                style={{ cursor: isClickable ? 'pointer' : 'not-allowed' }}
                            >
                                <motion.div
                                    initial={false}
                                    animate={{
                                        scale: isActive ? 1 : 0.8,
                                        backgroundColor: isActive ? 'var(--accent-end)' : 'var(--bg-secondary)',
                                    }}
                                    whileHover={isClickable ? { scale: 1.1 } : {}}
                                    className={`w-10 h-10 rounded-full flex items-center justify-center
                                        shadow-lg shadow-accent-start/20 border-2 
                                        ${isActive ? 'border-accent-end' : 'border-gray-600'}
                                        ${isAnalyzingStep ? 'animate-pulse' : ''}
                                        transition-all duration-300 ease-in-out
                                        ${isClickable ? 'hover:border-accent-start' : 'opacity-50'}`}
                                >
                                    {isAnalyzingStep ? (
                                        <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                                    ) : (
                                        <motion.div
                                            initial={false}
                                            animate={{ opacity: isActive ? 1 : 0.5 }}
                                            className={`text-sm ${isActive ? 'text-white' : 'text-gray-400'}`}
                                        >
                                            {index + 1}
                                        </motion.div>
                                    )}
                                </motion.div>

                                {index < Object.keys(STEPS).length - 1 && (
                                    <div className="absolute top-5 left-10 w-full h-[2px] -z-10">
                                        <div className="relative h-full">
                                            <div className="absolute inset-0 bg-gray-600" />
                                            <motion.div
                                                initial={false}
                                                animate={{
                                                    width: isActive ? "100%" : "0%",
                                                }}
                                                className="absolute inset-0 bg-gradient-to-r from-accent-start to-accent-end"
                                                transition={{ duration: 0.4, ease: "easeInOut" }}
                                            />
                                        </div>
                                    </div>
                                )}

                                <span className={`mt-3 text-sm font-medium transition-colors duration-300
                                    ${isActive ? 'text-accent-end' : 'text-gray-400'}
                                    ${isClickable ? 'hover:text-accent-start' : 'opacity-50'}`}>
                                    {stepKey.replace('_', ' ')}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );

    if (isLoading) {
        return <SpinnerFull />;
    }

    return (
        <div className="p-4 sm:p-8 max-w-2xl mx-auto mt-6">
            <div className="relative">
                <div className="absolute top-0 left-1/4 w-64 h-64 bg-accent-start opacity-20 rounded-full blur-3xl -z-10 animate-pulse" />
                <div className="absolute top-1/4 right-1/4 w-64 h-64 bg-accent-end opacity-20 rounded-full blur-3xl -z-10 animate-pulse" />
                
                <motion.h1 
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="text-4xl font-bold mb-8 bg-gradient-to-r from-accent-start to-accent-end 
                              bg-clip-text text-transparent"
                >
                    {isEditing ? 'Edit Your Sound' : 'Upload Your Sound'}
                </motion.h1>

                {!isEditing && renderProgressBar()}
                
                <AnimatePresence mode="wait">
                    <StepContainer key={step}>
                        {step === STEPS.SOUND_FILE && (
                            <SoundFileStep
                                fileInputRef={fileInputRef}
                                dropZoneRef={dropZoneRef}
                                handleFileSelection={handleFileSelection}
                            />
                        )}
{step === STEPS.IMAGE && (
    <ImageStep
        imageInputRef={imageInputRef}
        imagePreview={imagePreview}
        setImage={setImage}
        setImagePreview={setImagePreview}
        onPrev={handlePrevStep}
        onNext={handleNextStep}
        name={name}
        isGeneratingImage={isGeneratingImage}
        onGenerateAiImage={handleGenerateAiImage}
    />
)}
                        {step === STEPS.VISIBILITY && (
                            <VisibilityStep
                                status={status}
                                setStatus={setStatus}
                                selectedLicense={selectedLicense}
                                setSelectedLicense={setSelectedLicense}
                                costInCredits={costInCredits}
                                setCostInCredits={setCostInCredits}
                                licenses={licenses}
                                onPrev={handlePrevStep}
                                onNext={handleNextStep}
                            />
                        )}

                        {step === STEPS.METADATA && (
                            <MetadataStep
                                name={name}
                                setName={setName}
                                description={description}
                                setDescription={setDescription}
                                bpm={bpm}
                                setBpm={setBpm}
                                key={key}
                                setKey={setKey}
                                isAnalyzing={isAnalyzing}
                                onPrev={handlePrevStep}
                                onNext={handleNextStep}
                            />
                        )}
                        {step === STEPS.CLASSIFICATIONS && (
                            <ClassificationsStep
                                soundTypes={soundTypes}
                                typeId={typeId}
                                setTypeId={setTypeId}
                                availableGenres={availableGenres}
                                selectedGenres={selectedGenres}
                                setSelectedGenres={setSelectedGenres}
                                availableInstruments={availableInstruments}
                                selectedInstruments={selectedInstruments}
                                setSelectedInstruments={setSelectedInstruments}
                                availableTags={availableTags}
                                selectedTags={selectedTags}
                                setSelectedTags={setSelectedTags}
                                onPrev={handlePrevStep}
                                onNext={handleNextStep}
                            />
                        )}
                        {step === STEPS.REVIEW && (
                            <ReviewStep
                                fileName={fileName}
                                audioPreview={audioPreview}
                                togglePlayPause={togglePlayPause}
                                isLocalPlaying={isLocalPlaying}
                                imagePreview={imagePreview}
                                name={name}
                                description={description}
                                bpm={bpm}
                                key={key}
                                status={status}
                                costInCredits={costInCredits}
                                soundTypes={soundTypes}
                                typeId={typeId}
                                selectedGenres={selectedGenres}
                                selectedInstruments={selectedInstruments}
                                selectedTags={selectedTags}
                                selectedLicense={selectedLicense}
                                licenses={licenses}
                                contentOwnership={contentOwnership}
                                setContentOwnership={setContentOwnership}
                                onPrev={handlePrevStep}
                                onSubmit={handleSubmit}
                                isUploading={isUploading}
                            />
                        )}
                    </StepContainer>
                </AnimatePresence>
            </div>
            <div className="h-48" />
        </div>
    );
};

export default Upload;