import React, { useState, useEffect, useRef } from 'react';
import { CustomMultiSelect } from '../components/CustomMultiSelect';
import { motion } from 'framer-motion';
import { Trash2, RefreshCw, CheckCircle, AlertCircle, XCircle, Square, CheckSquare } from 'lucide-react';
import { Play, Pause } from 'lucide-react';

const VALID_STATUS = ["active", "inactive"];
const VALID_KEYS = [
    "C Major", "Db Major", "D Major", "Eb Major", "E Major", "F Major",
    "Gb Major", "G Major", "Ab Major", "A Major", "Bb Major", "B Major",
    "C Minor", "Db Minor", "D Minor", "Eb Minor", "E Minor", "F Minor",
    "Gb Minor", "G Minor", "Ab Minor", "A Minor", "Bb Minor", "B Minor"
];

const BatchUploadTable = ({
    files,
    setFiles,
    soundTypes,
    availableTags,
    availableGenres,
    availableInstruments,
    licenses,
    createSound,
    createTag,
    createGenre,
    createInstrument,
    setErrorMessage,
    editMode = false  
}) => {
    const [playingIndex, setPlayingIndex] = useState(null);
    const [defaultLicense, setDefaultLicense] = useState('');
    const [defaultSoundType, setDefaultSoundType] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState(new Set());
    const abortControllerRef = useRef(null);
    const uploadQueueRef = useRef([]);

    const [bulkEdit, setBulkEdit] = useState({
        description: '',
        tags: [],
        genres: [],
        instruments: [],
        typeId: '',
        bpm: '',
        key: '',
        status: '',
        costInCredits: '',
        license: '',
        image: null,
        imagePreview: null,
    });

    useEffect(() => {
        // Initialize selected files based on editMode
        setSelectedFiles(new Set(editMode ? [] : files.map((_, index) => index)));
    }, [files.length, editMode]);


    useEffect(() => {
        return () => {
            // Cleanup: abort any pending requests when component unmounts
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, []);

    const toggleFileSelection = (index) => {
        const newSelected = new Set(selectedFiles);
        if (newSelected.has(index)) {
            newSelected.delete(index);
        } else {
            newSelected.add(index);
        }
        setSelectedFiles(newSelected);
    };

    const toggleAllSelection = () => {
        if (selectedFiles.size === files.length) {
            setSelectedFiles(new Set());
        } else {
            setSelectedFiles(new Set(files.map((_, index) => index)));
        }
    };

    const createAbortController = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        return abortControllerRef.current.signal;
    };

    const handleUploadSelected = async () => {
        const selectedIndexes = Array.from(selectedFiles);
        const invalidFiles = selectedIndexes
            .map(index => files[index])
            .filter(file => !validateFile(file));

        if (invalidFiles.length > 0) {
            setErrorMessage('Please fill in all required fields for selected files before uploading');
            return;
        }

        setIsUploading(true);
        uploadQueueRef.current = [...selectedIndexes];

        for (const index of selectedIndexes) {
            if (!uploadQueueRef.current.includes(index)) {
                continue; // Skip if removed from queue
            }
            if (files[index].uploadStatus !== 'success') {
                await handleRetry(index);
            }
        }

        setIsUploading(false);
        uploadQueueRef.current = [];
    };
// Add these function definitions inside the BatchUploadTable component, before the return statement

const validateFile = (file) => {
    if (!file.typeId) {
        setErrorMessage(`Sound type is required for ${file.name}`);
        return false;
    }
    if (!file.license) {
        setErrorMessage(`License is required for ${file.name}`);
        return false;
    }
    return true;
};

const handleBulkImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            setBulkEdit(prev => ({ ...prev, image: file, imagePreview: reader.result }));
            setFiles(prevFiles => prevFiles.map(prevFile => ({ 
                ...prevFile, 
                image: file,
                imagePreview: reader.result 
            })));
        };
        reader.readAsDataURL(file);
    }
};

const handleBulkEditChange = (field, value) => {
    setBulkEdit(prev => ({ ...prev, [field]: value }));
    setFiles(prevFiles => prevFiles.map(file => ({ ...file, [field]: value })));
};

const handleBulkMultiSelectChange = (field, newValue) => {
    setBulkEdit(prev => ({ ...prev, [field]: newValue }));
    setFiles(prevFiles => prevFiles.map(file => ({ ...file, [field]: newValue })));
};

    const handleInputChange = (index, field, value) => {
        setFiles(prevFiles => {
            const newFiles = [...prevFiles];
            newFiles[index] = { ...newFiles[index], [field]: value };
            return newFiles;
        });
        
        // Auto-select the edited row
        setSelectedFiles(prev => new Set([...prev, index]));
    };

    const handleImageChange = (index, e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFiles(prevFiles => {
                    const newFiles = [...prevFiles];
                    newFiles[index] = { 
                        ...newFiles[index], 
                        image: file,
                        imagePreview: reader.result 
                    };
                    return newFiles;
                });
                
                // Auto-select the edited row
                setSelectedFiles(prev => new Set([...prev, index]));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleMultiSelectChange = (index, field, newValue) => {
        setFiles(prevFiles => {
            const newFiles = [...prevFiles];
            newFiles[index] = { 
                ...newFiles[index], 
                [field]: newValue.map(item => ({
                    id: item.id || (field === 'tags' ? availableTags : field === 'genres' ? availableGenres : availableInstruments).find(t => t.name === item.name)?.id,
                    name: item.name
                }))
            };
            return newFiles;
        });
        
        // Auto-select the edited row
        setSelectedFiles(prev => new Set([...prev, index]));
    };

const handleDelete = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setSelectedFiles(prev => {
        const newSelected = new Set(prev);
        newSelected.delete(index);
        // Adjust indices of files after the deleted one
        const adjustedSelected = new Set();
        newSelected.forEach(i => {
            if (i < index) adjustedSelected.add(i);
            if (i > index) adjustedSelected.add(i - 1);
        });
        return adjustedSelected;
    });
};

// State for upload cancellation
const [uploadCancelled, setUploadCancelled] = useState(false);

const handleRetry = async (index) => {
    const file = files[index];
    
    if (!validateFile(file)) {
        return;
    }

    try {
        setIsUploading(true);
        setUploadCancelled(false);
        
        setFiles(prevFiles => {
            const newFiles = [...prevFiles];
            newFiles[index] = { 
                ...newFiles[index], 
                uploadStatus: 'uploading',
                license: newFiles[index].license || defaultLicense,
                typeId: newFiles[index].typeId || defaultSoundType
            };
            return newFiles;
        });

        if (uploadCancelled) {
            return;
        }

        const signal = createAbortController();

        // Create tags
        const updatedTags = await Promise.all(file.tags.map(async (tag) => {
            if (!tag.id) {
                const newTag = await createTag({ name: tag.name.toLowerCase() }, signal);
                return { id: newTag.id, name: newTag.name };
            }
            return { ...tag, name: tag.name.toLowerCase() };
        }));

        if (uploadCancelled) {
            return;
        }

        // Create genres
        const updatedGenres = await Promise.all(file.genres.map(async (genre) => {
            if (!genre.id) {
                const newGenre = await createGenre({ name: genre.name.toLowerCase() }, signal);
                return { id: newGenre.id, name: newGenre.name };
            }
            return { ...genre, name: genre.name.toLowerCase() };
        }));

        if (uploadCancelled) {
            return;
        }

        // Create instruments
        const updatedInstruments = await Promise.all(file.instruments.map(async (instrument) => {
            if (!instrument.id) {
                const newInstrument = await createInstrument({ name: instrument.name.toLowerCase() }, signal);
                return { id: newInstrument.id, name: newInstrument.name };
            }
            return { ...instrument, name: instrument.name.toLowerCase() };
        }));

        if (uploadCancelled) {
            return;
        }

        // Create sound
        await createSound({
            ...file,
            tags: updatedTags,
            genres: updatedGenres,
            instruments: updatedInstruments,
            image: file.image,
            license: file.license || defaultLicense,
            typeId: file.typeId || defaultSoundType,
        }, signal);

        setFiles(prevFiles => {
            const newFiles = [...prevFiles];
            newFiles[index] = { ...newFiles[index], uploadStatus: 'success' };
            return newFiles;
        });

        // Remove the successfully uploaded/updated row from selection
        setSelectedFiles(prev => {
            const newSelected = new Set(prev);
            newSelected.delete(index);
            return newSelected;
        });

    } catch (error) {
        console.error(`Error uploading sound: ${file.name}`, error);
        setFiles(prevFiles => {
            const newFiles = [...prevFiles];
            newFiles[index] = { 
                ...newFiles[index], 
                uploadStatus: error.message === 'Upload cancelled' ? 'cancelled' : 'error' 
            };
            return newFiles;
        });
    } finally {
        setIsUploading(false);
    }
};
    const handleCancelUpload = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        uploadQueueRef.current = [];
        setIsUploading(false);
        
        setFiles(prevFiles => prevFiles.map(file => ({
            ...file,
            uploadStatus: file.uploadStatus === 'uploading' ? 'cancelled' : file.uploadStatus
        })));
        
        setErrorMessage('Upload queue cancelled');
    };


    const handleUploadAll = async () => {
        const invalidFiles = files.filter(file => !validateFile(file));
        if (invalidFiles.length > 0) {
            setErrorMessage('Please fill in all required fields before uploading');
            return;
        }

        setIsUploading(true);
        setUploadCancelled(false);

        for (let i = 0; i < files.length; i++) {
            if (uploadCancelled) {
                break;
            }
            if (files[i].uploadStatus !== 'success') {
                await handleRetry(i);
            }
        }

        setIsUploading(false);
    };

    const StatusIndicator = ({ status, onClick, children }) => {
        const variants = {
            analyzing: { rotate: 360 },
            uploading: { rotate: 360 },
            error: { scale: [1, 1.1, 1] },
            success: { scale: [1, 1.1, 1] },
            cancelled: { scale: [1, 1.1, 1] },
        };

        return (
            <motion.div
                variants={variants}
                animate={status}
                transition={{ 
                    duration: status === 'analyzing' || status === 'uploading' ? 1 : 0.2,
                    repeat: status === 'analyzing' || status === 'uploading' ? Infinity : 0
                }}
                className="flex items-center justify-center"
                onClick={onClick}
            >
                {children}
            </motion.div>
        );
    };

    const inputClass = `
        w-full rounded-lg bg-bg-secondary border border-gray-700 text-text-primary
        transition-all duration-200 ease-in-out
        focus:border-accent-start focus:ring-2 focus:ring-accent-start/20 
        h-9 px-3 py-1 text-sm hover:border-accent-start/50
        shadow-lg shadow-black/10
        font-sans
    `;

    const selectClass = `
        appearance-none w-full bg-bg-secondary text-text-primary border border-gray-700
        rounded-lg px-3 py-1 pr-8 h-9 text-sm
        transition-all duration-200 ease-in-out
        focus:outline-none focus:ring-2 focus:ring-accent-start/20 
        focus:border-accent-start hover:border-accent-start/50
        shadow-lg shadow-black/10
        font-sans
    `;

    const fileInputClass = `
        block w-full text-sm text-text-secondary
        file:mr-4 file:py-2 file:px-4
        file:rounded-full file:border file:border-white/20
        file:text-sm file:font-medium
        file:bg-white/10 file:text-text-primary
        hover:file:bg-white/20
        file:transition-all file:duration-200
        cursor-pointer
        font-sans
    `;

    return (
        <div className="relative">
            <div className="sticky top-0 z-30 flex justify-between items-center p-4 bg-bg-secondary/90 backdrop-blur-sm">
                <div className="flex gap-4 items-center">
                    <button
                        onClick={handleUploadSelected}
                        disabled={isUploading || selectedFiles.size === 0}
                        className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200
                            ${isUploading || selectedFiles.size === 0
                                ? 'bg-gray-600 cursor-not-allowed' 
                                : 'bg-accent-start hover:bg-accent-start/80'}`}
                    >
                        {editMode 
                            ? `Update Selected (${selectedFiles.size})`
                            : `Upload Selected (${selectedFiles.size})`
                        }
                    </button>
                    {isUploading && (
                        <button
                            onClick={handleCancelUpload}
                            className="px-4 py-2 rounded-lg bg-red-500 hover:bg-red-600 text-sm font-medium transition-colors duration-200"
                        >
                            Cancel {editMode ? 'Update' : 'Upload'}
                        </button>
                    )}
                </div>
            </div>
            <div className="overflow-x-auto rounded-lg shadow-xl bg-bg-secondary/30 backdrop-blur-sm">
                <div style={{ width: '3200px' }} className="min-h-[400px]">
                    <table className="w-full border-collapse font-sans">
                        <thead>
                            <tr className="bg-bg-secondary/70 backdrop-blur-sm">
                                <th className="sticky left-0 z-20 px-4 py-3 text-left text-xs font-medium text-text-secondary uppercase tracking-wider">
                                    <button
                                        onClick={toggleAllSelection}
                                        className="p-1 hover:bg-white/5 rounded transition-colors"
                                    >
                                        {selectedFiles.size === files.length ? (
                                            <CheckSquare className="w-4 h-4 text-white/70" />
                                        ) : (
                                            <Square className="w-4 h-4 text-white/70" />
                                        )}
                                    </button>
                                </th>
                                <th className="sticky left-[60px] z-20 px-4 py-3 text-left text-xs font-medium text-text-secondary uppercase tracking-wider w-[300px]">Name</th>
                                <th className="sticky left-[360px] z-20 px-4 py-3 text-left text-xs font-medium text-text-secondary uppercase tracking-wider">Actions</th>
                                <th className="sticky left-[445px] z-20 px-4 py-3 text-left text-xs font-medium text-text-secondary uppercase tracking-wider w-20">Preview</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-text-secondary uppercase tracking-wider">Image</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-text-secondary uppercase tracking-wider">Image Preview</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-text-secondary uppercase tracking-wider">Description</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-text-secondary uppercase tracking-wider">Tags</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-text-secondary uppercase tracking-wider">Genres</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-text-secondary uppercase tracking-wider">Instruments</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-text-secondary uppercase tracking-wider">
                                    Type <span className="text-red-500">*</span>
                                </th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-text-secondary uppercase tracking-wider">BPM</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-text-secondary uppercase tracking-wider">Key</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-text-secondary uppercase tracking-wider">Status</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-text-secondary uppercase tracking-wider">Cost</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-text-secondary uppercase tracking-wider">
                                    License <span className="text-red-500">*</span>
                                </th>
                            </tr>
                            <tr className="bg-bg-secondary/50">
                                <th className="sticky left-0 z-20 px-4 py-3 text-left bg-bg-secondary/70"></th>
                                <th className="sticky left-[60px] z-20 px-4 py-3 bg-bg-secondary/70">Bulk Edit</th>
                                <th className="sticky left-[360px] z-20 px-4 py-3 bg-bg-secondary/70"></th>
                                <th className="sticky left-[445px] z-20 px-4 py-3 bg-bg-secondary/70"></th>
                                <th className="px-4 py-3">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleBulkImageChange}
                                        className={fileInputClass}
                                    />
                                </th>
                                <th className="px-4 py-3">
                                    {bulkEdit.imagePreview && (
                                        <img 
                                            src={bulkEdit.imagePreview} 
                                            alt="Bulk Preview" 
                                            className="mt-2 max-w-full h-auto max-h-24 rounded-lg shadow-md" 
                                        />
                                    )}
                                </th>
                                <th className="px-4 py-3">
                                    <input
                                        type="text"
                                        value={bulkEdit.description}
                                        onChange={(e) => handleBulkEditChange('description', e.target.value)}
                                        className={inputClass}
                                        placeholder="Description"
                                    />
                                </th>
                                <th className="px-4 py-3">
                                    <CustomMultiSelect
                                        options={availableTags}
                                        value={bulkEdit.tags}
                                        onChange={(newValue) => handleBulkMultiSelectChange('tags', newValue)}
                                        placeholder="Tags"
                                        isMulti={true}
                                    />
                                </th>
                                <th className="px-4 py-3">
                                    <CustomMultiSelect
                                        options={availableGenres}
                                        value={bulkEdit.genres}
                                        onChange={(newValue) => handleBulkMultiSelectChange('genres', newValue)}
                                        placeholder="Genres"
                                        isMulti={true}
                                    />
                                </th>
                                <th className="px-4 py-3">
                                    <CustomMultiSelect
                                        options={availableInstruments}
                                        value={bulkEdit.instruments}
                                        onChange={(newValue) => handleBulkMultiSelectChange('instruments', newValue)}
                                        placeholder="Instruments"
                                        isMulti={true}
                                    />
                                </th>
                                <th className="px-4 py-3">
                                    <CustomMultiSelect
                                        options={soundTypes}
                                        value={bulkEdit.typeId ? [soundTypes.find(type => type.id === bulkEdit.typeId)] : []}
                                        onChange={(newValue) => handleBulkEditChange('typeId', newValue[0]?.id || '')}
                                        placeholder="Type *"
                                        isMulti={false}
                                    />
                                </th>
                                <th className="px-4 py-3">
                                    <input
                                        type="number"
                                        value={bulkEdit.bpm}
                                        onChange={(e) => handleBulkEditChange('bpm', e.target.value)}
                                        className={inputClass}
                                        placeholder="BPM"
                                    />
                                </th>
                                <th className="px-4 py-3">
                                    <select
                                        value={bulkEdit.key}
                                        onChange={(e) => handleBulkEditChange('key', e.target.value)}
                                        className={selectClass}
                                    >
                                        <option value="">Select key</option>
                                        {VALID_KEYS.map((validKey) => (
                                            <option key={validKey} value={validKey}>{validKey}</option>
                                        ))}
                                    </select>
                                </th>
                                <th className="px-4 py-3">
                                    <select
                                        value={bulkEdit.status}
                                        onChange={(e) => handleBulkEditChange('status', e.target.value)}
                                        className={selectClass}
                                    >
                                        <option value="">Select status</option>
                                        {VALID_STATUS.map((validStatus) => (
                                            <option key={validStatus} value={validStatus}>{validStatus}</option>
                                        ))}
                                    </select>
                                </th>
                                <th className="px-4 py-3">
                                    <input
                                        type="number"
                                        value={bulkEdit.costInCredits}
                                        onChange={(e) => handleBulkEditChange('costInCredits', e.target.value)}
                                        className={inputClass}
                                        placeholder="Cost"
                                    />
                                </th>
                                <th className="px-4 py-3">
                                    <select
                                        value={bulkEdit.license}
                                        onChange={(e) => handleBulkEditChange('license', e.target.value)}
                                        className={selectClass}
                                    >
                                        <option value="">Select license *</option>
                                        {licenses.map((license) => (
                                            <option key={license.id} value={license.id}>{license.name}</option>
                                        ))}
                                    </select>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {files.map((file, index) => (
                                <motion.tr
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.3, delay: index * 0.05 }}
                                    className={`group transition-colors duration-200 hover:bg-bg-secondary/50 
                                        ${file.isAnalyzing ? "opacity-50" : ""}
                                        ${file.uploadStatus === 'cancelled' ? "opacity-50 bg-red-900/10" : ""}
                                        ${selectedFiles.has(index) ? "bg-black/30" : ""}`}
                                >
                                    <td className="sticky left-0 z-10 px-4 py-3 bg-bg-secondary/70 backdrop-blur-sm">
                                        <button
                                            onClick={() => toggleFileSelection(index)}
                                            className="p-1 hover:bg-white/5 rounded transition-colors"
                                            disabled={file.uploadStatus === 'success'}
                                        >
                                            {selectedFiles.has(index) ? (
                                                <CheckSquare className="w-4 h-4 text-white/70" />
                                            ) : (
                                                <Square className="w-4 h-4 text-white/70" />
                                            )}
                                        </button>
                                    </td>
                                    <td className="sticky left-[60px] z-10 px-4 py-3 bg-bg-secondary/70 backdrop-blur-sm">
                                        <input
                                            type="text"
                                            value={file.name}
                                            onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                                            className={inputClass}
                                        />
                                    </td>
                                    <td className="sticky left-[360px] z-10 px-4 py-3 bg-bg-secondary/70 backdrop-blur-sm">
                                        {file.isAnalyzing || file.uploadStatus === 'uploading' ? (
                                            <StatusIndicator status="uploading">
                                                <RefreshCw className="w-5 h-5 text-accent-start" />
                                            </StatusIndicator>
                                        ) : file.uploadStatus === 'error' ? (
                                            <StatusIndicator status="error" onClick={() => handleRetry(index)}>
                                                <AlertCircle className="w-5 h-5 text-red-500 hover:text-red-400 cursor-pointer" />
                                            </StatusIndicator>
                                        ) : file.uploadStatus === 'success' ? (
                                            <StatusIndicator status="success">
                                                <CheckCircle className="w-5 h-5 text-green-500" />
                                            </StatusIndicator>
                                        ) : file.uploadStatus === 'cancelled' ? (
                                            <StatusIndicator status="cancelled">
                                                <XCircle className="w-5 h-5 text-red-500" />
                                            </StatusIndicator>
                                        ) : editMode ? (
                                            <button 
                                                onClick={() => handleRetry(index)}
                                                className="flex items-center justify-center w-full hover:scale-105 transition-transform duration-200"
                                            >
                                                <RefreshCw className="w-5 h-5 text-accent-start hover:text-accent-start/80 cursor-pointer" />
                                            </button>
                                        ) : (
                                            <button 
                                                onClick={() => handleDelete(index)}
                                                className="flex items-center justify-center w-full hover:scale-105 transition-transform duration-200"
                                            >
                                                <Trash2 className="w-5 h-5 text-red-500 hover:text-red-400 cursor-pointer" />
                                            </button>
                                        )}
                                    </td>
<td className="sticky left-[445px] z-10 px-4 py-3 bg-bg-secondary/70 backdrop-blur-sm">
    {file.preview && (
        <div className="flex items-center justify-center">
            <audio
                ref={(audio) => { file.audioRef = audio; }}
                src={file.preview}
                onEnded={() => setPlayingIndex(null)}
                className="hidden"
            />
            <button
                onClick={() => {
                    const audio = file.audioRef;
                    if (playingIndex === index) {
                        audio.pause();
                        setPlayingIndex(null);
                    } else {
                        if (playingIndex !== null && files[playingIndex]?.audioRef) {
                            files[playingIndex].audioRef.pause();
                        }
                        audio.play();
                        setPlayingIndex(index);
                    }
                }}
                className="p-2 rounded-full hover:bg-white/5 transition-colors duration-200"
            >
                {playingIndex === index ? (
                    <Pause className="w-5 h-5 text-accent-start" />
                ) : (
                    <Play className="w-5 h-5 text-accent-start" />
                )}
            </button>
        </div>
    )}
</td>
<td className="px-4 py-3">
    <input
        type="file"
        accept="image/*"
        onChange={(e) => handleImageChange(index, e)}
        className={fileInputClass}
    />
</td>
<td className="px-4 py-3">
    {file.imagePreview && (
        <motion.img 
            src={file.imagePreview} 
            alt="Preview" 
            className="mt-2 max-w-full h-auto max-h-24 rounded-lg shadow-md" 
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
        />
    )}
</td>
<td className="px-4 py-3">
    <input
        type="text"
        value={file.description}
        onChange={(e) => handleInputChange(index, 'description', e.target.value)}
        className={inputClass}
        placeholder="Description"
    />
</td>
<td className="px-4 py-3">
    <CustomMultiSelect
        options={availableTags}
        value={file.tags}
        onChange={(newValue) => handleMultiSelectChange(index, 'tags', newValue)}
        placeholder="Tags"
        isMulti={true}
    />
</td>
<td className="px-4 py-3">
    <CustomMultiSelect
        options={availableGenres}
        value={file.genres}
        onChange={(newValue) => handleMultiSelectChange(index, 'genres', newValue)}
        placeholder="Genres"
        isMulti={true}
    />
</td>
<td className="px-4 py-3">
    <CustomMultiSelect
        options={availableInstruments}
        value={file.instruments}
        onChange={(newValue) => handleMultiSelectChange(index, 'instruments', newValue)}
        placeholder="Instruments"
        isMulti={true}
    />
</td>
<td className="px-4 py-3">
    <CustomMultiSelect
        options={soundTypes}
        value={file.typeId ? [soundTypes.find(type => type.id === file.typeId)] : []}
        onChange={(newValue) => handleInputChange(index, 'typeId', newValue[0]?.id || '')}
        placeholder="Type *"
        isMulti={false}
        className={!file.typeId ? 'border-red-500' : ''}
    />
</td>
<td className="px-4 py-3">
    <input
        type="number"
        value={file.bpm}
        onChange={(e) => handleInputChange(index, 'bpm', e.target.value)}
        className={inputClass}
        placeholder="BPM"
    />
</td>
<td className="px-4 py-3">
    <select
        value={file.key}
        onChange={(e) => handleInputChange(index, 'key', e.target.value)}
        className={selectClass}
    >
        <option value="">Select key</option>
        {VALID_KEYS.map((validKey) => (
            <option key={validKey} value={validKey}>{validKey}</option>
        ))}
    </select>
</td>
<td className="px-4 py-3">
    <select
        value={file.status}
        onChange={(e) => handleInputChange(index, 'status', e.target.value)}
        className={selectClass}
    >
        <option value="">Select status</option>
        {VALID_STATUS.map((validStatus) => (
            <option key={validStatus} value={validStatus}>{validStatus}</option>
        ))}
    </select>
</td>
<td className="px-4 py-3">
    <input
        type="number"
        value={file.costInCredits}
        onChange={(e) => handleInputChange(index, 'costInCredits', e.target.value)}
        className={inputClass}
        placeholder="Cost"
    />
</td>
<td className="px-4 py-3">
    <select
        value={file.license}
        onChange={(e) => handleInputChange(index, 'license', e.target.value)}
        className={`${selectClass} ${!file.license ? 'border-red-500' : ''}`}
    >
        <option value="">Select license *</option>
        {licenses.map((license) => (
            <option key={license.id} value={license.id}>{license.name}</option>
        ))}
    </select>
</td>
</motion.tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};  // close the BatchUploadTable component

export default BatchUploadTable;