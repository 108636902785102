import React, { useState, useEffect } from 'react';
import { CustomMultiSelect } from './CustomMultiSelect';
import { fetchTags, fetchGenres, fetchInstruments } from '../api/APIManager';

const VALID_KEYS = [
    // "C", "Db", "D", "Eb", "E", "F", "Gb", "G", "Ab", "A", "Bb", "B",
    "C Major", "Db Major", "D Major", "Eb Major", "E Major", "F Major",
    "Gb Major", "G Major", "Ab Major", "A Major", "Bb Major", "B Major",
    "C Minor", "Db Minor", "D Minor", "Eb Minor", "E Minor", "F Minor",
    "Gb Minor", "G Minor", "Ab Minor", "A Minor", "Bb Minor", "B Minor"
];

const SearchIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
    </svg>
);

const ClearIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
    </svg>
);

export const SearchRow = ({ onSearch, initialFilters }) => {
  const [filters, setFilters] = useState(initialFilters || {
    key: [],
    tags: [],
    instruments: [],
    genres: [],
    searchText: ''
  });

  const [availableTags, setAvailableTags] = useState([]);
  const [availableGenres, setAvailableGenres] = useState([]);
  const [availableInstruments, setAvailableInstruments] = useState([]);
  const [showAdvanced, setShowAdvanced] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      const [fetchedTags, fetchedGenres, fetchedInstruments] = await Promise.all([
        fetchTags(),
        fetchGenres(),
        fetchInstruments()
      ]);
      setAvailableTags(fetchedTags);
      setAvailableGenres(fetchedGenres);
      setAvailableInstruments(fetchedInstruments);
    };
    loadData();
  }, []);

  const handleFilterChange = (filterName, selectedOptions) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: selectedOptions
    }));
  };

  const handleSearchTextChange = (event) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      searchText: event.target.value
    }));
  };

  const handleClearSearch = () => {
    setFilters(prevFilters => ({
      ...prevFilters,
      searchText: ''
    }));
  };

  const handleSearch = () => {
    onSearch(filters);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const toggleAdvanced = () => {
    setShowAdvanced(!showAdvanced);
  };

  const keyOptions = VALID_KEYS.map((key, index) => ({ id: index.toString(), name: key }));

  
  return (
    <div className="w-full bg-bg-secondary rounded-lg">
      <div className="space-y-2">
        {/* Main Search Row */}
        <div className="flex flex-col lg:flex-row items-center gap-2 px-4 py-3">
          {/* Search Input */}
          <div className="relative w-full">
            <input
              type="text"
              placeholder="Search..."
              className="w-full bg-bg-secondary border border-accent-start focus:border-accent-end rounded-full h-8 pl-8 pr-3 text-xs text-text-primary placeholder-text-secondary transition-all duration-200 ease-in-out focus:outline-none focus:ring-1 focus:ring-accent-end/50"
              onChange={handleSearchTextChange}
              onKeyPress={handleKeyPress}
              value={filters.searchText}
              style={{ padding: '0 12px 0 32px' }} // Explicit padding to ensure consistency
            />
            <div className="absolute left-2.5 top-1/2 transform -translate-y-1/2">
              <SearchIcon />
            </div>
          </div>

          {/* Desktop Filters Row */}
          <div className={`hidden lg:flex items-center gap-2 flex-none`}>
            <div className="w-28">
              <CustomMultiSelect
                options={keyOptions}
                value={filters.key}
                onChange={(selected) => handleFilterChange('key', selected)}
                placeholder="Key"
                isMulti={false}
              />
            </div>
            <div className="w-32">
              <CustomMultiSelect
                options={availableTags}
                value={filters.tags}
                onChange={(selected) => handleFilterChange('tags', selected)}
                placeholder="Tags"
                isMulti={true}
              />
            </div>
            <div className="w-36">
              <CustomMultiSelect
                options={availableInstruments}
                value={filters.instruments}
                onChange={(selected) => handleFilterChange('instruments', selected)}
                placeholder="Instruments"
                isMulti={true}
              />
            </div>
            <div className="w-32">
              <CustomMultiSelect
                options={availableGenres}
                value={filters.genres}
                onChange={(selected) => handleFilterChange('genres', selected)}
                placeholder="Genres"
                isMulti={true}
              />
            </div>
          </div>

          {/* Desktop Search Button */}
          <button
            className="hidden lg:block bg-gradient-to-r from-accent-start to-accent-end text-white px-6 h-8 rounded-full text-xs font-medium hover:opacity-90 transition-all duration-200 flex-none"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
        {/* Mobile Search Button */}
        <div className="lg:hidden px-4">
          <button
            className="w-full bg-gradient-to-r from-accent-start to-accent-end text-white h-8 rounded-full text-xs font-medium hover:opacity-90 transition-all duration-200"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
        
        {/* Mobile: Show/Hide Filters Button */}
        <div className="lg:hidden px-4 pb-2">
          <button
            className="w-full bg-bg-secondary text-text-primary h-8 px-3 rounded-full transition-all duration-200 ease-in-out text-xs"
            onClick={toggleAdvanced}
          >
            {showAdvanced ? 'Hide Filters' : 'Show Filters'}
          </button>
        </div>

        {/* Mobile: Filters Grid */}
        <div className={`lg:hidden grid grid-cols-2 gap-2 pb-3 px-4 ${showAdvanced ? '' : 'hidden'}`}>
          <div className="w-full">
            <CustomMultiSelect
              options={keyOptions}
              value={filters.key}
              onChange={(selected) => handleFilterChange('key', selected)}
              placeholder="Key"
              isMulti={false}
            />
          </div>
          <div className="w-full">
            <CustomMultiSelect
              options={availableTags}
              value={filters.tags}
              onChange={(selected) => handleFilterChange('tags', selected)}
              placeholder="Tags"
              isMulti={true}
            />
          </div>
          <div className="w-full">
            <CustomMultiSelect
              options={availableInstruments}
              value={filters.instruments}
              onChange={(selected) => handleFilterChange('instruments', selected)}
              placeholder="Instruments"
              isMulti={true}
            />
          </div>
          <div className="w-full">
            <CustomMultiSelect
              options={availableGenres}
              value={filters.genres}
              onChange={(selected) => handleFilterChange('genres', selected)}
              placeholder="Genres"
              isMulti={true}
            />
          </div>
        </div>


      </div>
    </div>
  );
};

export default SearchRow;