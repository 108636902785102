import React, { useState, useRef, useEffect } from 'react';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { createSound, updateSound, fetchSoundById, fetchSoundTypes, fetchTags, fetchGenres, fetchInstruments, analyzeAudio, createTag, createGenre, createInstrument, deleteSoundById, fetchLicenses } from '../api/APIManager'; 
import { CustomMultiSelect } from '../components/CustomMultiSelect'; 
import SpinnerFull from '../components/SpinnerFull';

const VALID_STATUS = [
    { value: "active", label: "Public" },
    { value: "inactive", label: "Private" }
];

const VALID_KEYS = [
    "C Major", "Db Major", "D Major", "Eb Major", "E Major", "F Major",
    "Gb Major", "G Major", "Ab Major", "A Major", "Bb Major", "B Major",
    "C Minor", "Db Minor", "D Minor", "Eb Minor", "E Minor", "F Minor",
    "Gb Minor", "G Minor", "Ab Minor", "A Minor", "Bb Minor", "B Minor"
];

const convertSharpToFlat = (key) => {
    const sharpToFlatMap = {
        'C#': 'Db',
        'D#': 'Eb',
        'F#': 'Gb',
        'G#': 'Ab',
        'A#': 'Bb'
    };

    const [note, ...modeParts] = key.split(' ');
    const mode = modeParts.join(' ');
    const upperNote = note.toUpperCase();

    for (const [sharp, flat] of Object.entries(sharpToFlatMap)) {
        if (upperNote === sharp) {
            return mode ? `${flat} ${mode}` : flat;
        }
    }

    return key;
};

const EditSound = () => {
    const { soundId } = useParams();
    const isEditing = !!soundId;
    const { setCurrentPlayingSound, setIsPlaying: setGlobalIsPlaying } = useOutletContext();
    const navigate = useNavigate();

    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [name, setName] = useState('');
    const [fileName, setFileName] = useState('');
    const [description, setDescription] = useState('');
    const [typeId, setTypeId] = useState('');
    const [bpm, setBpm] = useState('');
    const [key, setKey] = useState('');
    const [status, setStatus] = useState('');
    const [costInCredits, setCostInCredits] = useState('1');
    const [isUploading, setIsUploading] = useState(false);
    const [audioPreview, setAudioPreview] = useState(null);
    const [isLocalPlaying, setIsLocalPlaying] = useState(false);
    const fileInputRef = useRef(null);
    const imageInputRef = useRef(null);  
    const [soundTypes, setSoundTypes] = useState([]);
    const [availableTags, setAvailableTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [availableGenres, setAvailableGenres] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [availableInstruments, setAvailableInstruments] = useState([]);
    const [selectedInstruments, setSelectedInstruments] = useState([]);
    const [analyzedData, setAnalyzedData] = useState(null);
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [isLoading, setIsLoading] = useState(isEditing);
    const [licenses, setLicenses] = useState([]);
    const [selectedLicense, setSelectedLicense] = useState('');
    const [contentOwnership, setContentOwnership] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            const types = await fetchSoundTypes();
            setSoundTypes(types);
            
            const fetchedTags = await fetchTags();
            setAvailableTags(fetchedTags);
            const fetchedGenres = await fetchGenres();
            setAvailableGenres(fetchedGenres);
            const fetchedInstruments = await fetchInstruments();
            setAvailableInstruments(fetchedInstruments);
            const fetchedLicenses = await fetchLicenses();
            setLicenses(fetchedLicenses);

            if (isEditing) {
                try {
                    const soundData = await fetchSoundById(soundId);
                    setName(soundData.name);
                    setDescription(soundData.description);
                    setTypeId(soundData.type.id);
                    setBpm(soundData.bpm?.toString() || '');
                    setKey(soundData.key || '');
                    setStatus(soundData.status || '');
                    setCostInCredits(soundData.cost_in_credits?.toString() || '1');

                    setSelectedTags(soundData.tags.map(tag => ({
                        id: tag.id,
                        name: tag.name
                    })));
                    setSelectedGenres(soundData.genres.map(genre => ({
                        id: genre.id,
                        name: genre.name
                    })));
                    setSelectedInstruments(soundData.instruments.map(instrument => ({
                        id: instrument.id,
                        name: instrument.name
                    })));

                    if (soundData.audio_preview) {
                        setAudioPreview(soundData.audio_preview);
                    }
                    if (soundData.image) {
                        setImagePreview(soundData.image);
                    }

                    if (soundData.license) {
                        setSelectedLicense(soundData.license?.id || '');
                    }
                } catch (error) {
                    console.error('Error fetching sound data:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        loadData();

        return () => {
            if (audioPreview) {
                URL.revokeObjectURL(audioPreview);
            }
            if (imagePreview) {
                URL.revokeObjectURL(imagePreview);
            }
        };
    }, [soundId, isEditing]);

    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this sound? This action cannot be undone.')) {
            try {
                await deleteSoundById(soundId);
                navigate('/');
                window.location.reload();
            } catch (error) {
                alert(`Error deleting sound. Please try again. ${error}`);
                console.error('Error deleting sound:', error);
            }
        }
    };

    const handleFileChange = async (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        if (selectedFile) {
            setFileName(selectedFile.name);
            const fileName = selectedFile.name.split('.').slice(0, -1).join('.');
            setName(fileName);
            const previewUrl = URL.createObjectURL(selectedFile);
            setAudioPreview(previewUrl);
            setIsLocalPlaying(false);

            setIsAnalyzing(true);
            try {
                const analysis = await analyzeAudio(selectedFile);
                setAnalyzedData(analysis);
                setBpm(Math.round(analysis.bpm).toString());
                
                const convertedKey = convertSharpToFlat(analysis.key);
                
                if (VALID_KEYS.includes(convertedKey)) {
                    setKey(convertedKey);
                } else {
                    console.warn(`Invalid key: ${convertedKey}. Setting to empty string.`);
                    setKey('');
                }
                
                const analyzedGenres = analysis.genres.split(', ').map(genreName => {
                    const genre = availableGenres.find(g => g.name.toLowerCase() === genreName.toLowerCase());
                    return genre ? { id: genre.id, name: genre.name } : { name: genreName };
                });
                setSelectedGenres(analyzedGenres);

                const analyzedInstruments = analysis.instruments.map(instrumentName => {
                    const instrument = availableInstruments.find(i => i.name.toLowerCase() === instrumentName.toLowerCase());
                    return instrument ? { id: instrument.id, name: instrument.name } : { name: instrumentName };
                });
                setSelectedInstruments(analyzedInstruments);

                const analyzedTags = analysis.moods.split(', ').map(moodName => {
                    const tag = availableTags.find(t => t.name.toLowerCase() === moodName.toLowerCase());
                    return tag ? { id: tag.id, name: tag.name } : { name: moodName };
                });
                setSelectedTags(analyzedTags);
            } catch (error) {
                console.error('Error analyzing audio:', error);
            } finally {
                setIsAnalyzing(false);
            }
        }
    };

    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setImage(selectedImage);
        if (selectedImage) {
            const previewUrl = URL.createObjectURL(selectedImage);
            setImagePreview(previewUrl);
        }
    };

    const handleTagChange = (newSelectedTags) => {
        setSelectedTags(newSelectedTags.map(tag => ({
            id: tag.id || availableTags.find(t => t.name === tag.name)?.id,
            name: tag.name
        })));
    };

    const handleInstrumentsChange = (newSelectedInstruments) => {
        setSelectedInstruments(newSelectedInstruments.map(instrument => ({
            id: instrument.id || availableInstruments.find(i => i.name === instrument.name)?.id,
            name: instrument.name
        })));
    };

    const handleGenresChange = (newSelectedGenres) => {
        setSelectedGenres(newSelectedGenres.map(genre => ({
            id: genre.id || availableGenres.find(g => g.name === genre.name)?.id,
            name: genre.name
        })));
    };

    const handleTypeChange = (newSelectedTypes) => {
        setTypeId(newSelectedTypes.length > 0 ? newSelectedTypes[0].id : '');
    };

    const togglePlayPause = () => {
        if (audioPreview) {
            if (isLocalPlaying) {
                setGlobalIsPlaying(false);
            } else {
                setCurrentPlayingSound({ id: 'preview', audio_preview: audioPreview, name: name || 'Preview' });
                setGlobalIsPlaying(true);
            }
            setIsLocalPlaying(!isLocalPlaying);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!contentOwnership) {
            alert("You must declare content ownership before uploading.");
            return;
        }
        setIsUploading(true);
        
        try {
            const updatedTags = await Promise.all(selectedTags.map(async (tag) => {
                if (!tag.id) {
                    const newTag = await createTag({ name: tag.name.toLowerCase() });
                    return { id: newTag.id, name: newTag.name };
                }
                return { ...tag, name: tag.name.toLowerCase() };
            }));

            const updatedGenres = await Promise.all(selectedGenres.map(async (genre) => {
                if (!genre.id) {
                    const newGenre = await createGenre({ name: genre.name.toLowerCase() });
                    return { id: newGenre.id, name: newGenre.name };
                }
                return { ...genre, name: genre.name.toLowerCase() };
            }));

            const updatedInstruments = await Promise.all(selectedInstruments.map(async (instrument) => {
                if (!instrument.id) {
                    const newInstrument = await createInstrument({ name: instrument.name.toLowerCase() });
                    return { id: newInstrument.id, name: newInstrument.name };
                }
                return { ...instrument, name: instrument.name.toLowerCase() };
            }));

            const soundData = {
                file,
                fileName,
                image,
                name,
                description,
                tags: updatedTags,
                genres: updatedGenres,
                instruments: updatedInstruments,
                typeId,
                bpm,
                key,
                cost_in_credits: costInCredits,
                status,
                license: selectedLicense,
            };
            
            let response;
            if (isEditing) {
                response = await updateSound(soundId, soundData);
            } else {
                response = await createSound(soundData);
            }
            
            window.location.reload();

            // Reset form
            setFile(null);
            setImage(null);
            setImagePreview(null);
            setName('');
            setFileName('');
            setDescription('');
            setSelectedTags([]);
            setTypeId('');
            setBpm('');
            setKey('');
            setCostInCredits('1');
            setAudioPreview(null);
            setIsLocalPlaying(false);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            if (imageInputRef.current) {
                imageInputRef.current.value = '';
            }
        } catch (error) {
            alert(`Error ${isEditing ? 'updating' : 'uploading'} sound. Please try again. ${error}`);
            console.error(`Error ${isEditing ? 'updating' : 'uploading'} sound:`, error);
        } finally {
            setIsUploading(false);
        }
    };

    const inputClass = "mt-1 block w-full rounded-md bg-bg-secondary border-gray-600 shadow-sm focus:border-accent-start focus:ring focus:ring-accent-start focus:ring-opacity-50 h-12 px-4 py-2";
    const selectClass = "appearance-none w-full bg-bg-secondary text-text-primary rounded-md px-4 py-2 pr-8 focus:outline-none focus:ring-2 focus:ring-accent-start h-12";
    const selectWrapperClass = "relative";
    const selectArrowClass = "absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none";

    if (isLoading) {
        return <SpinnerFull />;
    }

    const renderPublicFields = () => {
        return status !== 'inactive' && (
            <>
                <div className={selectWrapperClass}>
                    <label htmlFor="license" className="block text-sm font-medium text-text-secondary mb-2">
                        License
                    </label>
                    <select
                        id="license"
                        value={selectedLicense}
                        onChange={(e) => setSelectedLicense(e.target.value)}
                        className={selectClass}
                        required
                    >
                        <option value="">Select a license</option>
                        {licenses.map((license) => (
                            <option key={license.id} value={license.id}>
                                {license.name}
                            </option>
                        ))}
                    </select>
                    <div className={selectArrowClass}>
                        <svg className="w-4 h-4 text-text-secondary" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>

                <div>
                    <label htmlFor="costInCredits" className="block text-sm font-medium text-text-secondary mb-2">
                         Price in Tokens (1 Token = $0.07)
                    </label>
                    <input
                        type="number"
                        id="costInCredits"
                        value={costInCredits}
                        onChange={(e) => setCostInCredits(e.target.value)}
                        className={inputClass}
                        min="0"
                    />
                </div>
            </>
        );
    };

    return (
        <div className="p-4 sm:p-8 max-w-2xl mx-auto mt-6">
            <h1 className="text-3xl font-bold mb-6">{isEditing ? 'Edit Your Sound' : 'Upload Your Sound'}</h1>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="flex items-center space-x-4">
                    <div className="flex-grow">
                        <label htmlFor="file" className="block text-sm font-medium text-text-secondary mb-2">
                            Choose Sound File
                        </label>
                        <input
                            type="file"
                            id="file"
                            ref={fileInputRef}
                            accept=".wav"
                            onChange={handleFileChange}
                            className="block w-full text-sm text-text-secondary
                                file:mr-4 file:py-3 file:px-4
                                file:rounded-full file:border-0
                                file:text-sm file:font-semibold
                                file:bg-accent-start file:text-white
                                hover:file:bg-accent-end
                                h-12
                            "
                            required={!isEditing}
                        />
                    </div>
                    {audioPreview && (
                        <button
                            type="button"
                            onClick={togglePlayPause}
                            className="flex-shrink-0 bg-accent-start hover:bg-accent-end text-white font-bold h-12 w-12 rounded-full flex items-center justify-center focus:outline-none"
                        >
                            {isLocalPlaying ? (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            )}
                        </button>
                    )}
                </div>

                {isAnalyzing && (
                    <div className="flex items-center justify-center space-x-2 animate-pulse">
                        <div className="w-8 h-8 bg-accent-start rounded-full"></div>
                        <div className="w-8 h-8 bg-accent-start rounded-full"></div>
                        <div className="w-8 h-8 bg-accent-start rounded-full"></div>
                        <span className="text-lg font-semibold">Analyzing audio...</span>
                    </div>
                )}

                <div>
                    <label htmlFor="image" className="block text-sm font-medium text-text-secondary mb-2">
                        Upload Image
                    </label>
                    <input
                        type="file"
                        id="image"
                        ref={imageInputRef}
                        accept="image/*"
                        onChange={handleImageChange}
                        className="block w-full text-sm text-text-secondary
                            file:mr-4 file:py-3 file:px-4
                            file:rounded-full file:border-0
                            file:text-sm file:font-semibold
                            file:bg-accent-start file:text-white
                            hover:file:bg-accent-end
                            h-12
                        "
                        required={!isEditing}
                    />
                </div>

                {imagePreview && (
                    <div className="mt-2">
                        <img src={imagePreview} alt="Preview" className="max-w-full h-auto max-h-48 rounded" />
                    </div>
                )}

                <div>
                    <label htmlFor="name" className="block text-sm font-medium text-text-secondary mb-2">
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className={inputClass}
                        required
                    />
                </div>

                <div>
                    <label htmlFor="description" className="block text-sm font-medium text-text-secondary mb-2">
                        Description
                    </label>
                    <textarea
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows="4"
                        className={`${inputClass} h-32 py-3`}
                    ></textarea>
                </div>

                <CustomMultiSelect
                    options={availableGenres}
                    value={selectedGenres}
                    onChange={handleGenresChange}
                    placeholder="Select genres..."
                    label="Genres"
                    isMulti={true}
                />

                <CustomMultiSelect
                    options={availableInstruments}
                    value={selectedInstruments}
                    onChange={handleInstrumentsChange}
                    placeholder="Select Instruments..."
                    label="Instruments"
                    isMulti={true}
                />

                <CustomMultiSelect
                    options={availableTags}
                    value={selectedTags}
                    onChange={handleTagChange}
                    placeholder="Select tags..."
                    label="Tags"
                    isMulti={true}
                />

                <CustomMultiSelect
                    options={soundTypes}
                    value={typeId ? [soundTypes.find(type => type.id === typeId)] : []}
                    onChange={handleTypeChange}
                    placeholder="Select a sound type..."
                    label="Sound Type"
                    isMulti={false}
                    required={true}
                />

                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="bpm" className="block text-sm font-medium text-text-secondary mb-2">
                            BPM
                        </label>
                        <input
                            type="number"
                            id="bpm"
                            value={bpm}
                            onChange={(e) => setBpm(e.target.value)}
                            className={inputClass}
                            min="0"
                        />
                    </div>
                    <div className={selectWrapperClass}>
                        <label htmlFor="key" className="block text-sm font-medium text-text-secondary mb-2">
                            Key
                        </label>
                        <select
                            id="key"
                            value={key}
                            onChange={(e) => setKey(e.target.value)}
                            className={selectClass}
                            required
                        >
                            <option value="">Select a key</option>
                            {VALID_KEYS.map((validKey) => (
                                <option key={validKey} value={validKey}>
                                    {validKey}
                                </option>
                            ))}
                        </select>
                        <div className={selectArrowClass}>
                            <svg className="w-4 h-4 text-text-secondary" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div className={selectWrapperClass}>
                    <label htmlFor="status" className="block text-sm font-medium text-text-secondary mb-2">
                        Visibility
                    </label>
                    <select
                        id="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className={selectClass}
                    >
                        <option value="">Select visibility</option>
                        {VALID_STATUS.map((statusOption) => (
                            <option key={statusOption.value} value={statusOption.value}>
                                {statusOption.label}
                            </option>
                        ))}
                    </select>
                    <div className={selectArrowClass}>
                        <svg className="w-4 h-4 text-text-secondary" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>

                {renderPublicFields()}

                <div className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        id="contentOwnership"
                        checked={contentOwnership}
                        onChange={(e) => setContentOwnership(e.target.checked)}
                        className="h-4 w-4 text-accent-start focus:ring-accent-start border-gray-300 rounded"
                        required
                    />
                    <label htmlFor="contentOwnership" className="text-sm text-text-secondary">
                        I declare that I own or have rights to the sounds I'm uploading and that they do not infringe on third-party rights.
                        <a href="/content-rights-info" target="_blank" rel="noopener noreferrer" className="ml-1 text-accent-start hover:underline">
                            Learn more about content rights
                        </a>
                    </label>
                </div>

                <div className="space-y-4">
                    <button
                        type="submit"
                        disabled={isUploading || !contentOwnership}
                        className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-accent-start to-accent-end hover:from-accent-end hover:to-accent-start focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-start ${
                            (isUploading || !contentOwnership) ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                    >
                        {isUploading ? (isEditing ? 'Updating...' : 'Uploading...') : (isEditing ? 'Update Sound' : 'Upload Sound')}
                    </button>
                    {isEditing && (
                        <button
                            type="button"
                            onClick={handleDelete}
                            className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                            Delete Sound
                        </button>
                    )}
                </div>
            </form>
            <div className='h-48'></div>
        </div>
    );
};

export default EditSound;