import React, { createContext, useState, useContext } from 'react';

const ViewCacheContext = createContext();

export const ViewCacheProvider = ({ children }) => {
  const [cache, setCache] = useState({});

  const updateCache = (key, data) => {
    setCache(prevCache => ({
      ...prevCache,
      [key]: data
    }));
  };

  const clearCache = (key) => {
    setCache(prevCache => {
      const newCache = { ...prevCache };
      delete newCache[key];
      return newCache;
    });
  };

  return (
    <ViewCacheContext.Provider value={{ cache, updateCache, clearCache }}>
      {children}
    </ViewCacheContext.Provider>
  );
};

export const useViewCache = () => useContext(ViewCacheContext);