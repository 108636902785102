import React, { useState, useContext, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Header from './Header';
import { Navigation } from './Navigation';
import AudioPlayer from './AudioPlayer';
import AudioControls from './AudioControls';
import SpinnerFull from './SpinnerFull';
import { JUCESend, isJUCE } from '../context/JUCE';

const Layout = () => {
  const { user, isLoading: isAuthLoading } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentPlayingSound, setCurrentPlayingSound] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioPlayerHeight, setAudioPlayerHeight] = useState(0);

  useEffect(() => {
    // Update audio player height when a sound is loaded or playing
    setAudioPlayerHeight(currentPlayingSound || isPlaying ? 116 : 0);
  }, [currentPlayingSound, isPlaying]);

  if (isAuthLoading || !user) {
    return <SpinnerFull />;
  }

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying)
      if (isPlaying) {
        JUCESend('pauseSound', '');
      } else {
        JUCESend('playSound', currentPlayingSound);
      }
    
  }
  
  const audioControlsHeight = 56; // Adjust this value based on the actual height of your AudioControls

  return (
    <div className="flex flex-col h-screen bg-bg-primary text-text-primary font-sans">
      <Header className="h-16 flex-shrink-0" onMenuToggle={() => setIsMenuOpen(!isMenuOpen)} />
      <div className="flex flex-grow overflow-hidden relative">
        <div 
          className="absolute inset-0 top-16 sm:top-20 flex transition-all duration-300 ease-in-out" 
          style={{ bottom: `${audioPlayerHeight + (currentPlayingSound && isJUCE() ? audioControlsHeight : 0)}px` }}
        >
          <Navigation 
            isOpen={isMenuOpen} 
            onClose={() => setIsMenuOpen(false)} 
            className="h-full overflow-y-auto flex-shrink-0 z-50" 
          />
          <main className="flex-grow overflow-hidden flex flex-col">
            <div className="flex-grow overflow-y-auto">
              <Outlet context={{ setCurrentPlayingSound, setIsPlaying }} />
            </div>
          </main>
        </div>
      </div>
      <AudioPlayer
        sound={currentPlayingSound}
        isPlaying={isPlaying}
        onPlayPause={handlePlayPause}
        onEnded={() => setIsPlaying(false)}
      />
      {currentPlayingSound && isJUCE() && (
        <div 
          className="fixed left-0 right-0 bg-bg-secondary z-40 border-t border-black border-opacity-25 transition-all duration-300 ease-in-out"
          style={{ bottom: '0px', height: `${audioControlsHeight}px` }}
        >
          <AudioControls />
        </div>
      )}
      
      {/* Floating Action Button */}
      <Link
        to="/upload"
        className="fixed right-6 w-14 h-14 bg-gradient-to-r from-accent-start to-accent-end rounded-full flex items-center justify-center shadow-lg hover:opacity-90 transition-opacity duration-200 sm:hidden z-50"
        style={{ bottom: `${audioPlayerHeight + 16}px` }}
        aria-label="Upload Sound"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="text-white"
        >
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
      </Link>
    </div>
  );
};

export default Layout;