import axios from 'axios';
import { API_ENDPOINTS, APPLICATION_ID, BACKBLAZE_BUCKET_NAME } from './APIConfig';
import { v4 as uuidv4 } from 'uuid';

// Initialize with a default function instead of null
let globalShowToast = (message, type = 'error') => {
    // Default behavior: log to console if toast isn't initialized
    if (type === 'error') {
        console.error(message);
    } else {
        console.log(message);
    }
};

// Function to initialize toast
export const initializeToast = (showToast) => {
    if (typeof showToast === 'function') {
        globalShowToast = showToast;
    }
};

// Error handler utility
const handleError = (error, customMessage = null) => {
    const errorMessage = error.response?.data?.error || error.response?.data || error.message || customMessage || error.error || 'An error occurred ';
    globalShowToast(errorMessage, 'error');
    console.error(errorMessage, error);
    throw error;
};

// Success handler utility
const handleSuccess = (message) => {
    // globalShowToast(message, 'success');
};

// Rest of your APIManager code remains the same...
// AUTH
const handleTokenRefresh = async () => {
    try {
        const refreshToken = localStorage.getItem('refresh_token');
        if (!refreshToken) throw new Error('No refresh token available');

        const response = await axios.post(API_ENDPOINTS.REFRESH_TOKEN, { refresh_token: refreshToken });
        const { access_token } = response.data;

        localStorage.setItem('access_token', access_token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

        return access_token;
    } catch (error) {
        handleError(error, 'Failed to refresh authentication');
    }
};

const executeWithTokenRefresh = async (apiCall) => {
  try {
    return await apiCall();
  } catch (error) {
    if (error.response?.status === 401) {
      try {
        await handleTokenRefresh();
        return await apiCall();
      } catch (refreshError) {
        handleError(refreshError, 'Session expired. Please login again');
      }
    }
    throw error;
  }
};

// USER
export const fetchUserById = async (userId) => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.GET_USER(userId))
    );
    return response.data;
  } catch (error) {
    handleError(error, `Failed to fetch user details`);
    return {};
  }
};

export const followUser = async (userId) => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.post(`${API_ENDPOINTS.USERS}/${userId}/follow`)
    );
    handleSuccess('Successfully followed user');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to follow user');
  }
};

export const unfollowUser = async (userId) => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.delete(`${API_ENDPOINTS.USERS}/${userId}/follow`)
    );
    handleSuccess('Successfully unfollowed user');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to unfollow user');
  }
};

// SOUNDS
export const createSound = async (soundData) => {
  const uniqueFolder = uuidv4();
  const uniqueFileName = `${uniqueFolder}/${soundData.file.name}`;
  const fileNameWithoutExtension = soundData.file.name.replace(/\.[^/.]+$/, "");
  const audioPreviewFileName = `${uniqueFolder}/${fileNameWithoutExtension}_preview.mp3`;
  let imageFileName = soundData.image ? `${uniqueFolder}/${soundData.image.name}` : null;

  try {
    const uploadResult = await uploadFileToServer(soundData.file, uniqueFileName, audioPreviewFileName, soundData.image, imageFileName);

    const jsonData = {
      application_id: APPLICATION_ID,
      name: soundData.name,
      image: uploadResult.image_file_url || "",
      file_name: uniqueFileName,
      bucket_name: BACKBLAZE_BUCKET_NAME,
      file: "",
      tags: soundData.tags,
      genres: soundData.genres,
      instruments: soundData.instruments,
      audio_preview: uploadResult.preview_file_url,
      type: { id: soundData.typeId },
      key: soundData.key,
      file_backup: "",
      description: soundData.description,
      bpm: soundData.bpm,
      cost_in_credits: soundData.costInCredits,
      license: { id: soundData.license },
      file_size: soundData.file.size.toString()
    };

    if (soundData.status) {
      jsonData.status = soundData.status;
    }
    if (jsonData.key.trim() === "") {
      jsonData.key = null;
    }

    const response = await axios.post(API_ENDPOINTS.SOUNDS, jsonData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      },
    });

    handleSuccess('Sound created successfully');
    return { ...response.data, fileSize: soundData.file.size };
  } catch (error) {
    handleError(error, 'Failed to create sound');
  }
};

export const updateSound = async (soundId, newSoundData) => {
  try {
    const originalSound = await fetchSoundById(soundId);
    const updatedSound = { ...originalSound };

    // Update basic fields
    const fields = ['name', 'description', 'key', 'bpm', 'costInCredits', 'cost_in_credits', 'status'];
    fields.forEach(field => {
      if (newSoundData[field] !== undefined) {
        updatedSound[field] = newSoundData[field];
      }
    });

    // Update related fields
    if (newSoundData.type_id !== undefined) updatedSound.type = { id: newSoundData.type_id };
    if (newSoundData.license !== undefined) updatedSound.license = { id: newSoundData.license };

    // Update arrays
    ['tags', 'genres', 'instruments'].forEach(field => {
      if (newSoundData[field] !== undefined) {
        updatedSound[field] = newSoundData[field];
      }
    });

    // Handle file upload
    if (newSoundData.file) {
      const uniqueFolder = uuidv4();
      const uniqueFileName = `${uniqueFolder}/${newSoundData.file.name}`;
      const fileNameWithoutExtension = newSoundData.file.name.replace(/\.[^/.]+$/, "");
      const audioPreviewFileName = `${uniqueFolder}/${fileNameWithoutExtension}_preview.mp3`;

      const uploadResult = await uploadFileToServer(newSoundData.file, uniqueFileName, audioPreviewFileName);
      updatedSound.file_name = uniqueFileName;
      updatedSound.bucket_name = BACKBLAZE_BUCKET_NAME;
      updatedSound.audio_preview = uploadResult.preview_file_url;
      updatedSound.file_size = newSoundData.file.size.toString();
    }

    // Handle image upload
    if (newSoundData.image) {
      const uniqueFolder = uuidv4();
      const imageFileName = `${uniqueFolder}/${newSoundData.image.name}`;
      const uploadResult = await uploadFileToServer(null, null, null, newSoundData.image, imageFileName);
      updatedSound.image = uploadResult.image_file_url;
    }

    updatedSound.file = "";
    if (updatedSound.key.trim() === "") {
      updatedSound.key = undefined;
    }
    const response = await executeWithTokenRefresh(() => 
      axios.put(API_ENDPOINTS.GET_SOUND(soundId), updatedSound)
    );
    
    handleSuccess('Sound updated successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to update sound');
  }
};

export const fetchSounds = async (page, pageSize, filters = {}) => {
  try {
    // Default status to "active" if not present in filters
    if (!filters.status) {
      filters.status = "active";
    }

    // Remove status if it is set to "all"
    if (filters.status === "all") {
      delete filters.status;
    }

    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.SOUNDS, {
        params: {
          page_number: page,
          page_size: pageSize,
          application: APPLICATION_ID,
          ...filters,
        }
      })
    );
    
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch sounds');
    return { items: [], total: 0 };
  }
};

export const fetchFeaturedSounds = async (page, pageSize) => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.SOUNDS, {
        params: {
          page_number: 1,
          page_size: 10,
          application: APPLICATION_ID,
          status: "active",
          featured: true
        }
      })
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch featured sounds');
    return { items: [], total: 0 };
  }
};

export const fetchSoundById = async (soundId) => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.GET_SOUND(soundId))
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch sound details');
    return {};
  }
};

export const deleteSoundById = async (soundId) => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.delete(API_ENDPOINTS.GET_SOUND(soundId))
    );
    handleSuccess('Sound deleted successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to delete sound');
    return {};
  }
};

// Meta Data
export const fetchSoundTypes = async () => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.SOUND_TYPES)
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch sound types');
    return [];
  }
};

export const fetchTags = async () => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.TAGS)
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch tags');
    return [];
  }
};

export const fetchGenres = async () => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.GENRES)
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch genres');
    return [];
  }
};

export const fetchInstruments = async () => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.INSTRUMENTS)
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch instruments');
    return [];
  }
};

export const fetchLicenses = async () => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.LICENSES)
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch licenses');
    return [];
  }
};

export const acceptLicense = async (licenseId) => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.post(API_ENDPOINTS.ACCEPT_LICENSE(licenseId))
    );
    handleSuccess('License accepted successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to accept license');
    return [];
  }
};

export const createTag = async (tag) => {
  try {
    // First, fetch all existing tags
    const existingTags = await fetchTags();
    
    // Check if a tag with the same name already exists (case-insensitive)
    const existingTag = existingTags.find(
      t => t.name.toLowerCase() === tag.name.toLowerCase()
    );
    
    // If tag exists, return the existing one
    if (existingTag) {
      return existingTag;
    }

    // If tag doesn't exist, create a new one
    const response = await executeWithTokenRefresh(() => 
      axios.post(API_ENDPOINTS.TAGS, tag)
    );
    handleSuccess('Tag created successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to create tag');
    return null;
  }
};

export const createGenre = async (genre) => {
  try {
    // First, fetch all existing genres
    const existingGenres = await fetchGenres();
    
    // Check if a genre with the same name already exists (case-insensitive)
    const existingGenre = existingGenres.find(
      g => g.name.toLowerCase() === genre.name.toLowerCase()
    );
    
    // If genre exists, return the existing one
    if (existingGenre) {
      return existingGenre;
    }

    // If genre doesn't exist, create a new one
    const response = await executeWithTokenRefresh(() => 
      axios.post(API_ENDPOINTS.GENRES, genre)
    );
    handleSuccess('Genre created successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to create genre');
    return null;
  }
};

export const createInstrument = async (instrument) => {
  try {
    // First, fetch all existing instruments
    const existingInstruments = await fetchInstruments();
    
    // Check if an instrument with the same name already exists (case-insensitive)
    const existingInstrument = existingInstruments.find(
      i => i.name.toLowerCase() === instrument.name.toLowerCase()
    );
    
    // If instrument exists, return the existing one
    if (existingInstrument) {
      return existingInstrument;
    }

    // If instrument doesn't exist, create a new one
    const response = await executeWithTokenRefresh(() => 
      axios.post(API_ENDPOINTS.INSTRUMENTS, instrument)
    );
    handleSuccess('Instrument created successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to create instrument');
    return null;
  }
};

// Liking
export const likeSound = async (soundId) => {
  try {
    await executeWithTokenRefresh(() => 
      axios.post(API_ENDPOINTS.LIKE_SOUND(soundId))
    );
    handleSuccess('Sound liked successfully');
    return true;
  } catch (error) {
    handleError(error, 'Failed to like sound');
    return false;
  }
};

export const unlikeSound = async (soundId) => {
  try {
    await executeWithTokenRefresh(() => 
      axios.delete(API_ENDPOINTS.UNLIKE_SOUND(soundId))
    );
    handleSuccess('Sound unliked successfully');
    return true;
  } catch (error) {
    handleError(error, 'Failed to unlike sound');
    return false;
  }
};

// Purchases
export const purchaseSound = async (soundId) => {
  try {
    await executeWithTokenRefresh(() => 
      axios.post(API_ENDPOINTS.PURCHASE_SOUND(soundId))
    );
    handleSuccess('Sound purchased successfully');
    return true;
  } catch (error) {
    handleError(error, 'Failed to purchase sound');
    return false;
  }
};

// Reports
export const reportSound = async (soundId, reason) => {
  try {
    await executeWithTokenRefresh(() => 
      axios.post(API_ENDPOINTS.REPORT_SOUND(soundId), { reason })
    );
    handleSuccess('Sound reported successfully');
    return true;
  } catch (error) {
    handleError(error, 'Failed to report sound');
    return false;
  }
};

// UPLOAD SOUND
export const uploadFileToServer = async (file, uniqueFileName, audioPreviewFileName, imageFile = null, imageFileName = null, avatarImageFile = null, avatarImageFileName = null) => {
  const formData = new FormData();
  if (file) {
    formData.append('file', file);
    formData.append('file_name', uniqueFileName);
    formData.append('audio_preview_file_name', audioPreviewFileName);
  }
  if (imageFile && imageFileName) {
    formData.append('image_file', imageFile);
    formData.append('image_file_name', imageFileName);
  }

  if (avatarImageFile && avatarImageFileName) {
    formData.append('avatar_image', avatarImageFile);
    formData.append('avatar_image_file_name', avatarImageFileName);
  }
  try {
    const response = await axios.post(`${API_ENDPOINTS.UPLOAD_FILE}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
    });
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to upload file');
  }
};

// Downloads
export const getDownloadURL = async (soundId) => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.GET_SOUND_DOWNLOAD_URL(soundId))
    );
    handleSuccess('Download link generated successfully');
    return response.data.downloadUrl;
  } catch (error) {
    handleError(error, 'Failed to generate download link');
  }
};

//USER
export const updateUser = async (userId, newUserData) => {
  try {
    const originalUser = await fetchUserById(userId);
    const updatedUser = originalUser ;

    // Update basic fields
    const fields = ['username', 'email', 'avatar'];
    fields.forEach(field => {
      if (newUserData[field] !== undefined) {
        updatedUser[field] = newUserData[field];
      }
    });

    // Handle image upload
    if (newUserData.avatarFile) {
      const avatarFileName = `${userId}/${newUserData.avatarFile.name}`;
      const uploadResult = await uploadFileToServer(null, null, null, null, null, newUserData.avatarFile, avatarFileName);
      updatedUser.avatar = uploadResult.avatar_image_file_url;
    }

    const response = await executeWithTokenRefresh(() => 
      axios.put(API_ENDPOINTS.GET_USER(userId), originalUser)
    );
    
    handleSuccess('User updated successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to update user');
  }
};

//Audio Apis
export const analyzeAudio = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post('https://audioapis.soundware.io/analyze_audio', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    handleSuccess('Audio analysis completed successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to analyze audio file');
  }
};

export const generateAiImage = async () => {
  try {
      // Get a random artistic photo from Picsum
      const randomSeed = Math.floor(Math.random() * 1000);
      const response = await fetch(`https://picsum.photos/seed/${randomSeed}/800/800`);
      
      if (!response.ok) {
          throw new Error('Failed to fetch image');
      }

      return await response.blob();
  } catch (error) {
      console.error('Error generating image:', error);
      throw error;
  }
};

// Export the initialized toast function
export const getToast = () => {
  return globalShowToast;
};

// Initialize toast with default values if needed
export const ensureToastInitialized = () => {
  if (globalShowToast.toString().includes('Toast function not initialized')) {
    console.warn('Toast was not initialized, using default implementation');
    initializeToast((message, type) => {
      if (type === 'error') {
        console.error(message);
      } else {
        console.log(message);
      }
    });
  }
};

// Add this to ensure toast is always available
ensureToastInitialized();

export default {
  initializeToast,
  getToast,
  getDownloadURL,
  analyzeAudio,
  // ... you can list all other exports here if needed
};