import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { GoogleLogin } from '@react-oauth/google';

const Alert = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, y: -10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.1 }}
    className="p-4 mb-6 bg-red-500/10 backdrop-blur-sm border border-red-500/20 rounded-lg text-red-500 text-sm"
  >
    {children}
  </motion.div>
);

export const AuthSection = ({ 
  isLogin, 
  setIsLogin, 
  formData, 
  handleInputChange, 
  handleSubmit, 
  error, 
  isSubmitting, 
  handleGoogleSuccess, 
  handleGoogleError 
}) => {
  return (
    <div className="w-full max-w-md">
      <div className="backdrop-blur-xl bg-bg-secondary/50 rounded-3xl shadow-2xl border border-white/10 overflow-hidden">
        {/* Tab switcher */}
        <div className="p-2">
          <div className="relative flex h-9 rounded-lg bg-bg-primary/40">
            <motion.div
              className="absolute inset-y-0 my-0.5 rounded-md bg-white/20"
              style={{ width: 'calc(50% - 4px)' }}
              animate={{ x: isLogin ? 'calc(100% + 2px)' : '2px' }}
              transition={{ duration: 0.15, ease: "easeInOut" }}
            />
            <button
              onClick={() => setIsLogin(false)}
              className={`relative z-10 flex-1 text-sm font-medium transition-colors ${
                !isLogin ? 'text-text-primary' : 'text-text-secondary'
              }`}
            >
              Sign Up
            </button>
            <button
              onClick={() => setIsLogin(true)}
              className={`relative z-10 flex-1 text-sm font-medium transition-colors ${
                isLogin ? 'text-text-primary' : 'text-text-secondary'
              }`}
            >
              Sign In
            </button>
          </div>
          <div className="text-center mt-4 -mb-4 text-sm text-text-secondary">
            {isLogin ? (
              "Use your existing Soundware account to sign in to SoundBank"
            ) : (
              "Create a free account"
            )}
          </div>
        </div>

        <div className="p-8">
          <AnimatePresence mode="wait">
            {error && <Alert>{error}</Alert>}
          </AnimatePresence>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                placeholder="Username"
                className="w-full p-3 rounded-xl bg-bg-primary/50 text-text-primary border border-white/10 focus:border-accent-start focus:ring-1 focus:ring-accent-start transition-all placeholder:text-text-secondary"
                required
              />
            </div>

            <AnimatePresence mode="wait">
              {!isLogin && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.15 }}
                >
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                    className="w-full p-3 rounded-xl bg-bg-primary/50 text-text-primary border border-white/10 focus:border-accent-start focus:ring-1 focus:ring-accent-start transition-all placeholder:text-text-secondary"
                    required
                  />
                </motion.div>
              )}
            </AnimatePresence>

            <div className="space-y-2">
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Password"
                className="w-full p-3 rounded-xl bg-bg-primary/50 text-text-primary border border-white/10 focus:border-accent-start focus:ring-1 focus:ring-accent-start transition-all placeholder:text-text-secondary"
                required
              />
              {isLogin && (
                <div className="text-right">
                  <a
                    href="https://store.soundware.io/account/lost-password/"
                    className="text-sm text-accent-start hover:text-accent-end transition-colors"
                  >
                    Forgot password?
                  </a>
                </div>
              )}
            </div>

            <AnimatePresence mode="wait">
              {!isLogin && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.15 }}
                >
                  <div className="space-y-4">
                    <input
                      type="password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                      placeholder="Confirm Password"
                      className="w-full p-3 rounded-xl bg-bg-primary/50 text-text-primary border border-white/10 focus:border-accent-start focus:ring-1 focus:ring-accent-start transition-all placeholder:text-text-secondary"
                      required
                    />
                    <div className="flex items-start space-x-3">
                      <input
                        type="checkbox"
                        name="agreeToTerms"
                        checked={formData.agreeToTerms}
                        onChange={handleInputChange}
                        className="mt-1 rounded bg-bg-primary/50 border-white/20 checked:bg-accent-start focus:ring-accent-start transition-all"
                      />
                      <label className="text-sm text-text-secondary">
                        I agree to the{' '}
                        <a href="/terms" className="text-accent-start hover:text-accent-end transition-colors">
                          Terms of Service
                        </a>
                      </label>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            <motion.button
              type="submit"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              transition={{ duration: 0.1 }}
              className="w-full bg-gradient-to-r from-accent-start to-accent-end text-text-primary p-3 rounded-xl font-medium shadow-lg hover:shadow-accent-start/25 transition-all duration-300"
              disabled={isSubmitting}
            >
              <span className="relative z-10">
                {isSubmitting
                  ? isLogin
                    ? 'Signing in...'
                    : 'Creating account...'
                  : isLogin
                  ? 'Sign In'
                  : 'Create Account'}
              </span>
            </motion.button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AuthSection;