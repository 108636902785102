import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { AuthContext } from '../context/AuthContext';
import { isJUCE } from '../context/JUCE';

// [Previous icon components remain the same...]
const HomeIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
  </svg>
);

const ProfileIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
  </svg>
);

const LikesIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
  </svg>
);

const ExploreIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M12 10.9c-.61 0-1.1.49-1.1 1.1s.49 1.1 1.1 1.1c.61 0 1.1-.49 1.1-1.1s-.49-1.1-1.1-1.1zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm2.19 12.19L6 18l3.81-8.19L18 6l-3.81 8.19z"/>
  </svg>
);

const UploadIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"/>
  </svg>
);

const MySoundsIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z"/>
  </svg>
);

export const Navigation = ({ isOpen, onClose }) => {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 640 && isOpen) {
        onClose();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isOpen, onClose]);

  const navClasses = `
    fixed top-0 left-0 h-full w-64 bg-bg-secondary p-8 transition-all duration-300 ease-in-out transform z-50
    ${isOpen ? 'translate-x-0' : '-translate-x-full'}
    sm:relative sm:translate-x-0 sm:z-0
    flex flex-col justify-between
  `;

  const linkClasses = "flex items-center text-text-secondary hover:text-text-primary transition-colors duration-200";
  const subLinkClasses = "flex items-center text-text-secondary hover:text-text-primary transition-colors duration-200 pl-4";

  return (
    <>
      <nav className={navClasses}>
        <ul className="space-y-6">
          <li>
            <Link to="/" className={linkClasses} onClick={onClose}>
              <ExploreIcon /><span className="ml-2">Explore</span>
            </Link>
          </li>
          <li>
            <div className="font-semibold text-text-secondary mb-3">Library</div>
            <ul className="space-y-2">
              <li>
                <Link to="/purchased" className={subLinkClasses} onClick={onClose}>
                  <MySoundsIcon /><span className="ml-2">Purchased</span>
                </Link>
              </li>
              <li>
                <Link to="/uploaded" className={subLinkClasses} onClick={onClose}>
                  <UploadIcon /><span className="ml-2">Uploaded</span>
                </Link>
              </li>
              <li>
                <Link to="/likes" className={subLinkClasses} onClick={onClose}>
                  <LikesIcon /><span className="ml-2">Liked</span>
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <div className="font-semibold text-text-secondary mb-3">My Account</div>
            <ul className="space-y-3">
              <li>
                <Link to="/profile/me" className={subLinkClasses} onClick={onClose}>
                  <ProfileIcon /><span className="ml-2">Account</span>
                </Link>
              </li>
            </ul>
          </li>
          {user && user.verified && (
            <li>
              <div className="font-semibold text-text-secondary mb-3">Extras</div>
              <ul className="space-y-3">
                <li>
                  <Link to="/batch-upload" className={subLinkClasses} onClick={onClose}>
                    <UploadIcon /><span className="ml-2">Batch Upload</span>
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {user && user.admin && (
            <li>
              <div className="font-semibold text-text-secondary mb-3">Admin</div>
              <ul className="space-y-3">
                <li>
                  <Link to="/batch-edit" className={subLinkClasses} onClick={onClose}>
                    <UploadIcon /><span className="ml-2">Batch Edit</span>
                  </Link>
                </li>
              </ul>
            </li>
          )}
        </ul>
        <div className="mt-auto space-y-4">
          <Link
            to="/upload"
            onClick={onClose}
            className="block w-full bg-gradient-to-r from-accent-start to-accent-end text-white px-4 py-2 rounded text-center hover:opacity-90 transition-opacity duration-200"
          >
            Upload
          </Link>
          
          {!isJUCE() && (
            <>
              <div className="font-semibold text-center text-text-secondary mb-2 text-sm">Download</div>
              <div className="flex gap-2">
                <motion.button
                  onClick={() => window.location.href = "https://f002.backblazeb2.com/file/soundwarereleases/SoundBank%20Win%20Installer.exe"} 
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="flex-1 bg-bg-secondary text-text-primary p-2 rounded-lg font-medium border border-white/10 hover:bg-bg-primary/50 transition-all duration-300"
                  title="Download for Windows"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M0 3.449L9.75 2.1v9.451H0m10.949-9.602L24 0v11.4H10.949M0 12.6h9.75v9.451L0 20.699M10.949 12.6H24V24l-12.9-1.801"/>
                  </svg>
                </motion.button>
                <motion.button
                  onClick={() => window.location.href = "https://f002.backblazeb2.com/file/soundwarereleases/SoundBank%20Mac%20Installer_signed.pkg"} 
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="flex-1 bg-bg-secondary text-text-primary p-2 rounded-lg font-medium border border-white/10 hover:bg-bg-primary/50 transition-all duration-300"
                  title="Download for macOS"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M22 17.607c-.786 2.28-3.139 6.317-5.563 6.361-1.608.031-2.125-.953-3.963-.953-1.837 0-2.412.923-3.932.983-2.572.099-6.542-5.827-6.542-10.995 0-4.747 3.308-7.1 6.198-7.143 1.55-.028 3.014 1.045 3.959 1.045.949 0 2.727-1.29 4.596-1.101.782.033 2.979.315 4.389 2.377-3.741 2.442-3.158 7.549.858 9.426zm-5.222-17.607c-2.826.114-5.132 3.079-4.81 5.531 2.612.203 5.118-2.725 4.81-5.531z"/>
                  </svg>
                </motion.button>
              </div>
            </>
          )}
        </div>
      </nav>
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 sm:hidden z-40 transition-opacity duration-300 ease-in-out" 
          onClick={onClose}
        ></div>
      )}
    </>
  );
};

export default Navigation;