import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ViewCacheProvider } from './context/ViewCacheContext';
import { ToastProvider, useToast } from './context/ToastContext'; // Add this import
import { initializeToast } from './api/APIManager';
import Layout from './components/Layout';
import Index from './pages/Index';
import Home from './pages/Home';
import PurchasedSounds from './pages/PurchasedSounds';
import Upload from './pages/Upload';
import EditSound from './pages/EditSound';
import BatchUpload from './pages/BatchUpload';
import BatchEdit from './pages/BatchEdit';
import Likes from './pages/Likes';
import Uploaded from './pages/Uploaded';
import PrivateRoute from './components/PrivateRoute';
import UserProfile from './pages/UserProfile';
import './App.css';

// Separate component for toast initialization
const ToastInitializer = ({ children }) => {
  const { showToast } = useToast();
  
  useEffect(() => {
    initializeToast(showToast);
  }, [showToast]);

  return <>{children}</>;
};

// Wrap the routes with ToastInitializer
const AppRoutes = () => {
  const preventZoom = (event) => {
    if (event.scale !== 1) event.preventDefault();
  };

  const preventScroll = () => {
    // window.scrollTo(0, 0);
  };

  const preventCtrlZoom = (event) => {
    if (event.ctrlKey) event.preventDefault();
  };

  useEffect(() => {
    document.addEventListener('touchmove', preventZoom, { passive: false });
    window.addEventListener('scroll', preventScroll, { passive: false });
    window.addEventListener('wheel', preventCtrlZoom, { passive: false });

    return () => {
      document.removeEventListener('touchmove', preventZoom);
      window.removeEventListener('scroll', preventScroll);
      window.removeEventListener('wheel', preventCtrlZoom);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/landing" element={<Index />} />
        <Route path="/" element={<PrivateRoute><Layout /></PrivateRoute>}>
          <Route index element={<Home />} />
          <Route path="/purchased" element={<PurchasedSounds />} />
          <Route path="/uploaded" element={<Uploaded />} />
          <Route path="/likes" element={<Likes />} />
          <Route path="upload" element={<Upload />} />
          <Route path="sound/:soundId" element={<EditSound />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/profile/:userId" element={<UserProfile />} />
          <Route path="/batch-upload" element={<BatchUpload />} />
          <Route path="/batch-edit" element={<BatchEdit />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

// Main App component
function App() {
  return (
    <AuthProvider>
      <GoogleOAuthProvider clientId="400025157675-9qd00tfa32nc2opvolc1mg5sotecp241.apps.googleusercontent.com">
          <ToastProvider>
            <ToastInitializer>
              <AppRoutes />
            </ToastInitializer>
          </ToastProvider>
      </GoogleOAuthProvider>
    </AuthProvider>
  );
}

export default App;