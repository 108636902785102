import React, { useState, useEffect, useContext } from 'react';
import { SoundCard } from './SoundCard';
import { isJUCE, JUCESend, JUCEReceive } from '../context/JUCE';
import { getDownloadURL } from '../api/APIManager';
import SpinnerFull from './SpinnerFull';
import { AuthContext } from '../context/AuthContext';

export const SoundList = ({ sounds, onLike, onUnlike, onPurchase, onPlay, onDownload, currentPlayingSound, isPlaying }) => {
  const [purchasingSoundId, setPurchasingSoundId] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const { refreshUserData } = useContext(AuthContext);

  useEffect(() => {
    const handleJUCEReceive = ({ eventName, eventData }) => {
      if (eventName === 'dragExportComplete') {
        setIsDragging(false);
      }
    };

    JUCEReceive.on('JUCEReceive', handleJUCEReceive);

    return () => {
      JUCEReceive.off('JUCEReceive', handleJUCEReceive);
    };
  }, []);

  const handlePurchase = async (soundId) => {
    setPurchasingSoundId(soundId);
    try {
      await onPurchase(soundId);
      await refreshUserData();  // Refresh user data after successful purchase
    } catch (error) {
      console.error('Error purchasing sound:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setPurchasingSoundId(null);
    }
  };
  
  const handleDragStart = async (e, sound) => {
    if (isJUCE()) {
      e.preventDefault();
      setIsDragging(true);
      try {
        const downloadUrl = await getDownloadURL(sound.id);
        const soundWithUrl = { ...sound, download_url: downloadUrl };
        JUCESend('dragExport', soundWithUrl);
      } catch (error) {
        console.error('Error getting download URL:', error);
        JUCESend('dragExport', sound);
      }
    }
  };

  if (isDragging) {
    return <SpinnerFull message="Processing audio, please wait..." />;
  }

  return (
    <div className="space-y-2">
      {sounds.map((sound) => (
        <SoundCard 
          key={sound.id}
          sound={sound} 
          onLike={onLike}
          onUnlike={onUnlike}
          onPurchase={() => handlePurchase(sound.id)}
          onPlay={onPlay}
          onDownload={onDownload}
          currentPlayingSound={currentPlayingSound}
          isPlaying={isPlaying}
          isPurchasing={purchasingSoundId === sound.id}
          onDragStart={handleDragStart}
        />
      ))}
    </div>
  );
};

export default SoundList;